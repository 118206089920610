import AppSDK from "@growthos/ui-app-sdk";

export default class RequestHelpers {
	static CONTENT_TYPES = {
		JSON: "application/json",
		TEXT: "text/plain",
		EXCEL: "application/vnd.ms-excel"
	};

	static doRequest = async ({ uri = "", params = {}, method = "GET", ...rest }) => {
		const {
			buildURL,
			getFileName,
			CONTENT_TYPES: { TEXT, JSON, EXCEL }
		} = RequestHelpers;

		if (!("modelCode" in params)) {
			params.modelCode = location?.pathname?.split("/")[2];
		}

		// if (AppSDK.isReady && !("aarcValues" in params)) {
		//     const {masterAgencyDivisionID, masterBusinessUnitID, masterRegionID, masterCountryID} = AppSDK.getAARC();
		//     params.aarcValues = [masterAgencyDivisionID, masterBusinessUnitID, masterRegionID, masterCountryID].join("|");
		// }

		if (AppSDK.getIsReady() && !("contextId" in params)) {
			params.contextId = AppSDK.getContextId();
		}

		const response = await AppSDK.fetchWithAuth(buildURL(`${process.env.REACT_APP_API_URL}/${uri}`, params), {
			method,
			headers: { "Content-Type": "application/json" },
			...rest
		});
		const responseType = response.headers.get("content-type");

		if (responseType?.includes?.(JSON)) {
			// eslint-disable-next-line
			return await response.json();
		}
		if (responseType?.includes?.(TEXT)) {
			// eslint-disable-next-line
			return await response.text();
		}
		if (responseType?.includes?.(EXCEL)) {
			return { name: getFileName(response), data: await response.blob() };
		}
		return Promise.resolve(response);
	};

	static buildURL(baseURL, queryParams) {
		try {
			if (typeof queryParams === "object" && Object.keys(queryParams)?.length > 0) {
				Object.keys(queryParams).forEach((param) => {
					if (queryParams[param] === null || queryParams[param] === undefined) {
						delete queryParams[param];
					}
				});
				const qp = new URLSearchParams(queryParams);
				return `${baseURL}?${qp.toString()}`;
			}
			return baseURL;
		} catch (_) {
			console.log(`An error occured while building url: ${baseURL}`);
			return baseURL;
		}
	}

	static getFileName = (fileResponse) => {
		const contentDisposition = fileResponse.headers.get("Content-Disposition");
		if (contentDisposition?.indexOf?.("attachment") !== -1) {
			const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
			const matches = filenameRegex.exec(contentDisposition);
			if (matches !== null && matches[1]) {
				return matches[1].replace(/['"]/g, "");
			}
		}
		return null;
	};

	static async downloadFile({ name, data }) {
		const link = document.createElement("a");
		link.href = window.URL.createObjectURL(data);
		link.setAttribute("download", name);
		document.body.appendChild(link);
		link.click();
	}
}
