import RequestHelpers from "../helpers/RequestHelpers";
const { doRequest } = RequestHelpers;

export default class ActivityLogService {
	static getHistoryLog(modelCode, queryParams, filterValues) {
		return doRequest({
			method: "POST",
			uri: "HistoryLog/GetHistoryLog",
			params: { ...queryParams, modelCode },
			body: JSON.stringify(filterValues)
		});
	}

	static getUserList(modelCode) {
		return doRequest({
			uri: `HistoryLog/GetUserList`,
			params: { modelCode }
		});
	}
}
