import {useEffect, useState} from "react";

/**
 * Custom hook that allows you to use async functions in useEffect.
 * @param callback The async function to execute.
 * @param deps The dependencies to watch for changes.
 * @returns The output of the async function.
 */
export default (callback: () => Promise<void>, deps: ReadonlyArray<unknown> = []): unknown => {
    const [output, setOutput] = useState<unknown>();

    useEffect(() => {
        const execute = async () => await callback();
        setOutput(execute());
    }, deps);

    return output;
};
