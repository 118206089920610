import React, { useMemo, useCallback, useState, useEffect } from "react";
import Modal from "@growthos/ui-modal";

import ModalButtons from "./ModalButtons";
import Actions from "./Actions";
import Body from "./Body";

const MODAL_HEADING = "Add standard entities";
const MODAL_DESCRIPTION =
	"Search and select from the available predefined entities below. If you're unable to find a suitable entity, you'll have the opportunity to add additional entities at a later point.";

import "../BulkAddEntityModal.scss";

export default ({ setOpen, open, availableEntities, onSubmit, modelCode, showCurrentEntities, setShowCurrentEntities }) => {
	const [entities, setEntities] = useState(availableEntities);
	const [searchTerm, setSearchTerm] = useState("");
	const [isExporting, setIsExporting] = useState(false);
	const [listOfApps, setListOfApps] = useState([]);
	const [appToFilter, setAppToFilter] = useState("All modules");

	useEffect(() => {
		const newAvailableEntities =
			availableEntities?.map((ae) => ({
				...ae,
				selected: entities?.find?.((e) => e?.entityId == ae?.entityId)?.selected || ae?.selected || false
			})) || [];
		setEntities(newAvailableEntities);

		const appsWithEntitiesRequired = ["All modules"];
		newAvailableEntities.forEach(({ tooltip }) => {
			if (tooltip && isJsonString(tooltip)) {
				JSON.parse(tooltip).RequiredBy.forEach((app) => {
					if (app && !appsWithEntitiesRequired.includes(app)) {
						appsWithEntitiesRequired.push(app);
					}
				});
			}
		});
		setListOfApps(appsWithEntitiesRequired);
	}, [availableEntities]);

	useEffect(() => {
		const bulkAddNewEntityModal = document.querySelector("#bulkAddStandardEntityModal");
		bulkAddNewEntityModal.addEventListener("click", (e) => {
			// If the user clicks outside of the tooltip
			if (!e.target.closest(".entity_details_tooltip") && !e.target?.classList?.contains("openTooltipIcon")) {
				closeEntityTooltips();
			}
		});
	}, []);

	const isJsonString = (tooltip) => {
		try {
			JSON.parse(tooltip);
		} catch (e) {
			return false;
		}
		return true;
	};

	const closeEntityTooltips = () => {
		const entityDetailsTooltips = document.getElementsByClassName("entity_details_tooltip");
		for (const tooltip of entityDetailsTooltips) {
			tooltip.style.display = "none";
		}
	};

	const selectedEntities = useMemo(() => {
		return entities?.filter(({ selected }) => selected == true) || [];
	}, [entities]);

	const visibleEntities = useMemo(() => {
		if (searchTerm == "" && appToFilter == "All modules") {
			return entities;
		}
		let filteredEntities = entities;
		if (searchTerm != "") {
			filteredEntities = filteredEntities.filter(({ name, code }) => [name, code].some((val) => val?.toString?.()?.toLowerCase?.()?.includes?.(searchTerm?.toLowerCase?.())));
		}
		if (appToFilter !== "All modules") {
			filteredEntities = filteredEntities.filter(({ tooltip }) => {
				return tooltip && isJsonString(tooltip) && JSON.parse(tooltip).RequiredBy.includes(appToFilter);
			});
		}
		return filteredEntities;
	}, [entities, searchTerm, appToFilter]);

	const handleSelectChange = useCallback(
		(id, selected) => {
			setEntities((prevEntities) => {
				const newEntities = [...prevEntities];
				const targetEntityIndex = newEntities.findIndex(({ entityId }) => entityId == id);
				newEntities.splice(targetEntityIndex, 1, {
					...prevEntities[targetEntityIndex],
					selected
				});
				return newEntities;
			});
		},
		[setEntities, entities]
	);

	const closeAndResetModal = useCallback(() => {
		closeEntityTooltips();
		setOpen(false);
		setSearchTerm("");
		setAppToFilter("All modules");
		setEntities(entities.map((entity) => ({ ...entity, selected: false })));
	}, [entities]);

	const toggleSelectAll = useCallback(() => {
		const targetSelectedState = !visibleEntities.filter(({ disabled }) => !disabled).every((entity) => entity.selected == true);

		setEntities(
			entities.map((entity) => {
				// only operate on visible entities
				if (visibleEntities.find((ve) => ve.entityId == entity.entityId)) {
					return {
						...entity,
						selected: entity.disabled ? false : targetSelectedState
					};
				}
				return entity;
			})
		);
	}, [setEntities, entities, visibleEntities]);

	return (
		<Modal
			id="bulkAddStandardEntityModal"
			heading={MODAL_HEADING}
			description={MODAL_DESCRIPTION}
			isOpen={open}
			onRequestClose={closeAndResetModal}
			actionsRight={<ModalButtons closeAndResetModal={closeAndResetModal} onSubmit={onSubmit} selectedEntities={selectedEntities} />}
		>
			<Actions
				searchTerm={searchTerm}
				setSearchTerm={setSearchTerm}
				listOfApps={listOfApps}
				appToFilter={appToFilter}
				setAppToFilter={setAppToFilter}
				showCurrentEntities={showCurrentEntities}
				setShowCurrentEntities={setShowCurrentEntities}
				isExporting={isExporting}
				setIsExporting={setIsExporting}
				modelCode={modelCode}
			/>
			<Body
				entities={entities}
				visibleEntities={visibleEntities}
				selectedEntities={selectedEntities}
				toggleSelectAll={toggleSelectAll}
				handleSelectChange={handleSelectChange}
				closeEntityTooltips={closeEntityTooltips}
			/>
		</Modal>
	);
};
