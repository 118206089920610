import React, { useCallback } from "react";
import Button, { BUTTON_DISPLAY } from "@growthos/ui-button";

export default ({ closeAndResetModal, onSubmit, selectedEntities }) => {
	const handleSubmit = useCallback(() => {
		onSubmit?.(selectedEntities?.map(({ entityId }) => entityId));
		closeAndResetModal();
	}, [selectedEntities]);

	return (
		<>
			<Button display={BUTTON_DISPLAY.SECONDARY} onClick={closeAndResetModal}>
				Cancel
			</Button>
			<Button onClick={handleSubmit} disabled={selectedEntities?.length == 0}>
				Save and add
			</Button>
		</>
	);
};
