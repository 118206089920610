import React, { useEffect, useContext, useMemo, useState, useRef } from "react";
import ReactHtmlParser from "react-html-parser";
import moment from "moment";
import "./index.scss";
import { AppContext } from "../../common/AppProvider";
// Services
import ActivityLogService from "../../services/ActivityLogService";
import EntitiesService from "../../services/EntitiesService";
import DataService from "../../services/DataService";
// Component Library
import Button, { BUTTON_DISPLAY, BUTTON_SIZE } from "@growthos/ui-button";
import { DataProvider } from "@growthos/ui-theme";
import DataTable, { ColumnTemplate, TD_TYPE } from "@growthos/ui-datatable";
import Heading, { HEADING_SIZE } from "@growthos/ui-heading";
import FilterChip, { FILTER_TYPE } from "@growthos/ui-filter-chip";
import { NavLink } from "@growthos/ui-navigation";
import Textbox from "@growthos/ui-textbox";

export default function ActivityLog() {
	const { selectedModel, setIsLoading, setPageHeaderValues } = useContext(AppContext);

	const [selectedTab, setSelectedTab] = useState("Entities");
	const [userList, setUserList] = useState([]);
	const [entitiesList, setEntitiesList] = useState([]);
	const [countriesList, setCountriesList] = useState([]);
	const [regionsList, setRegionsList] = useState([]);
	// Entity
	const [entityDataProvider, setEntityDataProvider] = useState(null);
	const [entityParameters, setEntityParameters] = useState({ queryParams: { pageNumber: 0, pageSize: 10, type: "entity" }, filterValues: {} });
	const [entityPaginationProps, setEntityPaginationProps] = useState({});
	const entityActionTypes = [<option value="Create">Entity added</option>, <option value="Update">Entity edited</option>];
	// Value
	const [valueDataProvider, setValueDataProvider] = useState(null);
	const [valueParameters, setValueParameters] = useState({ queryParams: { pageNumber: 0, pageSize: 10, type: "value" }, filterValues: {} });
	const [valuePaginationProps, setValuePaginationProps] = useState({});
	const valueActionTypes = [
		<option value="Create">Value added</option>,
		<option value="Update">Value edited</option>,
		<option value="Archive">Value archived</option>,
		<option value="Unarchive">Value unarchived</option>
	];
	// Hierarchy
	const [hierarchyDataProvider, setHierarchyDataProvider] = useState(null);
	const [hierarchyParameters, setHierarchyParameters] = useState({ queryParams: { pageNumber: 0, pageSize: 10, type: "hierarchy" }, filterValues: {} });
	const [hierarchyPaginationProps, setHierarchyPaginationProps] = useState({});
	const hierarchyActionTypes = [
		<option value="Create">Attribute/Hierarchy created</option>,
		<option value="Update">Attribute/Hierarchy edited</option>,
		<option value="Delete">Attribute/Hierarchy deleted</option>
	];

	const paginationConstants = {
		pageSize: 10,
		dropUp: true,
		rowOptions: [{ label: "10", value: 10 }]
	};

	const columns = [
		<ColumnTemplate
			key="createdDate"
			bind="createdDate"
			type={TD_TYPE.TEXT}
			isSortable={true}
			onRender={(bindData, rowData) => {
				if (bindData) {
					return <>{moment(bindData).format("MM/DD/YYYY, h:mm A")}</>;
				} else {
					return <></>;
				}
			}}
		>
			Date & time
		</ColumnTemplate>,
		<ColumnTemplate key="userName" bind="userName" type={TD_TYPE.TEXT} isSortable={true}>
			User
		</ColumnTemplate>,
		<ColumnTemplate key="entity" bind="entity" type={TD_TYPE.TEXT} isSortable={true}>
			Entity
		</ColumnTemplate>,
		<ColumnTemplate key="actionType" bind="actionType" type={TD_TYPE.TEXT} isSortable={true}>
			Action type
		</ColumnTemplate>,
		<ColumnTemplate
			key="actionDescription"
			bind="actionDescription"
			type={TD_TYPE.TEXT}
			isSortable={true}
			onRender={(bindData, rowData) => {
				if (bindData) {
					return (
						<>
							{JSON.parse(bindData)?.map((message) => (
								<>
									{ReactHtmlParser(message)}
									<br />
								</>
							))}
						</>
					);
				} else {
					return <></>;
				}
			}}
		>
			Action description
		</ColumnTemplate>
	];

	useEffect(() => {
		if (selectedModel) {
			setIsLoading(true);
			getActivityLogData("entity").then((entityData) => {
				setEntityPaginationProps({
					...paginationConstants,
					totalSize: entityData.count,
					value: { label: entityParameters.queryParams.pageNumber + 1, value: entityParameters.queryParams.pageNumber + 1 },
					onChange: (value) => {
						const parameters = returnParametersForType("entity");
						parameters.queryParams.pageNumber = value.value - 1;
						handleParametersUpdate(parameters, "entity");
					}
				});
				setEntityDataProvider(new DataProvider({ data: entityData.results, currentData: entityData.results, rowsPerPage: 10 }));
				setIsLoading(false);
			});
		}
	}, [entityParameters]);

	useEffect(() => {
		if (selectedModel) {
			setIsLoading(true);
			getActivityLogData("value").then((valueData) => {
				setValuePaginationProps({
					...paginationConstants,
					totalSize: valueData.count,
					value: { label: valueParameters.queryParams.pageNumber + 1, value: valueParameters.queryParams.pageNumber + 1 },
					onChange: (value) => {
						const parameters = returnParametersForType("value");
						parameters.queryParams.pageNumber = value.value - 1;
						handleParametersUpdate(parameters, "value");
					}
				});
				setValueDataProvider(new DataProvider({ data: valueData.results, currentData: valueData.results, rowsPerPage: 10 }));
				setIsLoading(false);
			});
		}
	}, [valueParameters]);

	useEffect(() => {
		if (selectedModel) {
			setIsLoading(true);
			getActivityLogData("hierarchy").then((hierarchyData) => {
				setHierarchyPaginationProps({
					...paginationConstants,
					totalSize: hierarchyData.count,
					value: { label: hierarchyParameters.queryParams.pageNumber + 1, value: hierarchyParameters.queryParams.pageNumber + 1 },
					onChange: (value) => {
						const parameters = returnParametersForType("hierarchy");
						parameters.queryParams.pageNumber = value.value - 1;
						handleParametersUpdate(parameters, "hierarchy");
					}
				});
				setHierarchyDataProvider(new DataProvider({ data: hierarchyData.results, currentData: hierarchyData.results, rowsPerPage: 10 }));
				setIsLoading(false);
			});
		}
	}, [hierarchyParameters]);

	useEffect(() => {
		if (selectedModel) {
			setIsLoading(true);
			EntitiesService.getEntitiesData(selectedModel.code).then((response) => {
				setEntitiesList(
					response.map((entity) => {
						return { value: entity.code, label: entity.name };
					})
				);
				// DataService.getRegionCountryList().then((countryRegionData) => {
				// 	const newCountriesList = countryRegionData["Master Country"]?.data
				// 		.filter((country) => {
				// 			return country.Code != "ZZZ";
				// 		})
				// 		.map((country) => {
				// 			const parentRegion = countryRegionData["Master Region"]?.data?.find((region) => {
				// 				return region.Name == country.Region.substring(0, country.Region.indexOf(":"));
				// 			});

				// 			return {
				// 				label: country.Name,
				// 				value: country.ID,
				// 				regionName: parentRegion.Name,
				// 				regionMUID: parentRegion.MUID
				// 			};
				// 		});
				// 	const newRegionsList = countryRegionData["Master Region"]?.data
				// 		.filter((region) => {
				// 			return region.Code != "ZZZ";
				// 		})
				// 		.map((region) => {
				// 			return {
				// 				label: region.Name,
				// 				value: region.ID,
				// 				MUID: region.MUID
				// 			};
				// 		});
				// 	setCountriesList(newCountriesList);
				// 	setRegionsList(newRegionsList);
				// });
				ActivityLogService.getUserList(selectedModel.code).then((users) => {
					setUserList(
						users?.map((user) => ({
							label: `${user.givenName} ${user.familyName}`,
							value: user.oid
						}))
					);
				});
				getActivityLogData("entity").then((entityData) => {
					setEntityPaginationProps({
						...paginationConstants,
						totalSize: entityData.count,
						value: { label: entityParameters.queryParams.pageNumber + 1, value: entityParameters.queryParams.pageNumber + 1 },
						onChange: (value) => {
							const parameters = returnParametersForType("entity");
							parameters.queryParams.pageNumber = value.value - 1;
							handleParametersUpdate(parameters, "entity");
						}
					});
					setEntityDataProvider(new DataProvider({ data: entityData.results, currentData: entityData.results, rowsPerPage: 10 }));
					getActivityLogData("value").then((valueData) => {
						setValuePaginationProps({
							...paginationConstants,
							totalSize: valueData.count,
							value: { label: valueParameters.queryParams.pageNumber + 1, value: valueParameters.queryParams.pageNumber + 1 },
							onChange: (value) => {
								const parameters = returnParametersForType("value");
								parameters.queryParams.pageNumber = value.value - 1;
								handleParametersUpdate(parameters, "value");
							}
						});
						setValueDataProvider(new DataProvider({ data: valueData.results, currentData: valueData.results, rowsPerPage: 10 }));
						getActivityLogData("hierarchy").then((hierarchyData) => {
							setHierarchyPaginationProps({
								...paginationConstants,
								totalSize: hierarchyData.count,
								value: { label: hierarchyParameters.queryParams.pageNumber + 1, value: hierarchyParameters.queryParams.pageNumber + 1 },
								onChange: (value) => {
									const parameters = returnParametersForType("hierarchy");
									parameters.queryParams.pageNumber = value.value - 1;
									handleParametersUpdate(parameters, "hierarchy");
								}
							});
							setHierarchyDataProvider(new DataProvider({ data: hierarchyData.results, currentData: hierarchyData.results, rowsPerPage: 10 }));
							updateHeaders();
							setIsLoading(false);
						});
					});
				});
			});
		}
	}, [selectedModel]);

	useEffect(() => {
		updateHeaders();
	}, [selectedTab]);

	function getFormattedDate(dateSelection) {
		const fmt = "M/D/YYYY hh:mm:ss A";

		switch (dateSelection) {
			case "today":
				return {
					StartDate: moment().startOf("day").format(fmt),
					EndDate: moment().endOf("day").format(fmt)
				};
			case "yesterday":
				return {
					StartDate: moment().subtract(1, "day").startOf("day").format(fmt),
					EndDate: moment().subtract(1, "day").endOf("day").format(fmt)
				};
			case "thisWeek":
				return {
					StartDate: moment().startOf("week").format(fmt),
					EndDate: moment().endOf("week").format(fmt)
				};
			case "lastWeek":
				return {
					StartDate: moment().subtract(1, "week").startOf("week").format(fmt),
					EndDate: moment().subtract(1, "week").endOf("week").format(fmt)
				};
			case "thisMonth":
				return {
					StartDate: moment().startOf("month").format(fmt),
					EndDate: moment().endOf("month").format(fmt)
				};
			default:
				return "";
		}
	}

	function getActivityLogData(type) {
		const parameters = returnParametersForType(type);
		const filterValues = parameters.filterValues;
		const filterObj = {};
		if (filterValues.dateRange) {
			const { StartDate, EndDate } = getFormattedDate(filterValues.dateRange);
			filterObj.StartDate = StartDate;
			filterObj.EndDate = EndDate;
		}
		if (filterValues.actionType) {
			filterObj.Action = filterValues.actionType;
		}
		if (filterValues.searchValue) {
			filterObj.Keyword = filterValues.searchValue;
		}
		if (filterValues.entity) {
			filterObj.EntityGUID = filterValues.entity;
		}
		if (filterValues.user) {
			filterObj.UserID = filterValues.user;
		}
		return ActivityLogService.getHistoryLog(selectedModel.code, parameters.queryParams, filterObj);
	}

	function returnParametersForType(type) {
		if (type === "entity") {
			return { ...entityParameters };
		} else if (type === "value") {
			return { ...valueParameters };
		} else if (type === "hierarchy") {
			return { ...hierarchyParameters };
		}
	}

	function updateHeaders() {
		const subnavLinks = [
			<NavLink
				className="unborderedNavlink"
				selected={selectedTab === "Entities"}
				onClick={() => {
					setSelectedTab("Entities");
				}}
			>
				Entities
			</NavLink>,
			<NavLink
				className="unborderedNavlink"
				selected={selectedTab === "Values"}
				onClick={() => {
					setSelectedTab("Values");
				}}
			>
				Values
			</NavLink>,
			<NavLink
				className="unborderedNavlink"
				selected={selectedTab === "Hierarchies"}
				onClick={() => {
					setSelectedTab("Hierarchies");
				}}
			>
				Hierarchies & attributes
			</NavLink>
		];
		setPageHeaderValues({ subnavLinks });
	}

	function handleParametersUpdate(parameters, type) {
		if (type === "entity") {
			setEntityParameters(parameters);
		} else if (type === "value") {
			setValueParameters(parameters);
		} else if (type === "hierarchy") {
			setHierarchyParameters(parameters);
		}
	}

	function updateParameters(parameters, value, field) {
		if (value) {
			parameters.filterValues[field] = value;
		} else {
			delete parameters.filterValues[field];
		}
		parameters.queryParams.pageNumber = 0;
		return parameters;
	}

	function renderContentForType(type) {
		const parameters = returnParametersForType(type);
		const renderedContent = [];
		const actionTypes = type === "entity" ? entityActionTypes : type === "value" ? valueActionTypes : type === "hierarchy" ? hierarchyActionTypes : null;
		renderedContent.push(
			<div className="table-actions">
				<Textbox
					hideLabel={true}
					id={`search-${type}`}
					className="search-table-textbox"
					type="search"
					placeholder="Search"
					debounceTimeout={1000}
					onChange={(evt, value) => {
						const newParameters = updateParameters(parameters, value?.trim() || null, "searchValue");
						handleParametersUpdate(newParameters, type);
					}}
				>
					Search {type}
				</Textbox>
				<div className="divider" />
				<FilterChip
					value={parameters.filterValues.dateRange || undefined}
					type={FILTER_TYPE.SELECT}
					listItems={[
						<option value="today">Today</option>,
						<option value="yesterday">Yesterday</option>,
						<option value="thisWeek">This Week</option>,
						<option value="lastWeek">Last Week</option>,
						<option value="thisMonth">This Month</option>
					]}
					isClearable={true}
					onChange={(evt, value) => {
						const newParameters = updateParameters(parameters, value || null, "dateRange");
						handleParametersUpdate(newParameters, type);
					}}
				>
					Date range
				</FilterChip>
				<FilterChip
					value={parameters.filterValues.user || undefined}
					type={FILTER_TYPE.SELECT}
					listItems={userList.map((user) => {
						return <option value={user.value}>{user.label}</option>;
					})}
					isClearable={true}
					onChange={(evt, value) => {
						const newParameters = updateParameters(parameters, value || null, "user");
						handleParametersUpdate(newParameters, type);
					}}
				>
					User
				</FilterChip>
				<FilterChip
					value={parameters.filterValues.actionType || undefined}
					type={FILTER_TYPE.SELECT}
					listItems={actionTypes}
					isClearable={true}
					onChange={(evt, value) => {
						const newParameters = updateParameters(parameters, value || null, "actionType");
						handleParametersUpdate(newParameters, type);
					}}
				>
					Action type
				</FilterChip>
				<FilterChip
					value={parameters.filterValues.entity || undefined}
					type={FILTER_TYPE.SELECT}
					listItems={entitiesList.map((entity) => {
						return <option value={entity.value}>{entity.label}</option>;
					})}
					isClearable={true}
					onChange={(evt, value) => {
						const newParameters = updateParameters(parameters, value || null, "entity");
						handleParametersUpdate(newParameters, type);
					}}
				>
					Entity
				</FilterChip>
				{/* <FilterChip
					value={parameters.filterValues.region || undefined}
					type={FILTER_TYPE.SELECT}
					listItems={regionsList.map((region) => {
						return <option value={region.MUID}>{region.label}</option>;
					})}
					isClearable={true}
					onChange={(evt, value) => {
						const newParameters = updateParameters(parameters, value || null, "region");
						delete newParameters.filterValues.country;
						handleParametersUpdate(newParameters, type);
					}}
				>
					Region
				</FilterChip>
				<FilterChip
					value={parameters.filterValues.country || undefined}
					type={FILTER_TYPE.SELECT}
					listItems={
						parameters.filterValues.region
							? countriesList
									.filter((filteredCountries) => {
										return filteredCountries.regionMUID === parameters.filterValues.region;
									})
									.map((country) => {
										return <option value={country.value.toString()}>{country.label}</option>;
									})
							: []
					}
					isClearable={true}
					disabled={!parameters.filterValues.region}
					onChange={(evt, value) => {
						const newParameters = updateParameters(parameters, value || null, "country");
						handleParametersUpdate(newParameters, type);
					}}
				>
					Country
				</FilterChip> */}
				<Button
					display={BUTTON_DISPLAY.TEXT}
					size={BUTTON_SIZE.SMALL}
					disabled={
						!Object.keys(parameters.filterValues).filter((filterField) => {
							return filterField !== "searchValue";
						}).length
					}
					onClick={() => {
						Object.keys(parameters.filterValues).forEach((filterField) => {
							if (filterField !== "searchValue") {
								delete parameters.filterValues[filterField];
							}
						});
						handleParametersUpdate(parameters, type);
					}}
				>
					Clear all
				</Button>
			</div>
		);
		const dataProvider = type === "entity" ? entityDataProvider : type === "value" ? valueDataProvider : type === "hierarchy" ? hierarchyDataProvider : null;
		const paginationProps = type === "entity" ? entityPaginationProps : type === "value" ? valuePaginationProps : type === "hierarchy" ? hierarchyPaginationProps : null;
		renderedContent.push(
			<DataTable
				actionBarProps={{ visible: false }}
				paginationProps={paginationProps}
				data={dataProvider}
				emptyState={
					<Heading size={HEADING_SIZE.MEDIUM} description="No data">
						No values to display
					</Heading>
				}
			>
				{columns}
			</DataTable>
		);
		return renderedContent;
	}

	return (
		<div className="activity-log-container">
			<div className={`table-container ${selectedTab !== "Entities" ? "is-hidden" : ""}`}>{renderContentForType("entity")}</div>
			<div className={`table-container ${selectedTab !== "Values" ? "is-hidden" : ""}`}>{renderContentForType("value")}</div>
			<div className={`table-container ${selectedTab !== "Hierarchies" ? "is-hidden" : ""}`}>{renderContentForType("hierarchy")}</div>
		</div>
	);
}
