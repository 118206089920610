import React, { useCallback, useEffect, useMemo, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";

import { AppContext } from "../../../common/AppProvider";
import { EntitiesContext } from "../../../common/EntitiesContext";
import { AttributesContext } from "../../../common/AttributesContext";

import { ENTITY_DETAILS_EDIT_DISABLED_KEYS } from "./constants";
import EditAttributes from "./EditAttributes";

export default function EntitySettings() {
	const navigate = useNavigate();
	const { userRoles, selectedModel, isLoading, modelHierarchies } = useContext(AppContext);
	const { selectedEntity } = useContext(EntitiesContext);
	const { entityAttributes } = useContext(AttributesContext);

	const params = useParams();

	const handleGoBack = useCallback(() => {
		navigate(`/model/${params.modelCode}/entity/${params.entityCode}/values`);
	}, [navigate, params]);

	useEffect(() => {
		const globalCheck = selectedModel?.modelType === "Global" && userRoles?.isGlobalUser === false;
		const nullCheck = selectedEntity !== null;
		const szrtCheck = !["SZ", "RT"].includes(selectedEntity?.dataKeyCode);
		const disabledKeys = ENTITY_DETAILS_EDIT_DISABLED_KEYS.includes(selectedEntity?.dataKeyCode) && ["OS Standard Model", "Global"].includes(selectedModel?.name);

		if (globalCheck && (!nullCheck || !szrtCheck || !disabledKeys)) {
			handleGoBack?.();
		}
	}, [handleGoBack, selectedEntity, selectedModel?.modelType, selectedModel?.name, userRoles?.isGlobalUser]);

	return <EditAttributes handleGoBack={handleGoBack} hierarchies={modelHierarchies} attributes={entityAttributes} loading={isLoading} selectedModel={selectedModel} />;
}
