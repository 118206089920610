import React, { useMemo } from "react";
import Button from "@growthos/ui-button";
import Status from "@growthos/ui-status";

import AvailableRow from "./AvalableRow";
import SelectedRow from "./SelectedRow";

const NO_VISIBLE_ENTITIES_MESSAGE = "Not finding what you're looking for? You can always add new entities later.";

export default ({ entities, visibleEntities, selectedEntities, toggleSelectAll, handleSelectChange, closeEntityTooltips }) => {
	const selectAllButtonText = useMemo(() => {
		return visibleEntities?.filter(({ disabled }) => !disabled).every((entity) => entity.selected == true) ? "Deselect All" : "Select All" || [];
	}, [entities, visibleEntities]);

	return (
		<div className="bulkAddEntityBody">
			<div className="bulkAddSection bulkAddEntityAvailable">
				<div className="bulkAddSectionHeader">
					<h4 style={{ display: "inline-block" }}>Available entities</h4>
					<Button display="text" style={{ fontSize: 12 }} onClick={toggleSelectAll}>
						{selectAllButtonText}
					</Button>
				</div>

				{visibleEntities?.length > 0 ? (
					<div className="bulkAddSectionRows">
						{visibleEntities.map((rowInfo) => (
							<AvailableRow key={rowInfo.entityId} {...rowInfo} onChange={handleSelectChange} closeEntityTooltips={closeEntityTooltips} />
						))}
					</div>
				) : (
					<div className="bulkAddSectionRowsEmpty">{NO_VISIBLE_ENTITIES_MESSAGE}</div>
				)}
			</div>
			<div className="bulkAddSection bulkAddEntitySelected">
				<div className="bulkAddSectionHeader">
					<h4>Entities to add</h4>
					<Status type="no-status" isBadge={true} title={`${selectedEntities?.length} selected` || "0 selected"} />
				</div>
				<div className="bulkAddSectionRows">
					{selectedEntities.map((rowInfo) => (
						<SelectedRow key={rowInfo.entityId} {...rowInfo} onChange={(id) => handleSelectChange(id, false)} />
					))}
				</div>
			</div>
		</div>
	);
};
