import RequestHelpers from "../helpers/RequestHelpers";
const { doRequest } = RequestHelpers;

export default class AppAssignmentService {
	static getApplicationsList(modelCode) {
		return doRequest({
			uri: "Application/GetList",
			params: { modelCode }
		});
	}

	static getListOfEntities(applicationCode, modelCode) {
		return doRequest({
			uri: "Application/GetEntityListByModelApplicationList",
			params: { applicationCode, modelCode }
		});
	}

	static saveEntities(applicationCode, modelCode, entityArray) {
		return doRequest({
			method: "POST",
			uri: "Application/SaveEntityAssignment",
			params: { applicationCode, modelCode },
			body: JSON.stringify(entityArray)
		});
	}

	static getAllDataValues(modelCode, entityCode) {
		return doRequest({
			uri: "Data/GetListForUI",
			params: { modelCode, entityCode, fromPage: "appconfig", archivedEnum: 1 }
		});
	}

	static getSavedDataValues(applicationCode, modelCode, entityCode) {
		return doRequest({
			uri: "Application/GetDataValuesByApplicationModeEntitylList",
			params: { modelCode, entityCode, applicationCode }
		});
	}

	static saveDataValues(applicationCode, modelCode, entityCode, dataValueArray) {
		return doRequest({
			method: "POST",
			uri: "Application/SaveDataValueConfigurationOptions",
			params: { entityCode, modelCode, applicationCode },
			body: JSON.stringify(dataValueArray)
		});
	}

	static getMandatoryEntities(applicationCode, modelCode) {
		return doRequest({
			uri: "Application/GetApplicationConfigurationoptions",
			params: { modelCode, applicationCode }
		});
	}
}
