import React from "react";

import PropTypes from "prop-types";

const propTypes = {
	entityId: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	code: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired
};

export default function SelectedRow({ entityId, name, code, onChange }) {
	return (
		<div className="bulkAddEntitySelectedRow">
			<span>
				{name} ({code})
			</span>
			<i className="icon-close unselectEntity" onClick={() => onChange(entityId)} />
		</div>
	);
}

SelectedRow.propTypes = propTypes;
