import RequestHelpers from "../helpers/RequestHelpers";
const { doRequest } = RequestHelpers;

export default class HierarchyService {
	static getAllDataHierarchy(modelId, hierarchyName, pageNumber, pageSize) {
		return doRequest({
			uri: "Hierarchy/GetData",
			params: {
				modelCode: modelId,
				hierarchyName,
				pageNumber,
				pageSize
			}
		});
	}

	static getAllHierarchies(modelCode) {
		return doRequest({
			uri: "Hierarchy/GetList",
			params: { modelCode }
		});
	}

	static getAllLevelsByHierarchy(modelCode, hierarchyName) {
		return doRequest({
			uri: "Hierarchy/GetLevelList",
			params: { modelCode, hierarchyName }
		});
	}

	static updateAllLevelsByHierarchy(rowList) {
		return doRequest({
			method: "PUT",
			uri: "Hierarchy/UpgradeAllLevels",
			body: JSON.stringify(rowList)
		});
	}

	static Save(model) {
		return doRequest({
			method: "POST",
			uri: "Hierarchy/Create",
			body: JSON.stringify(model)
		});
	}

	static Update(modelCode, rowList) {
		return doRequest({
			method: "PUT",
			uri: "Hierarchy/Update",
			params: { modelCode },
			body: JSON.stringify(rowList)
		});
	}

	static Delete(hierarchyId, modelCode) {
		return doRequest({
			method: "DELETE",
			uri: "Hierarchy/Delete",
			params: { Hierarchyid: hierarchyId, modelCode }
		});
	}

	static editHierarchyName(id, modelCode, newName) {
		return doRequest({
			method: "PUT",
			uri: "Hierarchy/Update",
			params: { modelCode },
			body: JSON.stringify({
				id,
				modelId: modelCode,
				modelCode,
				name: newName
			})
		});
	}

	static getHierarchyData(modelCode, hierarchyCode) {
		return doRequest({
			uri: "HierarchyExportView/GetHierarchyData",
			params: { modelCode, hierarchyCode }
		});
	}
}
