import React, { useContext } from "react";
import "./ArchiveModal.scss";
// Services
// Component Library
import Button, { BUTTON_DISPLAY } from "@growthos/ui-button";
import Modal from "@growthos/ui-modal";
// Context
import { EntitiesContext } from "../../../../common/EntitiesContext";

export default ({ type, selectedValues, handleConfirm }) => {
	const { selectedEntity } = useContext(EntitiesContext);
	return (
		<Modal
			className="archive-modal"
			id={`${type}-modal`}
			heading={`${type} values?`}
			description={
				<span>
					Are you sure you want to {type.toLowerCase()} the following {selectedValues.length !== 1 ? <b>{selectedValues.length} values</b> : "value"} for entity{" "}
					<b>{selectedEntity.name}</b>?
				</span>
			}
			onRequestClose={() => Modal.hide(`${type}-modal`)}
			actionsRight={
				<div style={{ display: "flex" }}>
					<Button id={`close-${type}-modal-button`} display={BUTTON_DISPLAY.SECONDARY} onClick={() => Modal.hide(`${type}-modal`)}>
						Cancel
					</Button>
					<Button
						id={`save-${type}-modal-button`}
						onClick={() => {
							Modal.hide(`${type}-modal`);
							handleConfirm();
						}}
					>
						Yes, {type.toLowerCase()}
					</Button>
				</div>
			}
		>
			<div className="selected-values-container">
				<div className="selected-values-header">
					<span className="value-code">Code</span>
					<span className="value-name">Name</span>
				</div>
				<div className="selected-values-list">
					{selectedValues?.slice(0, 10)?.map((value) => {
						return (
							<div className="selected-value" key={value.Code}>
								<span className="value-code">{value.Code}</span>
								<span className="value-name">{value.Name}</span>
							</div>
						);
					})}
				</div>
				{selectedValues.length > 10 && <div className="more-values">+{selectedValues.length - 10} more</div>}
			</div>
		</Modal>
	);
};
