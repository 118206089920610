import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";

// Component Library
import AppSDK from "@growthos/ui-app-sdk";

import Grid from "@growthos/ui-grid";
import Loading from "@growthos/ui-loading";

// Services
import ModelsService from "../services/ModelsService";
import HierarchyService from "../services/HierarchyService";
import ModelSelector from "./ModelSelector";
import PageHeader from "./PageHeader";
import useAsyncEffect from "./useAsyncEffect";

const propTypes = {
	children: PropTypes.node
};

export const AppContext = React.createContext();

export const AppContextProvider = ({ children, userData }) => {
	const [modelsList, setModelsList] = useState([]);
	const [selectedModel, setSelectedModel] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [modelHierarchies, setModelHierarchies] = useState([]);
	const [pageHeader, setPageHeader] = useState({});
	const [selectedPage, setSelectedPage] = useState(0);
	const [userRole, setUserRole] = useState({});

	useAsyncEffect(async () => {
		const cid = AppSDK.getContextId();
		if (cid) {
			try {
				const models = await ModelsService.getModelsData(cid);
				setModelsList(models?.map((model) => ({ ...model, label: model.name, value: model.code })) || []);
			} catch (error) {
				console.log(error);
			}
		}
	}, []);

	useEffect(() => {
		setPageHeader({});
	}, [selectedPage]);

	useEffect(() => {
		setUserRole(userData);
	}, [userData]);

	useAsyncEffect(async () => {
		if (selectedModel?.code) {
			setModelHierarchies(await HierarchyService.getAllHierarchies(selectedModel.code));
			// const {masterAgencyDivisionID, masterBusinessUnitID, masterRegionID, masterCountryID} = AppSDK.getAARC();

			// const {roles} = await AppSDK.ApiUser.getRoles(masterAgencyDivisionID, masterBusinessUnitID, masterRegionID, masterCountryID, process.env.REACT_APP_APP_REGISTRY_ID);
			// const apps = await AppSDK.ApiUser.getUserApps({masterAgencyDivisionID, masterBusinessUnitID, masterRegionID, masterCountryID});
		}
	}, [selectedModel]);

	const contextValue = useMemo(
		() => ({
			modelsList,
			setModelsList,
			selectedModel,
			setSelectedModel,
			isLoading,
			setIsLoading,
			modelHierarchies,
			setModelHierarchies,
			userRole,
			setPageHeaderValues: setPageHeader
		}),
		[modelsList, selectedModel, isLoading, modelHierarchies, userRole]
	);

	return (
		<Grid id="pageContainer">
			{isLoading && <Loading details="">Loading</Loading>}
			<ModelSelector modelsList={modelsList} selectedModel={selectedModel} setSelectedModel={setSelectedModel} />
			<PageHeader
				{...pageHeader}
				selectedPage={selectedPage}
				setSelectedPage={setSelectedPage}
				selectedModel={selectedModel}
				setSelectedModel={setSelectedModel}
				userRole={userRole}
			/>
			<AppContext.Provider value={contextValue}>{children}</AppContext.Provider>
		</Grid>
	);
};

AppContextProvider.propTypes = propTypes;
AppContextProvider.componentName = "AppContextProvider";
