import React, { useContext, useEffect, useMemo, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import ArchiveModal from "../ArchiveModal/ArchiveModal";
import FilterSideSheet from "../FilterSideSheet/FilterSideSheet";
import StateHelper from "../../EntitySettings/StateHelper";
import _ from "lodash";
// Services
import DataService from "../../../../services/DataService";
import DataBatchService from "../../../../services/DataBatchService";
import RequestHelpers from "../../../../helpers/RequestHelpers";
// Context
import { AttributesContext } from "../../../../common/AttributesContext";
import { AppContext } from "../../../../common/AppProvider";
import { EntitiesContext } from "../../../../common/EntitiesContext";
// Component Library
import { BannerNotification, show } from "@growthos/ui-banner";
import Button, { BUTTON_DISPLAY, BUTTON_SIZE } from "@growthos/ui-button";
import { DataProvider } from "@growthos/ui-theme";
import DataTable, { ColumnTemplate, TD_TYPE, TD_ALIGN } from "@growthos/ui-datatable";
import { SearchIndex } from "@growthos/ui-dropdown";
import Heading, { HEADING_LEVEL, HEADING_SIZE } from "@growthos/ui-heading";
import Loading from "@growthos/ui-loading";
import Modal from "@growthos/ui-modal";
import Textbox from "@growthos/ui-textbox";
import Tooltip, { TOOLTIP_POSITION } from "@growthos/ui-tooltip";
import Popover from "@growthos/ui-popover";
import Status from "@growthos/ui-status";

export default function LiveValues({ setValuesType, setHierachyOpen, setNewValuesModalOpen, fetchValues, setFetchLiveValues, setFetchArchivedValues }) {
	const navigate = useNavigate();

	const tableApiRef = useRef();

	const { selectedModel, modelHierarchies } = useContext(AppContext);
	const { selectedEntity, setHideEntityList, hideEntityList, hierarchiesList, entityData } = useContext(EntitiesContext);
	const { entityAttributes } = useContext(AttributesContext);

	const [isLoading, setIsLoading] = useState(false);
	const [sidesheetOpen, setSidesheetOpen] = useState(false);
	const [isEditing, setIsEditing] = useState(false);
	const [statePage, setStatePage] = useState(1);
	const [stateColumns, setStateColumns] = useState([]);
	const [stateDataProvider, setStateDataProvider] = useState(null);
	const [statePaginationProps, setStatePaginationProps] = useState({});
	const [stateSelectedValues, setStateSelectedValues] = useState([]);
	const [editMade, setEditMade] = useState(false);
	const [rerenderTable, setRerenderTable] = useState(false);
	const [rawColumnData, setRawColumnData] = useState([]);
	const [downloadingValues, setDownloadingValues] = useState(false);
	const [searchValue, setSearchValue] = useState("");
	const [filterValues, setFilterValues] = useState([]);

	const isFreeForm = useMemo(() => {
		if (selectedEntity) {
			return selectedEntity.isFreeform;
		}
		return false;
	}, [selectedEntity]);

	const disableEdit = useMemo(() => {
		if (selectedModel?.modelTypeId !== 1 && ["RT", "SZ", "SCX"].includes(selectedEntity?.dataKeyCode)) {
			return true;
		}
		return false;
	}, [selectedEntity, selectedModel]);

	const disableAddAndArchive = useMemo(() => {
		if (["DMA", "MSA", "CY"].includes(selectedEntity?.dataKeyCode)) {
			return true;
		}
		return false;
	}, [selectedEntity]);

	const hasHierarchy = useMemo(() => {
		return hierarchiesList.length > 0;
	}, [hierarchiesList]);

	const selectedNamesAndCodes = useMemo(() => {
		return (
			stateSelectedValues.map((value) => {
				return { Code: value.Code, Name: value.Name, IsArchived: "1", MUID: value.MUID };
			}) || []
		);
	}, [stateSelectedValues]);

	useEffect(() => {
		const newColumns = [...stateColumns];
		const newPaginationProps = statePaginationProps;
		if (isEditing) {
			newColumns.shift();
			newPaginationProps.disabled = true;
		} else {
			// If we're going back to the uneditable state, make sure there is a checkbox column
			if (
				!newColumns.find((column) => {
					return column.key === "MUID";
				})
			) {
				newColumns.unshift(
					<ColumnTemplate key={"MUID"} bind="MUID" type={TD_TYPE.CHECKBOX}>
						Checkboxes
					</ColumnTemplate>
				);
			}
			newPaginationProps.disabled = false;
		}
		setStateColumns(newColumns);
		setStatePaginationProps(newPaginationProps);
		// the rerenderTable is needed to force the table to rerender with the new columns. Just changing the stateColumns value won't cause a refresh for some reason
		setRerenderTable(true);
	}, [isEditing]);

	useEffect(() => {
		if (entityData) {
			getDataValues({ pageNumber: statePage });
		}
	}, [entityData]);

	useEffect(() => {
		getDataValues({ pageNumber: 1 });
	}, [searchValue, filterValues]);

	useEffect(() => {
		if (rerenderTable) {
			setRerenderTable(false);
		}
	}, [rerenderTable]);

	useEffect(() => {
		// Refetch data if values have been archived
		if (fetchValues) {
			setFetchLiveValues(false);
			getDataValues({ pageNumber: statePage });
		}
	}, [fetchValues]);

	useEffect(() => {
		if (stateDataProvider) {
			setIsLoading(false);
			setTimeout(() => {
				reselectCheckboxesOnRefresh();
			}, 1000);
		}
	}, [stateDataProvider]);

	function getDataValues(apiProps = {}, resetSelectedRows = true) {
		setIsLoading(true);
		setStateDataProvider(null);
		if (resetSelectedRows) {
			setStateSelectedValues([]);
		}
		setStatePage(apiProps.pageNumber);
		const filtersJson = {};
		const orFilters = [];
		const andFilters = [];
		if (rawColumnData.length && searchValue.trim()) {
			rawColumnData.forEach((column) => {
				orFilters.push({ [column.name]: { $like: searchValue.trim() } });
			});
		}
		if (filterValues.length) {
			filterValues.forEach((filter) => {
				andFilters.push({ [filter.column]: { $like: filter.value.trim() } });
			});
		}
		if (orFilters.length) {
			filtersJson.$or = orFilters;
		}
		if (andFilters.length) {
			filtersJson.$and = andFilters;
		}
		DataService.getDataList({
			modelCode: selectedModel.code,
			entityCode: selectedEntity.code,
			pageSize: 50,
			archivedEnum: 1,
			filtersJson: JSON.stringify(filtersJson),
			...apiProps
		}).then((data) => {
			const columns = !isFreeForm
				? [
						<ColumnTemplate key={"MUID"} bind="MUID" type={TD_TYPE.CHECKBOX}>
							Checkboxes
						</ColumnTemplate>
					]
				: [];
			if (selectedEntity.name === "InName Site") {
				const rawSupplierColumn = data.colHeaders.find((col) => {
					return col.name?.toLowerCase() === "raw supplier code";
				});
				if (rawSupplierColumn) {
					const nameColumn = data.colHeaders.find((col) => {
						return col.name === "Name";
					});
					if (nameColumn) {
						rawSupplierColumn.sortOrderCustom = nameColumn.sortOrderCustom + 1;
					}
				}
			}

			const sortedColumns = data.colHeaders
				.sort(function (a, b) {
					return a.sortOrderCustom - b.sortOrderCustom;
				})
				.filter((column) => {
					return column.name !== "IsArchived";
				});
			setRawColumnData(sortedColumns);
			const searchIndicies = sortedColumns.reduce((acc, { type, sourceId }) => {
				if (type === "autocomplete" && sourceId) {
					acc[sourceId] = new SearchIndex();
				}
				return acc;
			}, {});
			sortedColumns.forEach((column) => {
				const mapping = entityAttributes.find((attribute) => {
					return attribute.code === column.code;
				});
				const isHierarchy = StateHelper.getHierarchy(modelHierarchies, mapping?.config_EntityCode, mapping?.code);
				const columnProps = {};
				if (column.type === "autocomplete") {
					columnProps.type = TD_TYPE.OPTIONS;
					columnProps.editProps = {
						menuPosition: "fixed",
						isClearable: true,
						isSearchable: true,
						asyncOptions: {
							pageSize: 10,
							searchIndex: searchIndicies[column.sourceId],
							requestValues: async ({ searchValue, page }) => {
								try {
									return await DataService.getEntityDataValuesByPage(
										column.sourceId,
										page + 1, //1 based indexing
										searchValue,
										10
									);
								} catch (e) {
									return { totalCount: 0, values: [] };
								}
							}
						},
						onChange: () => {
							if (!editMade) {
								setEditMade(true);
							}
						}
					};
				} else {
					columnProps.type = TD_TYPE.TEXT;
					columnProps.editProps = {
						onChange: () => {
							if (!editMade) {
								setEditMade(true);
							}
						}
					};
				}
				// if (column.type === "text") {
				// 	columnProps.type = TD_TYPE.TEXT;
				// 	columnProps.editProps = {
				// 		pattern: column.validator
				// 	};
				// } else if (column.type === "autocomplete") {

				// } else if (column.type === "numeric") {
				// 	const decimals = column?.format?.split(".")[1] ? column?.format?.split(".")[1].length : null;
				// 	console.log("decimals", decimals);
				// 	if (decimals !== null && decimals !== 1) {
				// 		const step = `0.${column?.format?.split(".")[1].substring(1)}1`;
				// 		columnProps.type = TD_TYPE.TEXT;
				// 		columnProps.editProps = {
				// 			step,
				// 			className: "currency-textbox"
				// 		};
				// 	} else {
				// 		columnProps.type = TD_TYPE.TEXT;
				// 	}
				// } else if (column.type === "date") {
				// 	columnProps.type = TD_TYPE.DATE;
				// }
				columns.push(
					<ColumnTemplate
						key={column.name}
						bind={column.name}
						isDraggable={column.name !== "Code" && column.name !== "Name"}
						isEditable={true}
						isDisabled={(column.name === "Code" || column.name === "Name") && disableAddAndArchive}
						isSortable={true}
						{...columnProps}
					>
						<div className="column-header">
							{isHierarchy && <i className="icon-tree-view hierarchy-icon" />}
							<span style={isHierarchy ? { marginLeft: "28px" } : {}}>{column.name !== "Name" ? column.name : `${selectedEntity.name} ${column.name}`}</span>
							<Status type="no-status" isBadge={true} title={column.dataCount?.toString()} />
						</div>
					</ColumnTemplate>
				);
			});
			setStateColumns(columns);
			const dataProvider = new DataProvider({
				data: data.data,
				currentData: data.data,
				rowsPerPage: 50
			});
			dataProvider.doSort = (a, b, prop, dir) => {
				// Parse property
				a = a[prop];
				b = b[prop];

				if ((a === null && b === null) || (a === undefined && b === undefined) || (a === null && b === undefined) || (a === undefined && b === null)) {
					return 0;
				}
				if ((a === undefined || a === null) && b !== undefined && b !== null) {
					switch (dir) {
						case DataProvider.SORT_DIRECTION.DSC:
							return 1;
						case DataProvider.SORT_DIRECTION.ASC:
						default:
							return -1;
					}
				} else if (a !== undefined && a !== null && (b === undefined || b === null)) {
					switch (dir) {
						case DataProvider.SORT_DIRECTION.DSC:
							return -1;
						case DataProvider.SORT_DIRECTION.ASC:
						default:
							return 1;
					}
				}

				// Validate dates
				// TODO: Find a better method to handle different date formats
				// eslint-disable-next-line no-useless-escape
				if (/^\d{1,2}[\/-]\d{1,2}[\/-]\d{4}$/.test(a) && /^\d{1,2}[\/-]\d{1,2}[\/-]\d{4}$/.test(b)) {
					a = new Date(a);
					b = new Date(b);
				} else {
					// Parse object
					a = a.data ?? a.value ?? a;
					b = b.data ?? b.value ?? b;

					// Parse currency/percent
					// TODO: Find a better method to handle different currency and number formats
					a = a
						.toString()
						// eslint-disable-next-line no-useless-escape
						.replace(/[\$,%]/g, "")
						.toLowerCase();
					b = b
						.toString()
						// eslint-disable-next-line no-useless-escape
						.replace(/[\$,%]/g, "")
						.toLowerCase();
					// Check if a or b only contain numbers and decimals, otherwise any string value that begins with a number will be parsed as a float
					if (/^[0-9.]+$/.test(a) && /^[0-9.]+$/.test(b)) {
						// Try to parse as number
						const aParsed = parseFloat(a);
						const bParsed = parseFloat(b);
						if (!isNaN(aParsed) && !isNaN(bParsed)) {
							a = aParsed;
							b = bParsed;
						}
					}
				}

				// Sort
				switch (dir) {
					case DataProvider.SORT_DIRECTION.DSC:
						return a < b ? 1 : b < a ? -1 : 0;
					case DataProvider.SORT_DIRECTION.ASC:
					default:
						return a > b ? 1 : b > a ? -1 : 0;
				}
			};
			setStateDataProvider(dataProvider);
			setStatePaginationProps({
				pageSize: 50,
				totalSize: data.count,
				dropUp: true,
				value: { label: apiProps.pageNumber, value: apiProps.pageNumber },
				rowOptions: [{ label: "50", value: 50 }],
				onChange: (value) => {
					getDataValues({ pageNumber: value.value }, false);
				}
			});
			setIsEditing(false);
		});
	}

	function handleValueSelection(evt, checkboxState, node) {
		if (!evt.isTrusted) {
			return;
		}
		let selectedValues = [...stateSelectedValues];
		const isSelectAll = node.value === "live-values-table-select-all";
		const selectedMUIDs = selectedValues.map((row) => {
			return row.MUID;
		});
		if (!isSelectAll) {
			if (checkboxState === true && !selectedMUIDs.includes(node?.renderProps?.value)) {
				const selectedValue = tableApiRef?.current?.dataProvider?.data?.find((row) => {
					return row.MUID === node?.renderProps?.value;
				});
				selectedValues.push(selectedValue);
			} else if (checkboxState === false) {
				selectedValues = selectedValues.filter((row) => {
					return row.MUID !== node.renderProps.value;
				});
			}
		} else {
			if (node.selectionState === true) {
				tableApiRef?.current?.dataProvider?.data?.forEach((row) => {
					if (!selectedMUIDs.includes(row.MUID)) {
						selectedValues.push(row);
					}
				});
			} else {
				const codesInCurrentPage = tableApiRef?.current?.dataProvider?.data?.map((row) => {
					return row.MUID;
				});
				selectedValues = selectedValues.filter((row) => {
					return !codesInCurrentPage.includes(row.MUID);
				});
			}
		}
		setStateSelectedValues(selectedValues);
	}

	function reselectCheckboxesOnRefresh() {
		if (stateSelectedValues.length) {
			const checkboxes = Array.from(document.querySelectorAll("input[id^='live-values-table'][type='checkbox']"));
			stateSelectedValues.forEach((row) => {
				const matchingCheckbox = checkboxes.find((checkbox) => {
					return checkbox.value === row.MUID;
				});
				if (matchingCheckbox && !matchingCheckbox?.checked) {
					matchingCheckbox.click();
				}
			});
		}
	}

	function handleSave() {
		tableApiRef?.current?.dataProvider?.save().then(({ data }) => {
			const columnNames = rawColumnData.map((column) => {
				return column.name;
			});
			const saveData = data.map((row) => {
				const saveObj = { MUID: row.MUID };
				Object.keys(row).forEach((field) => {
					if (columnNames.includes(field)) {
						saveObj[field] = row[field];
					}
				});
				return saveObj;
			});
			updateValues(saveData, "Entity updated", "Values were not updated");
		});
	}

	function updateValues(dataObj, successMessage, errorMessage, refreshArchivedValues = false) {
		setIsLoading(true);
		DataService.UpdateDataSet(selectedModel.code, selectedEntity.code, dataObj)
			.then(() => {
				show("mainBannerContainer", <BannerNotification type="success" title={successMessage} message="" timed={5000} />);
				getDataValues({ pageNumber: statePage });
				if (refreshArchivedValues) {
					setFetchArchivedValues(true);
				}
			})
			.catch(async (error) => {
				const errorObject = await error?.details?.json();
				if (errorObject?.message) {
					const errorMessages = errorObject?.message.split("--");
					show("mainBannerContainer", <BannerNotification type="error" title="Error" message={errorMessage} additionalDetails={errorMessages} />);
				}
				setIsLoading(false);
			});
	}

	function uploadValues(e) {
		if (e.target.value) {
			const data = e.target.files[0];
			setIsLoading(true);
			DataBatchService.editData(selectedModel.code, selectedEntity.code, data)
				.then(() => {
					show("mainBannerContainer", <BannerNotification type="success" title="Values uploaded" message="" timed={5000} />);
					getDataValues({ pageNumber: statePage });
				})
				.catch(async (error) => {
					const errorObject = await error?.details?.json();
					if (errorObject?.message) {
						const errorMessages = errorObject?.message.split("--");
						show("mainBannerContainer", <BannerNotification type="error" title="Error" message="Values were not uploaded" additionalDetails={errorMessages} />);
					}
					setIsLoading(false);
				});
		}
	}

	function downloadValues() {
		setDownloadingValues(true);
		DataBatchService.downloadData(selectedModel.code, selectedEntity.code)
			.then((response) => {
				RequestHelpers.downloadFile(response);
			})
			.catch(async (error) => {
				const errorObject = await error?.details?.json();
				if (errorObject?.message) {
					const errorMessages = errorObject?.message.split("--");
					show("mainBannerContainer", <BannerNotification type="error" title="Error" message="Values were not uploaded" additionalDetails={errorMessages} />);
				}
			})
			.finally(() => {
				setDownloadingValues(false);
			});
	}

	return (
		<>
			{isLoading && <Loading details="">Loading</Loading>}
			<div className="editEntityHeader">
				<div className="editEntityHeaderLeft">
					{!isEditing ? (
						<>
							<Tooltip title={hideEntityList ? "Minimize view" : "Expand view"} position={TOOLTIP_POSITION.BOTTOM_START}>
								<Button
									className={hideEntityList ? "sidebar-expanded" : ""}
									id="sidebar-toggle-button"
									icon={hideEntityList ? "icon-sidebar-open" : "icon-sidebar-close"}
									display={BUTTON_DISPLAY.ICON}
									onClick={() => {
										setHideEntityList(!hideEntityList);
									}}
								>
									Sidebar toggle
								</Button>
							</Tooltip>
							<h4 className="editEntityTitle">{selectedEntity.name}</h4>
							<Popover
								className="values-type-popover"
								trigger={
									<Button display={BUTTON_DISPLAY.TEXT} size={BUTTON_SIZE.SMALL} id="values-type-trigger">
										Live values
										<i className="icon-caret-down" />
									</Button>
								}
							>
								<button className="values-button is-selected" id="live-values-button" type="button">
									Live values
									<i className="icon-checkmark" />
								</button>
								<button className="values-button" id="archived-values-button" onClick={() => setValuesType("Archived")} type="button">
									Archived values
								</button>
							</Popover>
						</>
					) : (
						<h4 className="editEntityTitle">Editing {selectedEntity.name}</h4>
					)}
				</div>
				<div id="entityValuesActions">
					{!isEditing ? (
						<>
							<Popover
								trigger={
									<Button id="triggerPopoverButton" size={BUTTON_SIZE.SMALL} display={BUTTON_DISPLAY.ICON_BORDERED} icon="icon-more">
										Trigger Entity Values Popover
									</Button>
								}
							>
								<button
									id="editSettingsButton"
									disabled={disableEdit}
									onClick={() => navigate(`/model/${selectedModel.code}/entity/${selectedEntity.code}/settings`)}
									type="button"
								>
									<i className="icon-configure" />
									Edit settings
								</button>
								<div className="button-divider" />
								<button
									id="uploadValuesButton"
									type="button"
									disabled={isFreeForm || disableEdit}
									onClick={() => document.getElementById("upload-values-input").click()}
								>
									<i className="icon-upload" />
									Upload values
								</button>
								<button id="downloadValuesButton" type="button" disabled={isFreeForm || downloadingValues} onClick={downloadValues}>
									<i className="icon-download" />
									Download values
								</button>
							</Popover>
							{hasHierarchy && (
								<Button id="viewHierarchyButton" size={BUTTON_SIZE.SMALL} display={BUTTON_DISPLAY.SECONDARY} onClick={() => setHierachyOpen(true)}>
									View hierarchy
								</Button>
							)}
							<Button
								id="editValuesButton"
								size={BUTTON_SIZE.SMALL}
								display={BUTTON_DISPLAY.SECONDARY}
								icon="icon-pencil"
								disabled={isFreeForm || disableEdit}
								onClick={() => {
									setStateSelectedValues([]);
									setIsEditing(true);
								}}
							>
								Edit table
							</Button>
							<Button
								id="newValuesButton"
								icon="icon-add"
								size={BUTTON_SIZE.SMALL}
								display={BUTTON_DISPLAY.SECONDARY}
								onClick={() => setNewValuesModalOpen(true)}
								disabled={isFreeForm || disableEdit || disableAddAndArchive}
							>
								New values
							</Button>
						</>
					) : (
						<>
							<Button
								id="cancel-values-table-button"
								size={BUTTON_SIZE.SMALL}
								display={BUTTON_DISPLAY.SECONDARY}
								onClick={() => {
									if (editMade) {
										Modal.show("confirm-cancel-modal");
									} else {
										setIsEditing(false);
									}
								}}
							>
								Cancel
							</Button>
							<Button
								id="save-values-table-button"
								size={BUTTON_SIZE.SMALL}
								display={BUTTON_DISPLAY.PRIMARY}
								disabled={!editMade}
								onClick={() => {
									Modal.show("confirm-save-modal");
								}}
							>
								Save
							</Button>
						</>
					)}
				</div>
			</div>
			<div className="values-table-actions">
				{!isEditing && !isFreeForm && (
					<>
						{stateSelectedValues.length === 0 ? (
							<>
								<Textbox
									id="search-live-values"
									className="search-values-textbox"
									hideLabel={true}
									type="search"
									placeholder="Search"
									onChange={(evt, value) => {
										if (value?.trim()) {
											setSearchValue(value?.trim());
										}
									}}
									onClear={() => {
										setSearchValue("");
									}}
									debounceTimeout={1000}
									defaultValue={searchValue}
								>
									Search Data Table
								</Textbox>
								<div className="actions-divider" />
								<Button
									className={`${filterValues.length ? "filter-applied" : ""}`}
									display={BUTTON_DISPLAY.SECONDARY}
									size={BUTTON_SIZE.SMALL}
									icon="icon-filter-alt"
									onClick={() => {
										setSidesheetOpen(!sidesheetOpen);
									}}
								>
									Filters
								</Button>
							</>
						) : (
							<>
								<Button
									display={BUTTON_DISPLAY.SECONDARY}
									size={BUTTON_SIZE.SMALL}
									icon="icon-archive"
									onClick={() => Modal.show("Archive-modal")}
									disabled={disableEdit || disableAddAndArchive}
								>
									Archive
								</Button>
								<div className="actions-divider" />
								<span className="selected-count">{stateSelectedValues.length} selected</span>
							</>
						)}
					</>
				)}
			</div>
			{stateDataProvider && !rerenderTable && (
				<DataTable
					id="live-values-table"
					ref={tableApiRef}
					className="values-data-table"
					hasVirtualScrolling={false}
					bindKey={"MUID"}
					actionBarProps={{ visible: false }}
					data={!isFreeForm ? stateDataProvider : null}
					paginationProps={statePaginationProps}
					isEditing={isEditing}
					emptyState={
						<Heading
							size={HEADING_SIZE.MEDIUM}
							description={
								isFreeForm
									? "This is a freeform entity."
									: !searchValue.trim() && !filterValues.length
										? "This entity currently has no associated values."
										: "No data."
							}
						>
							No values to display
						</Heading>
					}
					onCheckboxChange={handleValueSelection}
				>
					{stateColumns}
				</DataTable>
			)}
			<FilterSideSheet isOpen={sidesheetOpen} onClose={() => setSidesheetOpen(false)} applyFilter={setFilterValues} columns={rawColumnData} savedFilters={filterValues} />
			<Modal
				id="confirm-save-modal"
				heading="Saving"
				description="All additions, deletions, and edits will be applied. Do you want to save all changes?"
				actionsRight={
					<React.Fragment>
						<Button
							display={BUTTON_DISPLAY.SECONDARY}
							onClick={() => {
								Modal.hide("confirm-save-modal");
							}}
						>
							Cancel
						</Button>
						<Button
							onClick={() => {
								Modal.hide("confirm-save-modal");
								handleSave();
							}}
						>
							Save
						</Button>
					</React.Fragment>
				}
			></Modal>
			<Modal
				id="confirm-cancel-modal"
				heading="Unsaved Changes"
				description="Would you like to save or discard your changes?"
				actionsRight={
					<React.Fragment>
						<Button
							display={BUTTON_DISPLAY.SECONDARY}
							onClick={() => {
								Modal.hide("confirm-cancel-modal");
								setIsEditing(false);
							}}
						>
							Discard
						</Button>
						<Button
							onClick={() => {
								Modal.hide("confirm-cancel-modal");
								handleSave();
							}}
						>
							Save
						</Button>
					</React.Fragment>
				}
			></Modal>
			<ArchiveModal
				type="Archive"
				selectedValues={selectedNamesAndCodes}
				handleConfirm={() => {
					updateValues(selectedNamesAndCodes, "Value(s) archived", "Values were not archived", true);
				}}
			/>
			<input
				id="upload-values-input"
				name="uploadValues"
				type="file"
				multiple
				onChange={uploadValues}
				onClick={(e) => (e.target.value = null)}
				accept=".xlsx"
				style={{ display: "none" }}
			/>
		</>
	);
}
