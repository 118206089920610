import React from "react";
import Status from "@growthos/ui-status";

export default ({ name, code, tooltip, closeEntityTooltips }) => {
	const handleOpenTooltip = (e) => {
		const modelPosition = document.getElementById("bulkAddStandardEntityModal").getBoundingClientRect();
		const selectedEntityTooltip = document.getElementById(`${code}_entityDetails`);

		if (selectedEntityTooltip?.style?.display === "none" || selectedEntityTooltip?.style?.display === "") {
			closeEntityTooltips();
			selectedEntityTooltip.style.display = "block";
			selectedEntityTooltip.style.top = `${e.pageY - modelPosition.top - selectedEntityTooltip.offsetHeight + 15}px`;
			selectedEntityTooltip.style.left = `${e.pageX - modelPosition.left + 20}px`;
		} else {
			selectedEntityTooltip.style.display = "none";
		}
	};

	const isJsonString = (tooltip) => {
		try {
			JSON.parse(tooltip);
		} catch (e) {
			return false;
		}
		return true;
	};

	const tooltipDetails =
		tooltip && isJsonString(tooltip)
			? JSON.parse(tooltip)
			: {
					Description: "",
					IncludesMappings: false,
					IncludesValues: false,
					RequiredBy: [],
					TypeOfList: ""
				};

	return (
		<>
			<i className="icon-question openTooltipIcon" onClick={handleOpenTooltip} style={{ cursor: "pointer" }} />
			<div id={`${code}_entityDetails`} className="entity_details_tooltip">
				<div className="entityName">{name}</div>
				<div className="entityDescription">
					<h4>Description</h4>
					{tooltipDetails?.Description}
				</div>
				{tooltipDetails?.RequiredBy?.length && tooltipDetails?.RequiredBy?.[0] != "" && (
					<div className="entityRequiredApps">
						<div className="requiredTitle">Required By</div>
						{tooltipDetails?.RequiredBy?.map((app) => {
							if (app != "") {
								return <Status type="no-status" isBadge={true} title={app} className="requiredApp" key={app} />;
							}
							return <></>;
						})}
					</div>
				)}
				{tooltipDetails?.IncludesMappings && <div className="extraDetails">Includes Mappings</div>}
				{tooltipDetails?.IncludesValues && <div className="extraDetails">Includes Values</div>}
				{tooltipDetails?.TypeOfList && <div className="extraDetails">{tooltipDetails?.TypeOfList}</div>}
			</div>
		</>
	);
};
