import RequestHelpers from "../helpers/RequestHelpers";
const { doRequest } = RequestHelpers;

export default class DataService {
	static getDataList({
		modelCode,
		entityCode,
		pageNumber,
		filtersJson,
		sortColumn = "",
		sortOrder = "",
		archivedEnum = 3,
		pageSize = 50,
		selectedRegion = null,
		selectedCountry = null
	}) {
		const queryParams = {
			modelCode,
			entityCode,
			pageNumber,
			filtersJson,
			"sort.column": sortColumn,
			"sort.order": sortOrder,
			count: 1,
			frompage: "null",
			archivedEnum
		};

		if (pageSize === 0) {
			queryParams.pageSize = pageSize;
		}

		if (selectedRegion && selectedCountry) {
			queryParams.regionID = selectedRegion;
			queryParams.countryID = selectedCountry;
		}

		return doRequest({ uri: "Data/GetListForUI", params: queryParams });
	}

	static getRegionCountryList() {
		return doRequest({ uri: "Data/GetMasterRegionCountry" });
	}

	static getListMappingFilter(modelCode, entityCode, filter) {
		return doRequest({ uri: "Data/GetListMappingFilter", params: { modelCode, entityCode, filter } });
	}

	static getHierarchyDataByColumnMapped(modelCode, entityCode, attributeCode, itemName, itemCode) {
		return doRequest({
			uri: "Data/GetHierarchyDataByColumnMapped",
			params: {
				modelCode,
				entityCode,
				attributeCode,
				itemName,
				itemCode
			}
		});
	}

	static GetBusinessUnits() {
		return doRequest({ uri: "Data/GetBusinessUnits" });
	}

	static GetAgencies() {
		return doRequest({ uri: "Data/GetAgencyDivisionsBusinessUnits" });
	}

	/** @deprecated */
	static formatHandsOnTableData(data, entityName, modelType) {
		const formattedData = {
			colHeaders: [],
			colMapping: [],
			data: [],
			count: 0,
			pageSize: 0,
			multiValueCols: []
		};

		data.colHeaders = data.colHeaders.filter((column) => {
			return column.name !== "IsArchived";
		});

		if (data.colHeaders.some((col) => col.sortOrderCustom !== 0)) {
			data.colHeaders.sort((a, b) => {
				return a.sortOrderCustom - b.sortOrderCustom;
			});
		}

		data.colHeaders.forEach((ch, index) => {
			let countUniq = ` (${ch.dataCount})`;

			if (ch.hierarchyId !== "00000000-0000-0000-0000-000000000000" && ch.editor === "false") countUniq = "";
			if (ch.type === "autocomplete" && ch.sourceList !== null) {
				ch.sourceList = Object.values(ch.sourceList);
				ch.sourceList = ch.sourceList.filter((x) => !x.includes("System.Collections.Generic.Dictionary"));
			}

			// override for https://techjira.publicis.com/browse/OSPMDM-3258
			if (ch.name === "Code") {
				if (modelType === "Global" || entityName.toLowerCase().startsWith("raw")) {
					ch.validator = null;
				} else {
					ch.validator = "null|^.{0,100}$";
				}
			}

			const CompleteName = ch.name.toLowerCase().trim() === "name" ? `${entityName} ` + ch.name : ch.name;
			formattedData.colHeaders.push(CompleteName + countUniq);

			const commonArgs = {
				code: ch.code,
				data: ch.name,
				name: ch.name,
				hierarchyId: ch.hierarchyId,
				fullName: CompleteName,
				isKey: ch.isKey,
				type: ch.type.toLowerCase()
			};

			switch (ch.type) {
				case "date":
					formattedData.colMapping.push({
						...commonArgs,
						editor: ch.editor,
						datePickerConfig: ch.datePickerConfig,
						defaultDate: ch.defaultDate,
						dateFormat: ch.format,
						correctFormat: true
					});
					break;
				case "autocomplete":
					formattedData.colMapping.push({
						...commonArgs,
						sourceId: ch.sourceId,
						sourceList: ch.sourceList.length > 0 ? ch.sourceList : [],
						strict: true,
						allowInvalid: true
					});
					break;
				case "multivalued":
					formattedData.colMapping.push({
						...commonArgs,
						type: "text",
						isMultiValued: true,
						source: ch.source,
						strict: false
					});
					formattedData.multiValueCols.push(index);
					break;
				case "numeric":
					formattedData.colMapping.push({
						...commonArgs,
						editor: ch.editor,
						validator: ch.validator !== null ? new RegExp(ch.validator) : null,
						format: ch.format,
						correctFormat: true
					});
					break;
				default:
					formattedData.colMapping.push({
						...commonArgs,
						validator: ch.validator !== null ? new RegExp(ch.validator) : null,
						editor: ch.editor === "false" ? false : ch.editor,
						format: ch.format
					});
					break;
			}
		});

		data.data.forEach((item) => {
			delete item.$type;
			data.colHeaders.forEach((colHeader) => {
				item[colHeader.name] ||= "";
			});
			formattedData.data.push(item);
		});

		formattedData.count = data.count;
		formattedData.pageSize = data.pageSize;
		return formattedData;
	}

	static SaveDataSet(modelCode, entityCode, rowList, regionID, countryID) {
		return doRequest({
			method: "POST",
			uri: "Data/SaveDataSet",
			params: {
				modelCode,
				entityCode,
				...(regionID && countryID ? { regionID, countryID } : {})
			},
			body: JSON.stringify(rowList)
		});
	}

	static UpdateDataSet(modelCode, entityCode, rowList, regionID, countryID) {
		return doRequest({
			method: "PUT",
			uri: "Data/UpdateDataSetForUI",
			params: {
				modelCode,
				entityCode,
				...(regionID && countryID ? { regionID, countryID } : {})
			},
			body: JSON.stringify(rowList)
		});
	}

	static DeleteDataSet(modelCode, entityCode, rowList, regionID, countryID) {
		return doRequest({
			method: "DELETE",
			uri: "Data/DeleteDataSet",
			params: {
				modelCode,
				entityCode,
				...(regionID && countryID ? { regionID, countryID } : {})
			},
			body: JSON.stringify(rowList)
		});
	}

	static CopyDataEntity({ modelCode, code }, targetModelId, targetEntityId) {
		return doRequest({
			method: "POST",
			uri: "Data/CopyDataEntity",
			params: {
				sourceModelId: modelCode,
				sourceEntityId: code,
				targetModelId,
				targetEntityId
			}
		});
	}

	static getEntityDataValuesByPage = async (entityCode, pageNumber = 0, searchFilter = "", pageSize = 2) => {
		const response = await doRequest({
			uri: "Data/GetDataValuesByEntityGUID",
			params: {
				entityCode,
				pageSize,
				searchFilter,
				pageNumber
			}
		});
		const { totalCount, values } = response || {};
		return {
			totalCount,
			values: values.map(({ entityDataValueName, entityDataValueCode }) => ({
				label: `${entityDataValueName}:${entityDataValueCode}`,
				value: entityDataValueCode
			}))
		};
	};
}
