import React, { useState, useCallback, useEffect, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import "./index.scss";
// Services
import EntitiesService from "../../../services/EntitiesService";
// Component Library
import Grid, { Row, Column } from "@growthos/ui-grid";
import Textbox from "@growthos/ui-textbox";
// Context
import { EntitiesContext } from "../../../common/EntitiesContext";
import { AppContext } from "../../../common/AppProvider";

const EntityList = () => {
	const params = useParams();
	const navigate = useNavigate();

	const { selectedModel, setIsLoading } = useContext(AppContext);
	const { setEntitiesList, entitiesList, selectedEntity, setSelectedEntity, hideEntityList } = useContext(EntitiesContext);

	const [searchTerm, setSearchTerm] = useState("");

	useEffect(() => {
		if (selectedModel) {
			setIsLoading(true);
			EntitiesService.getEntitiesData(selectedModel.code).then((response) => {
				setEntitiesList(response);
				setIsLoading(false);

				if (params?.entityCode) {
					const matchingEntityFromQuery = response.find((entity) => {
						return entity.code === params.entityCode;
					});
					if (matchingEntityFromQuery && matchingEntityFromQuery?.code !== selectedEntity?.code) {
						setSelectedEntity(matchingEntityFromQuery);
					} else if (!matchingEntityFromQuery) {
						navigate(`/model/${selectedModel.code}/entity`);
					}
				}
			});
		}
	}, [selectedModel]);

	const loadEntity = useCallback(
		(selection) => {
			setSelectedEntity(selection);
			navigate(`/model/${selectedModel.code}/entity/${selection.code}/values`);
		},
		[navigate, selectedModel?.code, setSelectedEntity]
	);

	const filterCallback = useCallback(
		({ dataKeyName, dataKeyCode }) => {
			const testValue = (v) => v?.toLowerCase?.()?.includes?.(searchTerm?.toLowerCase?.());
			return testValue(dataKeyName) || testValue(dataKeyCode);
		},
		[searchTerm]
	);

	return (
		<React.Fragment>
			<Column from={1} to={3} className={`entityList ${hideEntityList ? "hideEntityList" : ""}`}>
				<Row>
					<Textbox
						className="search-entities-textbox"
						placeholder="Search entities"
						debounceTimeout={50}
						children=""
						type="search"
						onChange={(evt, value) => {
							setSearchTerm(value);
						}}
					/>
				</Row>

				<Row className={"entityListTableHeader"} cols={12}>
					<Column from={1} to={10}>
						Name
					</Column>
					<Column from={11} to={12}>
						Code
					</Column>
				</Row>

				<Grid className={"entityListTable"}>
					<div style={{ marginRight: 4 }}>
						{entitiesList.filter(filterCallback).map((selection, i) => (
							<Row
								className={`entityListTableRow ${selectedEntity?.code === selection.code ? "entityListTableRowSelected" : ""}`}
								key={`${selection.dataKeyCode}-${i}`}
								cols={12}
								onClick={() => loadEntity(selection)}
							>
								<Column className={"entityListTableRowName"} from={1} to={10}>
									{selection.dataKeyName}
								</Column>
								<Column className={"entityListTableRowCode"} from={11} to={12}>
									{selection.dataKeyCode}
								</Column>
							</Row>
						))}
					</div>
				</Grid>
			</Column>
		</React.Fragment>
	);
};
export default EntityList;
