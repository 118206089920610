import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
	name: "user",
	initialState: {
		userData: null,
		userRoles: []
	},
	reducers: {
		setUserPermissions: (state, action) => {
			state.userData = action.payload.userData;
			state.userRoles = action.payload.userRoles;
		}
	}
});

// Action creators are generated for each case reducer function
export const { setUserPermissions } = userSlice.actions;

export default userSlice.reducer;
