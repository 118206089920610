import React, { useState, useEffect, useContext, useMemo } from "react";
import "./index.scss";
import AppSDK from "@growthos/ui-app-sdk";
import { BannerNotification, show } from "@growthos/ui-banner";
import Button, { BUTTON_DISPLAY, BUTTON_SIZE } from "@growthos/ui-button";
import Dropdown from "@growthos/ui-dropdown";
import Modal from "@growthos/ui-modal";
import Textbox from "@growthos/ui-textbox";
import { AppContext } from "../../common/AppProvider";
import DataService from "../../services/DataService";
import ModelsService from "../../services/ModelsService";
import { displayErrors } from "../../helpers/Errors";
import _ from "lodash";

export default function ModelsAdmin() {
	const { selectedModel, setSelectedModel, setModelsList, modelsList, setIsLoading } = useContext(AppContext);
	const [modelsData, setModelsData] = useState([]);
	const [originalModelsData, setOriginalModelsData] = useState([]);
	const [modelTypes, setModelTypes] = useState([]);
	const [agencyMapping, setAgencyMapping] = useState({});
	const [businessUnitMapping, setBusinessUnitMapping] = useState({});
	const [countriesList, setCountriesList] = useState([]);
	const [regionsList, setRegionsList] = useState([]);
	const [modalToDelete, setModalToDelete] = useState(null);
	const [deleteText, setDeleteText] = useState("");

	const DISABLED_ACTION_MODELS = ["OS Standard Model", "Global"];
	const ENABLE_AGENCY_CLIENT_MODEL_TYPES = ["Client", "Global Integration"];

	useEffect(() => {
		setIsLoading(true);
		ModelsService.getModelTypes().then((modelTypes) => {
			const newModelTypes = modelTypes.map((type) => {
				return { ...type, label: type.modelType, value: type.id };
			});
			setModelTypes(newModelTypes);
			DataService.getRegionCountryList().then((countryRegionData) => {
				const newCountriesList = countryRegionData["Master Country"]?.data
					.filter((country) => {
						return country.Code != "ZZZ";
					})
					.map((country) => {
						const parentRegion = countryRegionData["Master Region"]?.data?.find((region) => {
							return region.Name == country.Region.substring(0, country.Region.indexOf(":"));
						});

						return {
							label: country.Name,
							value: country.ID,
							regionName: parentRegion.Name,
							regionMUID: parentRegion.MUID
						};
					});
				const newRegionsList = countryRegionData["Master Region"]?.data
					.filter((region) => {
						return region.Code != "ZZZ";
					})
					.map((region) => {
						return {
							label: region.Name,
							value: region.ID,
							MUID: region.MUID
						};
					});
				setCountriesList(newCountriesList);
				setRegionsList(newRegionsList);
				DataService.GetAgencies().then((agencyBusinessUnitData) => {
					const newAgencyMapping = {};
					const newBusinessUnitMapping = {};
					agencyBusinessUnitData.forEach((abu) => {
						if (!newAgencyMapping[abu.masterAgencyDivisionID]) {
							newAgencyMapping[abu.masterAgencyDivisionID] = abu.masterAgencyDivisionName;
						}
						if (!newBusinessUnitMapping[abu.masterAgencyDivisionID]) {
							newBusinessUnitMapping[abu.masterAgencyDivisionID] = [{ label: abu.masterBusinessUnitName, value: abu.masterBusinessUnitID }];
						} else {
							newBusinessUnitMapping[abu.masterAgencyDivisionID].push({ label: abu.masterBusinessUnitName, value: abu.masterBusinessUnitID });
						}
					});
					setAgencyMapping(newAgencyMapping);
					setBusinessUnitMapping(newBusinessUnitMapping);
					setIsLoading(false);
				});
			});
		});
	}, []);

	useEffect(() => {
		if (modelsList.length) {
			const formattedModelData = [...modelsList];
			formattedModelData.forEach((model) => {
				model.isEditing = false;
			});
			setOriginalModelsData(_.cloneDeep(formattedModelData));
			setModelsData(formattedModelData);
		}
	}, [modelsList]);

	const isEditing = useMemo(() => {
		return modelsData?.some((model) => {
			return model.isEditing;
		});
	}, [modelsData]);

	function formatDate(dateString) {
		const date = new Date(dateString);
		const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
		return monthNames[date.getMonth()] + " " + date.getDate() + ", " + date.getFullYear();
	}

	function renderLogFile(logFile) {
		const creator = logFile[0];
		const updater = logFile[logArray.length - 1];
		const log =
			"Created by " +
			creator.userName +
			" on " +
			formatDate(creator.date).toString() +
			"\n" +
			"Last updated by " +
			updater.userName +
			" on " +
			formatDate(updater.date).toString();

		return log;
	}

	function renderAgencyDropdown(model) {
		if (ENABLE_AGENCY_CLIENT_MODEL_TYPES.includes(model.modelType)) {
			const agencyOptions = Object.keys(agencyMapping).map((agencyID) => {
				return { value: agencyID, label: agencyMapping[agencyID] };
			});
			return (
				<Dropdown
					isSearchable={true}
					value={
						model.masterAgencyDivisionID
							? agencyOptions.find((agency) => {
									return agency.value.toString() === model.masterAgencyDivisionID.toString();
								})
							: null
					}
					options={agencyOptions}
					placeholder={"Select Agency"}
					onChange={(selected) => {
						const newModelsData = [...modelsData];
						const currentModel = newModelsData.find((data) => {
							return data.code === model.code;
						});
						currentModel.masterAgencyDivisionID = selected.value;
						currentModel.masterBusinessUnitID = null;
						setModelsData(newModelsData);
					}}
				>
					Select Agency
				</Dropdown>
			);
		} else {
			return "";
		}
	}

	function renderClientDropdown(model) {
		if (ENABLE_AGENCY_CLIENT_MODEL_TYPES.includes(model.modelType)) {
			const clientOptions = model.masterAgencyDivisionID ? businessUnitMapping[model.masterAgencyDivisionID] : [];
			return (
				<Dropdown
					isSearchable={true}
					disabled={!model.masterAgencyDivisionID}
					options={clientOptions}
					value={
						model.masterAgencyDivisionID && model.masterBusinessUnitID
							? businessUnitMapping[model.masterAgencyDivisionID].find((businessUnit) => {
									return businessUnit.value.toString() === model.masterBusinessUnitID.toString();
								})
							: null
					}
					placeholder={"Select Client"}
					onChange={(selected) => {
						const newModelsData = [...modelsData];
						const currentModel = newModelsData.find((data) => {
							return data.code === model.code;
						});
						currentModel.masterBusinessUnitID = selected.value;
						setModelsData(newModelsData);
					}}
				>
					Select Client
				</Dropdown>
			);
		} else {
			return "";
		}
	}

	function renderModelData() {
		const renderedModelData = [];
		modelsData.forEach((model) => {
			const currentlyEditing = model.isEditing;
			renderedModelData.push(
				<div className={`model-row ${currentlyEditing ? "currentlyEditing" : ""}`} key={model.code}>
					<div className="dataField doubleWidth">
						{!currentlyEditing ? (
							model.name
						) : (
							<Textbox
								hideLabel={true}
								value={model.name}
								maxLength={50}
								onChange={(evt, value) => {
									const newModelsData = [...modelsData];
									const currentModel = newModelsData.find((data) => {
										return data.code === model.code;
									});
									currentModel.name = value;
									setModelsData(newModelsData);
								}}
							/>
						)}
					</div>
					<div className="dataField">
						{!currentlyEditing ? (
							model.modelType
						) : (
							<Dropdown
								isSearchable={true}
								placeholder={"Select model type"}
								options={modelTypes}
								value={model.modelType ? { value: model.modelTypeId, label: model.modelType } : null}
								onChange={(selected) => {
									const newModelsData = [...modelsData];
									const currentModel = newModelsData.find((data) => {
										return data.code === model.code;
									});
									currentModel.modelTypeId = selected.id;
									currentModel.modelType = selected.label;
									currentModel.masterAgencyDivisionID = null;
									currentModel.masterBusinessUnitID = null;
									setModelsData(newModelsData);
								}}
							>
								Model Type
							</Dropdown>
						)}
					</div>
					<div className="dataField">{!currentlyEditing ? agencyMapping[model.masterAgencyDivisionID] || "" : renderAgencyDropdown(model)}</div>
					<div className="dataField">
						{!currentlyEditing
							? businessUnitMapping[model.masterAgencyDivisionID]?.find((businessUnit) => {
									return businessUnit.value === model.masterBusinessUnitID;
								})?.label || ""
							: renderClientDropdown(model)}
					</div>
					<div className="dataField">
						{!currentlyEditing ? (
							regionsList.find((region) => {
								return region.value === model.masterRegionID;
							})?.label || ""
						) : (
							<Dropdown
								isSearchable={true}
								placeholder={"Select Region"}
								options={regionsList}
								value={
									model.masterRegionID
										? regionsList.find((region) => {
												return region.value.toString() === model.masterRegionID.toString();
											})
										: null
								}
								onChange={(selected) => {
									const newModelsData = [...modelsData];
									const currentModel = newModelsData.find((data) => {
										return data.code === model.code;
									});
									currentModel.masterRegionID = selected.value;
									currentModel.masterCountryID = null;
									setModelsData(newModelsData);
								}}
							>
								Select Region
							</Dropdown>
						)}
					</div>
					<div className="dataField">
						{!currentlyEditing ? (
							countriesList.find((country) => {
								return country.value === model.masterCountryID;
							})?.label || ""
						) : (
							<Dropdown
								isSearchable={true}
								placeholder={"Select Country"}
								disabled={!model.masterRegionID}
								options={
									model.masterRegionID
										? countriesList.filter((country) => {
												const regionMUID = regionsList.find((region) => {
													return region.value.toString() === model.masterRegionID.toString();
												})?.MUID;
												return country.regionMUID === regionMUID;
											})
										: []
								}
								value={
									model.masterCountryID
										? countriesList.find((country) => {
												return country.value.toString() === model.masterCountryID.toString();
											})
										: null
								}
								onChange={(selected) => {
									const newModelsData = [...modelsData];
									const currentModel = newModelsData.find((data) => {
										return data.code === model.code;
									});
									currentModel.masterCountryID = selected.value;
									setModelsData(newModelsData);
								}}
							>
								Select Country
							</Dropdown>
						)}
					</div>
					<div className="dataField doubleWidth">
						{!currentlyEditing ? (
							model.description
						) : (
							<Textbox
								hideLabel={true}
								value={model.description}
								maxLength={500}
								onChange={(evt, value) => {
									const newModelsData = [...modelsData];
									const currentModel = newModelsData.find((data) => {
										return data.code === model.code;
									});
									currentModel.description = value;
									setModelsData(newModelsData);
								}}
							>
								Description
							</Textbox>
						)}
					</div>
					<div className="dataField">{model.log?.length ? renderLogFile(model.log) : ""}</div>
					<div className="dataField modelActions">
						{!DISABLED_ACTION_MODELS.includes(model.name) ? (
							<>
								{!currentlyEditing ? (
									<>
										<Button
											size={BUTTON_SIZE.SMALL}
											display={BUTTON_DISPLAY.SECONDARY}
											icon="icon-pencil"
											disabled={isEditing}
											onClick={() => {
												const newModelsData = [...modelsData];
												newModelsData.find((data) => {
													return data.code === model.code;
												}).isEditing = true;
												setModelsData(newModelsData);
											}}
										>
											Edit
										</Button>

										<Button
											size={BUTTON_SIZE.SMALL}
											display={BUTTON_DISPLAY.SECONDARY}
											icon="icon-delete"
											disabled={isEditing}
											onClick={() => {
												Modal.show("delete-confirmation-modal");
												setModalToDelete(model);
											}}
										>
											Delete
										</Button>
									</>
								) : (
									<>
										<Button
											size={BUTTON_SIZE.SMALL}
											display={BUTTON_DISPLAY.SECONDARY}
											icon="icon-check-20"
											disabled={
												!model.name?.trim() ||
												!model.modelType ||
												!model.description?.trim() ||
												(ENABLE_AGENCY_CLIENT_MODEL_TYPES.includes(model.modelType) && (!model.masterAgencyDivisionID || !model.masterBusinessUnitID))
											}
											onClick={() => {
												setIsLoading(true);
												const cid = AppSDK.getContextId();
												const saveObject = _.cloneDeep(model);
												saveObject.contextID = cid;
												delete saveObject.isEditing;
												delete saveObject.label;
												delete saveObject.value;
												if (saveObject.masterRegionID && !saveObject.masterCountryID) {
													delete saveObject.masterRegionID;
													delete saveObject.masterCountryID;
												}
												if (!ENABLE_AGENCY_CLIENT_MODEL_TYPES.includes(model.modelType)) {
													delete saveObject.masterAgencyDivisionID;
													delete saveObject.masterBusinessUnitID;
												}
												if (saveObject.code === "newModel") {
													delete saveObject.code;
													ModelsService.createModel(saveObject)
														.then(() => {
															ModelsService.getModelsData(cid).then((models) => {
																setModelsList(models?.map((model) => ({ ...model, label: model.name, value: model.code })) || []);
																setIsLoading(false);
																show(
																	"mainBannerContainer",
																	<BannerNotification type="success" title="Success" message="Model was created." timed={5000} />
																);
															});
														})
														.catch(async (error) => {
															const errorObject = await error?.details?.json();
															if (errorObject?.errorMessage || errorObject?.message) {
																displayErrors("mainBannerContainer", errorObject.errorMessage || errorObject?.message, false);
															}
															setIsLoading(false);
														});
												} else {
													ModelsService.updateModel(saveObject)
														.then(() => {
															ModelsService.getModelsData(cid).then((models) => {
																const newModelsList = models?.map((model) => ({ ...model, label: model.name, value: model.code })) || [];
																setModelsList(newModelsList);
																if (saveObject.code === selectedModel?.code) {
																	setSelectedModel(
																		newModelsList.find((model) => {
																			return model.code === selectedModel?.code;
																		})
																	);
																}
																setIsLoading(false);
																show(
																	"mainBannerContainer",
																	<BannerNotification type="success" title="Success" message="Model was updated." timed={5000} />
																);
															});
														})
														.catch(async (error) => {
															const errorObject = await error?.details?.json();
															if (errorObject?.errorMessage || errorObject?.message) {
																displayErrors("mainBannerContainer", errorObject.errorMessage || errorObject?.message, false);
															}
															setIsLoading(false);
														});
												}
											}}
										>
											Save
										</Button>
										<Button
											size={BUTTON_SIZE.SMALL}
											display={BUTTON_DISPLAY.SECONDARY}
											icon="icon-close"
											onClick={() => {
												setModelsData(_.cloneDeep(originalModelsData));
											}}
										>
											Cancel
										</Button>
									</>
								)}
							</>
						) : (
							""
						)}
					</div>
				</div>
			);
		});
		return renderedModelData;
	}

	return (
		<div className="models-admin-container">
			<div className="models-list-actions">
				<Button
					disabled={isEditing}
					display={BUTTON_DISPLAY.PRIMARY}
					icon={"icon-add"}
					onClick={() => {
						const newModelsData = [...modelsData];
						newModelsData.unshift({ code: "newModel", isEditing: true });
						setModelsData(newModelsData);
					}}
				>
					Add New Model
				</Button>
			</div>
			<div className="models-list-container">
				<div className="models-list-header">
					<span className="doubleWidth">Name</span>
					<span>Type</span>
					<span>Agency</span>
					<span>Client</span>
					<span>Region</span>
					<span>Country</span>
					<span className="doubleWidth">Description</span>
					<span>Log File</span>
					<span>Actions</span>
				</div>
				<div className="models-list">{modelsData.length && Object.keys(businessUnitMapping).length && renderModelData()}</div>
			</div>

			<Modal
				id="delete-confirmation-modal"
				heading="Delete Model"
				description="Are you sure you want to delete the model? All of the entities associated with it will be deleted."
				userClosable={false}
				actionsRight={
					<React.Fragment>
						<Button
							display={BUTTON_DISPLAY.SECONDARY}
							onClick={() => {
								Modal.hide("delete-confirmation-modal");
								setModalToDelete(null);
								setDeleteText("");
							}}
						>
							Cancel
						</Button>
						<Button
							className="red-button"
							disabled={deleteText !== "CONFIRM"}
							onClick={() => {
								setIsLoading(true);
								Modal.hide("delete-confirmation-modal");
								setDeleteText("");
								ModelsService.deleteModel(modalToDelete.code).then(() => {
									const cid = AppSDK.getContextId();
									ModelsService.getModelsData(cid).then((models) => {
										const newModelsList = models?.map((model) => ({ ...model, label: model.name, value: model.code })) || [];
										setModelsList(newModelsList);
										if (modalToDelete.code === selectedModel?.code) {
											setSelectedModel(null);
										}
										setModalToDelete(null);
										setIsLoading(false);
										show("mainBannerContainer", <BannerNotification type="success" title="Success" message="Model was deleted." timed={5000} />);
									});
								});
							}}
						>
							Delete
						</Button>
					</React.Fragment>
				}
			>
				<Textbox
					value={deleteText}
					onChange={(evt, value) => {
						setDeleteText(value);
					}}
				>
					Type "CONFIRM" to enable Delete button
				</Textbox>
			</Modal>
		</div>
	);
}
