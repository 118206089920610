import React, { useEffect, useMemo, useContext } from "react";
import "./Mappings.scss";
// Contexts
import { AppContext } from "../../../../common/AppProvider";
import { AttributesContext } from "../../../../common/AttributesContext";
import { EntitiesContext } from "../../../../common/EntitiesContext";
// Component Libary
import Button, { BUTTON_DISPLAY, BUTTON_SIZE } from "@growthos/ui-button";
import DragDrop, { Droppable } from "@growthos/ui-drag-drop";
import TextBox from "@growthos/ui-textbox";
import Dropdown from "@growthos/ui-dropdown";

import PropTypes from "prop-types";
import nextId from "react-id-generator";
import { HIERARCHY_OPTION, ATTRIBUTE_OPTION, MAPPING_OPTION, MULTI_MAPPING_OPTION, TYPE_OPTIONS, DECIMAL_OPTIONS, CURRENCY_OPTIONS, SUPPLIER_CODE_NAME } from "../constants";

const propTypes = {
	entityMappings: PropTypes.array,
	setEntityMappings: PropTypes.func,
	isInNameSite: PropTypes.bool
};

export default function Mappings({ entityMappings, setEntityMappings, isInNameSite }) {
	const { selectedModel } = useContext(AppContext);
	const { dateTimeFormats } = useContext(AttributesContext);
	const { selectedEntity, entitiesList } = useContext(EntitiesContext);

	const valueFieldConstants = useMemo(() => {
		const mappingOptions = entitiesList
			?.filter(({ name, code }) => {
				return code !== selectedEntity?.code && (!isInNameSite || (isInNameSite && name !== SUPPLIER_CODE_NAME));
			})
			.map(({ name, code }) => ({
				label: name,
				value: code
			}));
		return {
			Datetime: {
				label: "Date Format",
				options: dateTimeFormats?.map((val) => ({
					label: val,
					value: val
				}))
			},
			Number: { label: "Decimals", options: DECIMAL_OPTIONS },
			Text: { label: "Characters" },
			Currency: { label: "Decimals", options: CURRENCY_OPTIONS },
			Mapping: { label: "Mapping", options: mappingOptions },
			"Multi Value Mapping": { label: "Mapping", options: mappingOptions }
		};
	}, [selectedEntity, entitiesList, dateTimeFormats, isInNameSite]);

	const mappings = useMemo(() => {
		const hasHierarchyMapping = entityMappings.find((mapping) => {
			return mapping.mappingRelationship?.label === "Hierarchy";
		});
		return entityMappings.map((mapping) => {
			const isNewMapping = mapping.id.startsWith("new-mapping-");
			return {
				id: mapping.id,
				title: (
					<React.Fragment>
						<Dropdown
							className="mapping-relationship-dropdown"
							placeholder="Select a mapping relationship"
							options={hasHierarchyMapping && mapping.mappingRelationship?.label !== "Hierarchy" ? [ATTRIBUTE_OPTION] : [HIERARCHY_OPTION, ATTRIBUTE_OPTION]}
							value={mapping.mappingRelationship}
							onChange={(selected) => changeValue("mappingRelationship", selected, mapping.id)}
							disabled={!isNewMapping}
							isSearchable={true}
						>
							Mapping relationship
							<abbr className="gos__required" title="required">
								*
							</abbr>
						</Dropdown>
						<TextBox
							className="mapping-name-textbox"
							placeholder="Enter a name"
							value={mapping.name}
							onChange={(evt) => changeValue("name", evt.target.value, mapping.id)}
							error="Name cannot be Raw Supplier Code"
							forceInvalid={isNewMapping && mapping.name?.trim()?.toLowerCase() === "raw supplier code"}
						>
							Name
							<abbr className="gos__required" title="required">
								*
							</abbr>
						</TextBox>
						<Dropdown
							className="mapping-type-dropdown"
							placeholder="Select a type"
							value={mapping.type}
							onChange={(selected) => changeValue("type", selected, mapping.id)}
							disabled={!isNewMapping || !mapping.mappingRelationship}
							options={
								mapping.mappingRelationship?.label === "Hierarchy"
									? [MAPPING_OPTION, ...(selectedModel?.isMultiLevelMapping === true ? [MULTI_MAPPING_OPTION] : [])]
									: [...TYPE_OPTIONS, ...(selectedModel?.isMultiLevelMapping === true ? [MULTI_MAPPING_OPTION] : [])]
							}
							isSearchable={true}
						>
							Type
							<abbr className="gos__required" title="required">
								*
							</abbr>
						</Dropdown>
						{!mapping.type || valueFieldConstants[mapping.type?.label]?.options ? (
							<Dropdown
								className="mapping-value-dropdown"
								placeholder="Select"
								options={mapping.type ? valueFieldConstants[mapping.type?.label]?.options : []}
								disabled={!mapping.type || (!isNewMapping && mapping?.type?.label === "Mapping")}
								value={mapping.value}
								onChange={(selected) => changeValue("value", selected, mapping.id)}
								isSearchable={true}
							>
								{mapping.type ? valueFieldConstants[mapping.type?.label]?.label : "Value column"}
								<abbr className="gos__required" title="required">
									*
								</abbr>
							</Dropdown>
						) : (
							<TextBox
								disabled={!mapping.type}
								className="mapping-value-textbox"
								value={mapping.value}
								onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
								onChange={(evt) => {
									try {
										const formattedValue = parseInt(evt.target.value.replace(/[^0-9]/gi, ""), 10);
										changeValue("value", formattedValue, mapping.id);
									} catch (e) {
										console.log(e);
									}
								}}
								type="number"
							>
								{valueFieldConstants[mapping.type?.label]?.label}
							</TextBox>
						)}
					</React.Fragment>
				),
				actions: (
					<Button display={BUTTON_DISPLAY.ICON} size={BUTTON_SIZE.SMALL} icon="icon-delete" className="delete-mapping-button" onClick={() => deleteMapping(mapping.id)}>
						Delete Mapping
					</Button>
				)
			};
		});
	}, [entityMappings, setEntityMappings, valueFieldConstants]);

	function handleOrderChange(newList) {
		const newEntityMappings = newList.map((id) => {
			return entityMappings.find((mapping) => {
				return mapping.id === id;
			});
		});
		setEntityMappings(newEntityMappings);
	}

	function addNewMapping() {
		const newEntityMappings = [...entityMappings];
		newEntityMappings.push({ id: nextId("new-mapping-"), mappingRelationship: null, name: "", type: null, value: null });
		setEntityMappings(newEntityMappings);
	}

	function deleteMapping(mappingId) {
		const newEntityMappings = [...entityMappings].filter((mapping) => {
			return mapping.id !== mappingId;
		});
		setEntityMappings(newEntityMappings);
	}

	function changeValue(field, value, id) {
		const newEntityMappings = [...entityMappings];
		const mappingToUpdate = newEntityMappings.find((mapping) => {
			return mapping.id === id;
		});
		mappingToUpdate[field] = value;
		if (field === "mappingRelationship") {
			mappingToUpdate.type = null;
			mappingToUpdate.value = null;
		}
		if (field === "type") {
			mappingToUpdate.value = null;
		}
		setEntityMappings(newEntityMappings);
	}

	useEffect(() => {
		// Apply styling whenever mappings updates so dropdowns don't get cut off by the mapping below it
		const draggableElements = document.querySelectorAll(".entity-mappings-container .gos-droppable .gos-draggable");
		Array.from(draggableElements).forEach((element, index) => {
			element.style.zIndex = 10 + Array.from(draggableElements).length - index;
		});
	}, [mappings]);

	return (
		<div className="entity-mappings-container">
			<h4>Mappings</h4>
			<p className="container-description">
				May contain hierarchies and/or attributes. Only one hierarchy can be used as a mapping relationship. The order of the mappings determines the order of the value
				table's columns.
			</p>
			<Button display="secondary" icon="icon-add" size="sm" id="add-new-mapping" onClick={addNewMapping}>
				New mapping
			</Button>
			<DragDrop>
				<Droppable list={mappings} onChange={handleOrderChange} />
			</DragDrop>
		</div>
	);
}

Mappings.propTypes = propTypes;
