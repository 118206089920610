import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Row } from "@growthos/ui-grid";
import { useLocation, useNavigate, Link } from "react-router-dom";
import Navigation, { NavLink } from "@growthos/ui-navigation";

const propTypes = {
	Actions: PropTypes.elementType,
	Body: PropTypes.elementType,
	selectedPage: PropTypes.number,
	setSelectedPage: PropTypes.func,
	selectedModel: PropTypes.object,
	userRole: PropTypes.object
};

export default function PageHeader({ Actions, Body, Heading, subnavLinks, hideNavLinks, selectedPage, setSelectedPage, selectedModel, userRole }) {
	const navigate = useNavigate();
	const location = useLocation();
	const [pages, setPages] = useState([]);

	useEffect(() => {
		const newPages = [
			{
				title: "Entities",
				to: `/model/${selectedModel?.code}/entity`,
				onClick: () => navigate(`/model/${selectedModel?.code}/entity`)
			}
		];
		if (selectedModel) {
			newPages.push(
				{
					title: "Hierarchies",
					to: `/model/${selectedModel?.code}/hierarchies`,
					onClick: () => navigate(`/model/${selectedModel?.code}/hierarchies`)
				},
				{
					title: "Activity Log",
					to: `/model/${selectedModel?.code}/activity-log`,
					onClick: () => navigate(`/model/${selectedModel?.code}/activity-log`)
				}
			);
		}
		if (userRole?.roleName === "User.GlobalAdmin") {
			newPages.push({
				title: "Models Admin",
				to: `/models-admin`,
				onClick: () => navigate(`/models-admin`)
			});
		}
		if ((userRole?.roleName === "User.GlobalAdmin" || userRole?.roleName === "User.ClientAdmin") && selectedModel) {
			newPages.push({
				title: "App Config",
				to: `/app-config/${selectedModel?.code}`,
				onClick: () => navigate(`/app-config/${selectedModel?.code}`)
			});
		}
		setPages(newPages);

		let currentPage = null;
		if (location.pathname.includes("/hierarchies")) {
			currentPage = newPages.find((page) => {
				return page.title === "Hierarchies";
			});
		} else if (location.pathname.includes("/activity-log")) {
			currentPage = newPages.find((page) => {
				return page.title === "Activity Log";
			});
		} else if (location.pathname.includes("/models-admin")) {
			currentPage = newPages.find((page) => {
				return page.title === "Models Admin";
			});
		} else if (location.pathname.includes("/app-config")) {
			currentPage = newPages.find((page) => {
				return page.title === "App Config";
			});
		} else {
			currentPage = newPages.find((page) => {
				return page.title === "Entities";
			});
		}
		setSelectedPage(newPages.indexOf(currentPage));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userRole, selectedModel]);

	return (
		<Row className="pageHeaderRow" from={1} to={12}>
			<Navigation
				heading={!Heading ? pages[selectedPage]?.title || "" : Heading || ""}
				{...(!hideNavLinks
					? {
							navLinks: pages.map(({ to, title, onClick }, i) => {
								return (
									<NavLink
										key={`page-header-navLink_${i}`}
										to={to}
										as={Link}
										selected={i === selectedPage}
										onClick={(e) => {
											setSelectedPage(i);
											onClick(e);
										}}
									>
										{title}
										{i === selectedPage && <i className="icon-checkmark" />}
									</NavLink>
								);
							})
						}
					: {})}
				actions={Actions && <Actions />}
				{...(subnavLinks ? { subnavLinks: subnavLinks } : {})}
			>
				{Body && <Body />}
			</Navigation>
		</Row>
	);
}

PageHeader.propTypes = propTypes;
