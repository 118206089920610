import React, { useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import HierarchyTree from "./HierarchyTree/HierarchyTree";
import "./HierarchySideSheet.scss";
// Context
import { AppContext } from "../../../../common/AppProvider";
import { EntitiesContext } from "../../../../common/EntitiesContext";
// Component Library
import Button, { BUTTON_SIZE, BUTTON_DISPLAY } from "@growthos/ui-button";
import SideSheet from "@growthos/ui-sidesheet";

const HierarchySideSheet = ({ isOpen, onClose }) => {
	const navigate = useNavigate();

	const { selectedModel } = useContext(AppContext);
	const { selectedEntity, hierarchiesList, entitiesList, setSelectedEntity } = useContext(EntitiesContext);

	function navigateToEntity(entityGUID) {
		const matchingEntity = entitiesList.find((entity) => {
			return entity.code === entityGUID;
		});
		if (matchingEntity) {
			setSelectedEntity(matchingEntity);
			navigate(`/model/${selectedModel?.code}/entity/${entityGUID}/values`);
		}
	}

	return (
		<SideSheet isOpen={isOpen} onClose={onClose} headline={selectedEntity?.name}>
			<HierarchyTree hierarchiesList={hierarchiesList} navigateToEntity={navigateToEntity} />
		</SideSheet>
	);
};
export default HierarchySideSheet;
