import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./index.scss";
// Context
import { EntitiesContext, EntitiesProvider } from "../../common/EntitiesContext";
// Components
import EntityList from "./EntityList";
import { AppContext } from "../../common/AppProvider";
import { AttributesProvider } from "../../common/AttributesContext";
import { Row, Column } from "@growthos/ui-grid";
import EntitiesService from "../../services/EntitiesService";

import Button, { BUTTON_DISPLAY, BUTTON_SIZE } from "@growthos/ui-button";
import MoreMenu from "@growthos/ui-more-menu";

import useBulkAddEntityModal from "./BulkAddEntityModal";
import useAddNewEntityModal from "./AddNewEntityModal";

import ModelsService from "../../services/ModelsService";

import PropTypes from "prop-types";

const propTypes = {
	children: PropTypes.node
};

// eslint-disable-next-line
const AttributesWrapper = ({ children }) => {
	const { selectedModel, setPageHeaderValues } = useContext(AppContext);
	const { selectedEntity, setPmEntitiesList, hideEntityList, selectedEntityPage } = useContext(EntitiesContext);

	const { Modal: BulkAddEntityModal, ...bulkAddEntityModalProps } = useBulkAddEntityModal();
	const { Modal: AddNewEntityModal, ...addNewEntityModalProps } = useAddNewEntityModal();

	useEffect(() => {
		if (selectedEntityPage === "default" && bulkAddEntityModalProps && addNewEntityModalProps && selectedModel) {
			const Actions = () => (
				<>
					<Button
						id="exportEntitiesButton"
						icon="icon-download"
						size={BUTTON_SIZE.MEDIUM}
						display={BUTTON_DISPLAY.SECONDARY}
						onClick={() => ModelsService.exportModel(selectedModel.code)}
					>
						Download model
					</Button>
					<Button id="bulkAddEntitiesButton" size={BUTTON_SIZE.MEDIUM} display={BUTTON_DISPLAY.SECONDARY} onClick={() => bulkAddEntityModalProps?.setOpen?.(true)}>
						Bulk add standard entities
					</Button>
					<Button id="addEntityButton" size={BUTTON_SIZE.MEDIUM} icon="icon-add" onClick={() => addNewEntityModalProps?.setOpen?.(true)}>
						New entity
					</Button>
				</>
			);
			setPageHeaderValues({ Actions });
		}
	}, [selectedEntityPage, selectedModel, bulkAddEntityModalProps, addNewEntityModalProps]);

	useEffect(() => {
		if (selectedModel) {
			EntitiesService.getPMEntityList(selectedModel.code)
				.then((response) => {
					setPmEntitiesList(response);
				})
				.catch((e) => {
					console.log(e);
				});
		}
	}, [selectedModel, setPmEntitiesList]);

	return (
		<AttributesProvider model={selectedModel} entity={selectedEntity}>
			<BulkAddEntityModal {...bulkAddEntityModalProps} />
			<AddNewEntityModal {...addNewEntityModalProps} />
			<Row className={"entityManagementGridRow"} cols={12}>
				<EntityList />
				<Column from={!hideEntityList ? 4 : 1} to={12}>
					<div className="editEntity">{children}</div>
				</Column>
			</Row>
		</AttributesProvider>
	);
};

const EntityManagement = ({ children }) => {
	return (
		<EntitiesProvider>
			<AttributesWrapper>{children}</AttributesWrapper>
		</EntitiesProvider>
	);
};

EntityManagement.propTypes = propTypes;

export default EntityManagement;
