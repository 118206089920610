import React, { useState, useEffect, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import "./AddNewEntityModal.scss";
// Services
// Component Library
import { BannerNotification, show } from "@growthos/ui-banner";
import Button, { BUTTON_DISPLAY } from "@growthos/ui-button";
import Modal from "@growthos/ui-modal";
import Textbox from "@growthos/ui-textbox";
// Context
import { displayErrors } from "../../../helpers/Errors";
import { EntitiesContext } from "../../../common/EntitiesContext";
import { AppContext } from "../../../common/AppProvider";
import EntitiesService from "../../../services/EntitiesService";

const defaultEntityFields = { name: "", code: "", description: "" };

export default ({ open, setOpen }) => {
	const navigate = useNavigate();
	const { entitiesList, pmEntitiesList, setSelectedEntity, setEntitiesList } = useContext(EntitiesContext);
	const { selectedModel, setIsLoading } = useContext(AppContext);
	const [isNewEntity, setIsNewEntity] = useState(false);
	const [selectedEntity, setSelectedPMEntity] = useState(null);
	const [searchedEntities, setSearchedEntities] = useState([]);
	const [displayEntitiesList, setDisplayEntitiesList] = useState(false);
	const [entityFields, setEntityFields] = useState({ ...defaultEntityFields });
	const [duplicateNameEntered, setDuplicateNameEntered] = useState(false);
	const [duplicateCodeEntered, setDuplicateCodeEntered] = useState(false);

	const availableEntities = useMemo(() => {
		if (pmEntitiesList?.length > 0 && entitiesList?.length > 0 && selectedModel != null) {
			// filter the template entities with the codes of the existing entities, restricted to this modelCode
			const existingCodes = entitiesList.filter((e) => e.modelCode === selectedModel.code).map(({ dataKeyCode }) => dataKeyCode);
			const condition = ({ dataKeyCode }) => dataKeyCode !== "SZ" && dataKeyCode !== "RT" && dataKeyCode !== "SCX" && existingCodes.indexOf(dataKeyCode) === -1;
			return pmEntitiesList.filter(condition);
		}
		return [];
	}, [pmEntitiesList, entitiesList, selectedModel]);

	useEffect(() => {
		const addNewEntityModal = document.querySelector("#addNewEntityModal");
		addNewEntityModal.addEventListener("click", (e) => {
			if (!e.target.classList.contains("selectEntityButton")) {
				setDisplayEntitiesList(false);
			}
		});
	}, []);

	function onNameChange(newName) {
		if (newName.trim()) {
			let newSearchedEntities = availableEntities.filter((pmEntity) => {
				return (
					pmEntity.dataKeyName?.toLowerCase().includes(newName.trim().toLowerCase()) &&
					!entitiesList?.find((existingEntity) => {
						return existingEntity.dataKeyName?.toLowerCase() == pmEntity.dataKeyName?.toLowerCase();
					})
				);
			});
			newSearchedEntities = newSearchedEntities.map((entity) => {
				return (
					<button
						className="selectEntityButton PMEntity"
						id={`select-entity-${entity.code}`}
						key={`select-entity-${entity.code}`}
						onClick={() => {
							setSelectedPMEntity(entity);
							setIsNewEntity(false);
							setDisplayEntitiesList(false);
							setDuplicateCodeEntered(false);
							setDuplicateNameEntered(false);
							const newEntityFields = { ...entityFields };
							newEntityFields.name = entity.dataKeyName;
							setEntityFields(newEntityFields);
						}}
					>
						{entity.dataKeyName} ({entity.dataKeyCode})
					</button>
				);
			});
			newSearchedEntities.push(
				<button
					className="selectEntityButton newEntity"
					key={`select-entity-new-entity`}
					onClick={() => {
						setSelectedPMEntity(null);
						setIsNewEntity(true);
						setDisplayEntitiesList(false);
					}}
				>
					Create new entity
				</button>
			);
			setDisplayEntitiesList(true);
			setSearchedEntities(newSearchedEntities);

			const newEntityFields = { ...entityFields };
			newEntityFields.name = newName;
			setEntityFields(newEntityFields);
			setSelectedPMEntity(null);
			if (
				entitiesList.find((entity) => {
					return entity.dataKeyName.toLowerCase() == newName.trim().toLowerCase() || entity.vanityName?.toLowerCase() == newName.trim().toLowerCase();
				})
			) {
				setDuplicateNameEntered(true);
			} else {
				setDuplicateNameEntered(false);
			}
		} else {
			resetModal();
		}
	}

	function handleEntityFieldUpdate(value, type) {
		const newEntityFields = { ...entityFields };
		let newValue = value;
		if (type === "code") {
			newValue = newValue.replace(/[^a-zA-Z0-9]+/g, "")?.toUpperCase();
			if (
				entitiesList.find((entity) => {
					return entity.dataKeyCode == newValue;
				})
			) {
				setDuplicateCodeEntered(true);
			} else {
				setDuplicateCodeEntered(false);
			}
		}
		newEntityFields[type] = newValue;
		setEntityFields(newEntityFields);
	}

	function resetModal() {
		setDisplayEntitiesList(false);
		setSelectedPMEntity(null);
		setDuplicateCodeEntered(false);
		setDuplicateNameEntered(false);
		setIsNewEntity(false);
		setEntityFields({ ...defaultEntityFields });
	}

	function closeModal() {
		resetModal();
		setOpen(false);
	}

	function saveEntity() {
		try {
			closeModal();
			setIsLoading(true);
			if (!selectedEntity) {
				const newEntity = {
					dataKeyCode: entityFields.code.trim(),
					dataKeyDescription: entityFields.description.trim(),
					name: entityFields.name.trim(),
					vanityName: null,
					code: null,
					isNew: true,
					modelCode: selectedModel?.code,
					log: []
				};
				EntitiesService.createEntity(selectedModel?.code, newEntity)
					.then((entityCode) => {
						postSaveCleanup(entityFields.name.trim());
					})
					.catch(async (error) => {
						const errorObject = await error?.details?.json();
						errorHandling(errorObject?.message);
					});
			} else {
				EntitiesService.saveStandardBulkEntities(selectedModel?.code, [selectedEntity.code])
					.then(() => {
						postSaveCleanup(selectedEntity.name.trim());
					})
					.catch(async (error) => {
						const errorObject = await error?.details?.json();
						errorHandling(errorObject?.message);
					});
			}
		} catch (e) {
			console.log(e);
			setIsLoading(false);
		}
	}

	function postSaveCleanup(savedEntityName) {
		EntitiesService.getEntitiesData(selectedModel?.code).then((response) => {
			setEntitiesList(response);
			const savedEntity = response.find((entity) => {
				return entity.name === savedEntityName;
			});
			setSelectedEntity(savedEntity);
			navigate(`/model/${selectedModel?.code}/entity/${savedEntity?.code}/values`);
			setIsLoading(false);
			show("mainBannerContainer", <BannerNotification type="success" title="Entity created" message="" timed={5000} />);
		});
	}

	function errorHandling(errorMessage) {
		setIsLoading(false);
		if (errorMessage) {
			displayErrors("mainBannerContainer", errorMessage);
		}
		closeModal();
	}

	return (
		<Modal
			id="addNewEntityModal"
			heading="Add new entity"
			description="Enter an entity name to search for a standard entity you can use. If the one you need isn't available, you'll be given the option to create a new entity."
			isOpen={open}
			onRequestClose={closeModal}
			actionsRight={
				<div style={{ display: "flex" }}>
					<Button id="closeButtonAddNewEntityModal" display={BUTTON_DISPLAY.SECONDARY} onClick={closeModal}>
						Cancel
					</Button>
					<Button
						id="saveButtonAddNewEntityModal"
						onClick={saveEntity}
						disabled={
							!(
								selectedEntity ||
								(isNewEntity &&
									entityFields.name.trim() &&
									entityFields.code.trim() &&
									entityFields.description.trim() &&
									!duplicateNameEntered &&
									!duplicateCodeEntered)
							)
						}
					>
						Save
					</Button>
				</div>
			}
		>
			<Textbox
				id="entityName"
				className="entityName"
				type="search"
				placeholder="Enter an entity name"
				value={entityFields.name}
				onChange={(evt) => onNameChange(evt.target.value)}
				maxLength={50}
				error="This entity is already in your client model. Please edit the existing entity."
				forceInvalid={duplicateNameEntered}
			>
				Name
				<abbr className="gos__required" title="required">
					*
				</abbr>
			</Textbox>
			{displayEntitiesList && <div className="searchedEntitiesContainer">{searchedEntities}</div>}
			{(isNewEntity || selectedEntity) && (
				<>
					<Textbox
						id="entityCode"
						className="entityCode"
						type="text"
						placeholder="Enter a code"
						value={!selectedEntity ? entityFields.code : selectedEntity.dataKeyCode}
						disabled={selectedEntity ? true : false}
						onChange={(evt) => handleEntityFieldUpdate(evt.target.value, "code")}
						maxLength={3}
						error="This code is unavailable. Please choose another."
						forceInvalid={duplicateCodeEntered}
					>
						Code
						<abbr className="gos__required" title="required">
							*
						</abbr>
					</Textbox>
					<Textbox
						id="entityDescription"
						className="entityDescription"
						as="textarea"
						placeholder="Enter a description"
						value={!selectedEntity ? entityFields.description : selectedEntity.dataKeyDescription}
						disabled={selectedEntity ? true : false}
						onChange={(evt) => handleEntityFieldUpdate(evt.target.value, "description")}
						maxLength={300}
						showMaxLengthIndicator={true}
					>
						Description
						<abbr className="gos__required" title="required">
							*
						</abbr>
					</Textbox>
				</>
			)}
		</Modal>
	);
};
