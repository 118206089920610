import React from "react";
import { BannerNotification, show } from "@growthos/ui-banner";

export const getErrorMessage = (errorMessage) => {
	// manually set in the front end
	if (typeof errorMessage === "string") {
		return errorMessage;
		// most errors
	}
	if (errorMessage?.responseText) {
		const error = JSON.parse(errorMessage.responseText);
		let response;

		// WORKAROUND: fixes cases where backend returns props with uppercase
		Object.keys(error).forEach((key) => {
			const k = key.toLowerCase();

			if (k !== key) {
				error[k] = error[key];
				delete error[key];
			}
		});

		if (error.message) {
			response = error.message.split("--");
		} else if (error.error_description) {
			response = error.error_description.split("--");
		} else {
			response = [];
		}

		if (!(response.length > 1)) {
			response = error.message ? error.message : error.error_description;
		}

		return response;
		// Connection errors, third party api errors, etc... Errors that usually haven't got a description or whose description is vague
	}
	return null;
};

export const displayErrors = (bannerContainer, errors, timed = true) => {
	let errorsArray = [];
	if (Array.isArray(errors)) {
		errors.forEach((e) => {
			const message = getErrorMessage(e);
			if (Array.isArray(message) && message.length > 0) {
				errorsArray = [...errorsArray, ...message];
			} else {
				errorsArray.push(message);
			}
		});
	} else {
		const message = getErrorMessage(errors);

		if (message?.length && message?.length > 0) {
			errorsArray = message;
		} else {
			errorsArray = [message];
		}
	}
	if (Array.isArray(errorsArray)) {
		errorsArray.forEach((errorMessage) => {
			show(bannerContainer, <BannerNotification type="error" title="Error" message={errorMessage} {...(timed ? { timed: 5000 } : {})} />);
		});
	} else {
		show(bannerContainer, <BannerNotification type="error" title="Error" message={errorsArray} {...(timed ? { timed: 5000 } : {})} />);
	}
};
