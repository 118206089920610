export const EXCLUDED_ATTRIBUTES = ["Code", "Name", "IsArchived"];

export const HIERARCHY_OPTION = {
	label: "Hierarchy",
	value: "mapping-relationship-hierarchy"
};
export const ATTRIBUTE_OPTION = {
	label: "Attribute",
	value: "mapping-relationship-attribute"
};

export const DATETIME_OPTION = { label: "Datetime", value: "type-datetime" };
export const NUMBER_OPTION = { label: "Number", value: "type-number" };
export const TEXT_OPTION = { label: "Text", value: "type-text" };
export const CURRENCY_OPTION = { label: "Currency", value: "type-currency" };
export const MAPPING_OPTION = { label: "Mapping", value: "type-mapping" };
export const MULTI_MAPPING_OPTION = {
	label: "Multi Value Mapping",
	value: "type-multi-mapping"
};

export const ENTITY_DETAILS_EDIT_DISABLED_KEYS = ["DMA", "MSA", "CY"];

export const TYPE_OPTIONS = [DATETIME_OPTION, NUMBER_OPTION, TEXT_OPTION, CURRENCY_OPTION, MAPPING_OPTION];

export const DECIMAL_OPTIONS = [
	{ label: "0", value: "0" },
	{ label: "1", value: "1" },
	{ label: "2", value: "2" },
	{ label: "3", value: "3" },
	{ label: "4", value: "4" }
];

export const CURRENCY_OPTIONS = [{ label: "2", value: "2" }];

export const ENTITY_DETAILS_KEY_MAP = {
	dataKeyCode: "code",
	dataKeyName: "entityName",
	entityDescription: "description",
	dataKeyEnableMail: "emailAlertsEnabled",
	vanityName: "vanityName",
	isFreeform: "isFreeform"
};

export const DEFAULT_PAGE_TITLE = "Entity Name";
export const MAPPINGS_TITLE = "Mappings";
export const MAPPINGS_SUBTITLE = "Mappings may contain hierarchies and/or attributes. Only one Hierarchy can be used as a mapping relationship.";
export const ADD_MAPPING_BUTTON_TEXT = "Add Mapping";
export const SAVE_CONFIRMATION_MODAL_TITLE = "Are you sure you want to save all changes?";
export const SAVE_CONFIRMATION_MODAL_SUBTITLE = "All additions, edits and deletions will be applied.";
export const CANCEL_CONFIRMATION_MODAL_TITLE = "Are you sure you want to discard all changes?";
export const CANCEL_CONFIRMATION_MODAL_SUBTITLE = "All additions, edits and deletions will be lost.";
export const CANCEL_CONFIRMATION_MODAL_SAVE_BUTTON_TEXT = "Save";
export const CANCEL_CONFIRMATION_MODAL_CANCEL_BUTTON_TEXT = "Discard";
export const SAVE_BUTTON_TEXT = "Save";
export const CANCEL_BUTTON_TEXT = "Cancel";
export const ENTITY_DETAILS_TITLE = "Entity Details";
export const ENTITY_DETAILS_NAME_LABEL = "Entity Name";
export const ENTITY_DETAILS_CODE_LABEL = "Code";
export const ENTITY_DETAILS_VANITY_NAME_LABEL = "Vanity Name";
export const ENTITY_DETAILS_DESCRIPTION_LABEL = "Description";
export const ENTITY_DETAILS_EMAIL_ALERTS_LABEL = "Enable this list for email alerts";
export const ENTITY_DETAILS_FREEFORM_LABEL = "Freeform";
export const MAPPING_ROW_MAPPING_RELATIONSHIP_LABEL = "Mapping Relationship";
export const MAPPING_ROW_NAME_LABEL = "Name";
export const MAPPING_ROW_TYPE_LABEL = "Type";
export const MAPPING_ROW_VALUE_DEFAULT_VALUE_LABEL = "Value Column";
export const MAPPING_ROW_ATTRIBUTE_NAME_CONFLICT_ERROR_MESSAGE = "Name must be unique";
export const MAPPING_ROW_SUPPLIER_CODE_NAME_CONFLICT_ERROR = "Name cannot be Raw Supplier Code";
export const SUPPLIER_CODE_NAME = "Raw Supplier Code";
