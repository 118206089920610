import RequestHelpers from "../helpers/RequestHelpers";
const { doRequest } = RequestHelpers;

export default class EntitiesService {
	static getEntitiesData(modelId) {
		return doRequest({
			uri: "Entity/GetList",
			params: { modelCode: modelId }
		});
	}

	static deleteEntity(entityId, modelId) {
		return doRequest({
			method: "DELETE",
			uri: "Entity/Delete",
			params: { entityId, modelId }
		});
	}

	static saveEntity(entity) {
		if (entity.code === null) {
			return EntitiesService.createEntity(entity);
		}
		return EntitiesService.updateEntity(entity);
	}

	static createEntity(modelCode, entity) {
		return doRequest({
			method: "POST",
			uri: "Entity/Create",
			params: { modelCode },
			body: JSON.stringify(entity),
			headers: { "Content-Type": "application/json" }
		});
	}

	static updateEntity(modelCode, entity) {
		return doRequest({
			method: "PUT",
			uri: "Entity/Update",
			params: { modelCode },
			body: JSON.stringify(entity)
		});
	}

	static getEntityHierarchies(modelCode, entityCode) {
		return doRequest({
			uri: "Hierarchy/GetHierarchyLevels",
			params: {
				...(entityCode ? { entityCode } : {}),
				...(modelCode ? { modelCode } : {})
			}
		});
	}

	static getPMEntityList(modelCode) {
		return doRequest({
			uri: "Entity/GetPMTemplateModelEntitiesList",
			params: { modelCode }
		});
	}

	static saveStandardBulkEntities(modelCode, entities) {
		return doRequest({
			method: "POST",
			uri: "Entity/CreateBulkEntities",
			params: { modelCode },
			body: JSON.stringify(entities),
			headers: { "Content-Type": "application/json" }
		});
	}
}
