/* eslint-disable */

import React, { useEffect, useState, useContext, useMemo } from "react";
import { Routes, Route, useNavigate, useParams } from "react-router-dom";
import UserService from "./services/UserService";

import AppSDK from "@growthos/ui-app-sdk";
import { AnalyticsProvider } from "@growthos/ui-analytics";
import { BannerContainer } from "@growthos/ui-banner";

import routes from "./router/routes";

import { AppContextProvider, AppContext } from "./common/AppProvider";

import "./App.scss";

const MATOMO_URL = process.env.REACT_APP_MATOMO_URL;

const EnhancedRoute = ({ children }) => {
	const navigate = useNavigate();
	const { modelCode } = useParams();

	const { selectedModel, modelsList, setSelectedModel } = useContext(AppContext);

	useEffect(() => {
		if (!selectedModel && modelsList.length > 0 && modelCode) {
			const inferredModel = modelsList.find(({ code }) => code === modelCode);
			if (inferredModel) {
				setSelectedModel(inferredModel);
			} else {
				navigate(`/`);
			}
		}
	}, [modelsList, selectedModel, setSelectedModel, navigate, modelCode]);

	return <>{children}</>;
};

const App = () => {
	const [isReady, setIsReady] = useState(AppSDK.getIsReady());
	const [userData, setUserData] = useState({});

	useEffect(() => {
		AppSDK.onReady(({ contextId, context, briefId, taskId, currentApp }) => {
			UserService.getUserData(contextId).then((user) => {
				setUserData(user);
			});
			setIsReady(true);
		});
	}, []);

	const { user, contextId, context, briefId, currentApp } = useMemo(() => {
		return {
			user: AppSDK.ApiUser.getUser(),
			contextId: AppSDK.getContextId(),
			context: AppSDK.getContext(),
			briefId: AppSDK.getBriefId(),
			taskId: AppSDK.getTaskId(),
			currentApp: AppSDK.getCurrentApp()
		};
	}, [isReady]);

	return (
		<>
			{isReady && (
				<AnalyticsProvider
					matomoUrl={MATOMO_URL}
					matomoSiteId={16}
					matomoOptions={{ userId: user.email }}
					pendoApiKey={"93b796da-0cfd-482e-4a5a-20511c9ec7a4"}
					contextId={contextId}
					context={context}
					briefId={briefId}
					currentApp={currentApp}
					user={user}
				>
					<>
						<BannerContainer id="mainBannerContainer" />
						<AppContextProvider userData={userData}>
							<Routes>
								{routes.map(({ element, ...rest }, routeIndex) => (
									<Route {...rest} key={`route-${routeIndex}`} element={<EnhancedRoute>{element}</EnhancedRoute>} />
								))}
							</Routes>
						</AppContextProvider>
					</>
				</AnalyticsProvider>
			)}
		</>
	);
};
export default App;
