import React, { useMemo } from "react";
import "./HierarchyTree.scss";
// Component Library
import Button, { BUTTON_DISPLAY } from "@growthos/ui-button";

const HierarchyTree = ({ showHierachyName = true, hierarchiesList, navigateToEntity }) => {
	const renderedHierarchies = useMemo(() => {
		const fullList = [];
		if (hierarchiesList) {
			Object.values(hierarchiesList).forEach((hierarchy, hierarchyIndex) => {
				const entitiesList = [];
				Object.values(hierarchy.entities).forEach((entity, index) => {
					entitiesList.push(
						<div className="entity-row" key={`${entity.entityName}_${index}`}>
							{index !== 0 && (
								<React.Fragment>
									<div
										className="verticalLine"
										{...(index === Object.values(hierarchy.entities).length - 1 && {
											style: { height: "50%", bottom: "50%" }
										})}
									/>
									<div className="horizontalLine" style={{ width: `${index * 12}px`, minWidth: `${index * 12}px` }} />
								</React.Fragment>
							)}
							<span className="entity-name">{entity?.entityName}</span>
							{navigateToEntity && (
								<Button display={BUTTON_DISPLAY.TEXT} className="view-entity-button" onClick={() => navigateToEntity(entity?.entityGUID)}>
									View
								</Button>
							)}
						</div>
					);
				});
				fullList.push(
					<div className="hierarchy-tree" key={`hierarchy_key_${hierarchyIndex}`}>
						{showHierachyName && <div className="hierarchy-name">{hierarchy?.hierarchyName}</div>}
						{entitiesList}
					</div>
				);
			});
		}
		return fullList;
	}, [hierarchiesList]);

	return renderedHierarchies;
};
export default HierarchyTree;
