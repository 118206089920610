import React from "react";
import "./NoEntitySelected.scss";

const NoEntitySelected = () => {
	return (
		<div className="noEntitySelectedContainer">
			<h3 className="noEntitySelectedHeading">No Entity Selected</h3>
			<p>
				Select an existing entity to view/modify from the entities list
				<br />
				on the left.
			</p>
		</div>
	);
};
export default NoEntitySelected;
