import React, { useState } from "react";
import AttributesService from "../services/AttributesService";
import useAsyncEffect from "./useAsyncEffect";
import PropTypes from "prop-types";

export const AttributesContext = React.createContext();

const formatAttributes = (rawAttributes) => {
	return rawAttributes;
};

const propTypes = {
	children: PropTypes.node.isRequired,
	model: PropTypes.object,
	entity: PropTypes.object
};

export const AttributesProvider = ({ children, model, entity }) => {
	const [entityAttributes, setEntityAttributes] = useState([]);
	const [dateTimeFormats, setDateTimeFormats] = useState();
	const [numberFormats, setNumberFormats] = useState();

	useAsyncEffect(async () => {
		if (model && entity) {
			setEntityAttributes(formatAttributes(await AttributesService.getAttributesData(model.code, entity.code)));
			const { dateTimeFormats, numberFormats } = await AttributesService.getFieldFormats();
			setDateTimeFormats(dateTimeFormats);
			setNumberFormats(numberFormats);
		}
	}, [model, entity]);

	const contextValue = { entityAttributes, dateTimeFormats, numberFormats };

	return <AttributesContext.Provider value={contextValue}>{children}</AttributesContext.Provider>;
};

AttributesProvider.propTypes = propTypes;
