import React, { useState, useEffect, useContext, useMemo, useCallback } from "react";
import { BannerNotification, show } from "@growthos/ui-banner";
import Button, { BUTTON_DISPLAY, BUTTON_SIZE } from "@growthos/ui-button";
import Textbox from "@growthos/ui-textbox";
import Modal, { MODAL_TYPE, MODAL_SIZE } from "@growthos/ui-modal";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import AppAssignmentService from "../../../services/AppAssignmentService";
import { AppContext } from "../../../common/AppProvider";

export default function Configuration({
	entitiesList,
	selectedApplicationCode,
	modelCode,
	mandatoryEntityCodes,
	applicationEntityCodes,
	setSelectedEntities,
	selectedEntities,
	setApplicationEntityCodes
}) {
	const { setIsLoading } = useContext(AppContext);

	const [nonSelectedEntities, setNonSelectedEntities] = useState([]);
	const [searchTerm, setSearchTerm] = useState("");

	useEffect(() => {
		const nonSelectedEntities = entitiesList.filter((entity) => {
			return !applicationEntityCodes.includes(entity.code) && !mandatoryEntityCodes.includes(entity.code);
		});
		const selectedAndMandatoryEntities = [
			...entitiesList.filter((entity) => {
				return mandatoryEntityCodes.includes(entity.code);
			}),
			...entitiesList.filter((entity) => {
				return applicationEntityCodes.includes(entity.code) && !mandatoryEntityCodes.includes(entity.code);
			})
		];
		setSelectedEntities(selectedAndMandatoryEntities);
		setNonSelectedEntities(nonSelectedEntities);
	}, [applicationEntityCodes, mandatoryEntityCodes]);

	function reorderDragDrop(list, startIndex, endIndex) {
		const result = Array.from(list);
		const [removed] = result.splice(startIndex, 1);
		result.splice(endIndex, 0, removed);
		return result;
	}

	function moveItem(source, destination, droppableSource, droppableDestination) {
		const sourceClone = Array.from(source);
		const destClone = Array.from(destination);
		const [removed] = sourceClone.splice(droppableSource.index, 1);

		destClone.splice(droppableDestination.index, 0, removed);

		const result = {};
		result[droppableSource.droppableId] = sourceClone;
		result[droppableDestination.droppableId] = destClone;

		return result;
	}

	function getList(id) {
		if (id === "droppable") {
			return nonSelectedEntities;
		} else if (id === "droppable2") {
			return selectedEntities;
		}
	}

	function onDragEnd(result) {
		const { source, destination } = result;

		// dropped outside the list
		if (!destination) {
			return;
		}

		if (source.droppableId === destination.droppableId) {
			const [removed] = Array.from(getList(source.droppableId)).splice(source.index, 1);
			if (source.droppableId === "droppable2" && mandatoryEntityCodes.includes(removed.code)) {
				return;
			}
			const items = reorderDragDrop(getList(source.droppableId), source.index, destination.index);
			if (source.droppableId === "droppable") {
				setNonSelectedEntities(items);
			} else if (source.droppableId === "droppable2") {
				setSelectedEntities(items);
			}
		} else {
			const [removed] = Array.from(getList(source.droppableId)).splice(source.index, 1);
			if (mandatoryEntityCodes.includes(removed.code)) {
				Modal.show("mandatory-key-modal");
				return;
			}
			const result = moveItem(getList(source.droppableId), getList(destination.droppableId), source, destination);
			setNonSelectedEntities(result.droppable);
			setSelectedEntities(result.droppable2);
		}
	}

	function handleSearchChange(e) {
		const { value } = e.target;
		setSearchTerm(value);
		const selectedEntityCodes = selectedEntities.map((entity) => {
			return entity.code;
		});
		let matchingEntities = [];
		if (value !== "") {
			matchingEntities = entitiesList.filter((entity) => {
				return !selectedEntityCodes.includes(entity.code) && entity.name.toLowerCase().includes(value.toLowerCase());
			});
		} else {
			matchingEntities = entitiesList.filter((entity) => {
				return !selectedEntityCodes.includes(entity.code);
			});
		}
		setNonSelectedEntities(matchingEntities);
	}

	function handleSave() {
		const selectedEntityCodes = selectedEntities.map((entity) => {
			return entity.code;
		});
		setIsLoading(true);
		AppAssignmentService.saveEntities(selectedApplicationCode, modelCode, selectedEntityCodes).then(() => {
			setApplicationEntityCodes(selectedEntityCodes);
			show("mainBannerContainer", <BannerNotification type="success" title="Success" message="Successfully Saved" timed={5000} />);
			setIsLoading(false);
		});
	}

	return (
		<div className="configuration-container">
			<div className="configuration-actions">
				<Textbox className="searchEntity" id="searchConfig" placeholder="Search" hideLabel={true} value={searchTerm} onChange={handleSearchChange}>
					Search
				</Textbox>
				<Button onClick={handleSave}>Save</Button>
			</div>
			<div className="config-drag-drop-container">
				<DragDropContext onDragEnd={onDragEnd}>
					<Droppable droppableId="droppable">
						{(providedDroppable, snapshot) => (
							<div ref={providedDroppable.innerRef} className="allEntitiesContainer">
								{nonSelectedEntities.map((item, index) => (
									<Draggable key={item.code} draggableId={item.code} index={index}>
										{(provided, snapshot) => (
											<div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} className="draggableElement">
												<span className="entity-name">{item.name}</span>
												<span className="entity-code">{item.dataKeyCode}</span>
											</div>
										)}
									</Draggable>
								))}
								{providedDroppable.placeholder}
							</div>
						)}
					</Droppable>
					<Droppable droppableId="droppable2">
						{(providedDroppable, snapshot) => (
							<div ref={providedDroppable.innerRef} className="selectedEntitiesContainer">
								{selectedEntities.map((item, index) => (
									<Draggable key={item.code} draggableId={item.code} index={index}>
										{(provided, snapshot) => (
											<div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} className="draggableElement">
												<span className="entity-name">{item.name}</span>
												<span className="entity-code">{item.dataKeyCode}</span>
											</div>
										)}
									</Draggable>
								))}
								{providedDroppable.placeholder}
							</div>
						)}
					</Droppable>
				</DragDropContext>
			</div>
			<Modal id="mandatory-key-modal" heading="Warning" description="This is a mandatory entity and cannot be removed." type={MODAL_TYPE.WARNING}>
				<p>Modal Children</p>
			</Modal>
		</div>
	);
}
