import React, { useState, useEffect, useContext, useMemo, useCallback } from "react";
import "./index.scss";
import Button, { BUTTON_DISPLAY, BUTTON_SIZE } from "@growthos/ui-button";
import Checkbox, { DISPLAY_TYPE } from "@growthos/ui-checkbox";
import DataTable, { ColumnTemplate, TD_TYPE, TD_ALIGN } from "@growthos/ui-datatable";
import Dropdown from "@growthos/ui-dropdown";
import Grid, { Row, Column } from "@growthos/ui-grid";
import Heading, { HEADING_LEVEL, HEADING_SIZE } from "@growthos/ui-heading";
import Status from "@growthos/ui-status";
import { DataProvider } from "@growthos/ui-theme";
import HierarchyTree from "../Entities/EntityValues/HierarchySideSheet/HierarchyTree/HierarchyTree";
import EntitiesService from "../../services/EntitiesService";
import HierarchyService from "../../services/HierarchyService";
import { AppContext } from "../../common/AppProvider";
import RequestHelpers from "../../helpers/RequestHelpers";

export default function Hierarchies() {
	const { selectedModel, setIsLoading } = useContext(AppContext);
	const [hierarchiesList, setHierarchiesList] = useState([]);
	const [selectedHierarchy, setSelectedHierarchy] = useState(null);
	const [dataProvider, setDataProvider] = useState(null);
	const [pageSize, setPageSize] = useState(15);
	const [reverseData, setReverseData] = useState(false);
	const [columns, setColumns] = useState([]);
	const [paginationProps, setPaginationProps] = useState({});

	useEffect(() => {
		if (selectedModel) {
			setIsLoading(true);
			EntitiesService.getEntityHierarchies(selectedModel.code).then((response) => {
				let newHierarchiesList = response
					.map((hierarchy) => {
						return {
							...hierarchy,
							label: hierarchy.hierarchyName,
							value: hierarchy.hierarchyName
						};
					})
					.sort((a, b) => {
						if (a.hierarchyName?.toLowerCase() < b.hierarchyName?.toLowerCase()) {
							return -1;
						}
						if (a.hierarchyName?.toLowerCase() > b.hierarchyName?.toLowerCase()) {
							return 1;
						}
						return 0;
					});
				newHierarchiesList = newHierarchiesList.filter((row, index) => {
					return (
						newHierarchiesList.indexOf(
							newHierarchiesList.find((test) => {
								return test.label === row.label;
							})
						) === index
					);
				});
				setHierarchiesList(newHierarchiesList);
				setIsLoading(false);
			});
		}
	}, [selectedModel]);

	useEffect(() => {
		getHierarchyData();
	}, [selectedHierarchy]);

	function getHierarchyData(page = 1) {
		if (selectedHierarchy) {
			setIsLoading(true);
			HierarchyService.getAllDataHierarchy(selectedModel.code, selectedHierarchy.hierarchyName, page, pageSize).then((hierarchyData) => {
				setDataProvider(new DataProvider({ data: hierarchyData.data, currentData: hierarchyData.data, rowsPerPage: pageSize }));
				setColumns(hierarchyData.headers);
				setPaginationProps({
					pageSize,
					totalSize: hierarchyData.count,
					dropUp: true,
					value: { label: page, value: page },
					rowOptions: [{ label: "15", value: 15 }],
					onChange: (value) => {
						getHierarchyData(value.value);
					}
				});
				setIsLoading(false);
				// HierarchyService.getAllLevelsByHierarchy(selectedHierarchy.modelGUID, selectedHierarchy.hierarchyName).then((levels) => {
				// 	console.log(levels);
				// 	setIsLoading(false);
				// });
			});
		}
	}

	const handleExportData = useCallback(() => {
		setIsLoading(true);
		RequestHelpers.doRequest({
			uri: `HierarchyExportView/GetHierarchyData`,
			params: { hierarchyName: selectedHierarchy?.hierarchyName, modelCode: selectedModel.code }
		}).then((response) => {
			RequestHelpers.downloadFile(response).then(() => {
				setIsLoading(false);
			});
		});
	}, [selectedModel, selectedHierarchy]);

	const renderedColumns = useMemo(() => {
		let renderedColumns = reverseData ? columns : [...columns].reverse();
		renderedColumns = renderedColumns.map((columnName, index) => {
			return <ColumnTemplate bind={`childNameLevel${reverseData ? index + 1 : renderedColumns.length - index}`}>{columnName}</ColumnTemplate>;
		});
		return renderedColumns;
	}, [reverseData, columns]);

	return (
		<Row className="hierarchy-container" cols={12}>
			<Column from={1} to={3} className="hierarchy-list">
				<h4 className="list-title">Choose a hierarchy</h4>
				<span className="list-description">Select an existing hierarchy from the dropdown below.</span>
				<Dropdown
					className="select-hierarchy-dropdown"
					children="Hierarchy"
					options={hierarchiesList}
					isSearchable={true}
					onChange={(selected) => {
						setSelectedHierarchy(selected);
					}}
				/>
				{selectedHierarchy && <HierarchyTree hierarchiesList={[selectedHierarchy]} showHierachyName={false} />}
			</Column>
			<Column from={4} to={12} className="hierarchy-grid">
				{selectedHierarchy ? (
					<React.Fragment>
						<Status type="no-status" title="Hierarchy" isBadge={true} />
						<div className="hierarchy-table-header">
							<h4 className="hierarchy-string">{selectedHierarchy.hierarchyName}</h4>
							<div className="header-actions">
								<Checkbox id="show-reverse-toggle" displayType={DISPLAY_TYPE.TOGGLE} checked={reverseData} onChange={(e, checked) => setReverseData(checked)}>
									Show reverse data
								</Checkbox>
								<div className="actions-divider" />
								<Button id="download-hierarchy-button" display={BUTTON_DISPLAY.SECONDARY} size={BUTTON_SIZE.SMALL} onClick={handleExportData}>
									Download .CSV
								</Button>
							</div>
						</div>
						{dataProvider && (
							<DataTable
								className="hierarchy-data-table"
								actionBarProps={{ visible: false }}
								data={dataProvider}
								paginationProps={paginationProps}
								emptyState={
									<Heading size={HEADING_SIZE.MEDIUM} description="This hierarchy has no values.">
										No data
									</Heading>
								}
							>
								{renderedColumns}
							</DataTable>
						)}
					</React.Fragment>
				) : (
					<div className="no-hierarchy-selected">
						<Heading className="no-hierarchy-heading" description="Select an existing hierarchy to view/modify from the menu on the left">
							No hierarchy selected
						</Heading>
					</div>
				)}
			</Column>
		</Row>
	);
}
