import React, { useState } from "react";
import PropTypes from "prop-types";

export const EntitiesContext = React.createContext();

const propTypes = {
	children: PropTypes.node.isRequired
};

export const EntitiesProvider = ({ children }) => {
	const [entitiesList, setEntitiesList] = useState([]);
	const [selectedEntity, setSelectedEntity] = useState(null);
	const [entityData, setEntityData] = useState(null);
	const [pmEntitiesList, setPmEntitiesList] = useState(null);
	const [hideEntityList, setHideEntityList] = useState(false);
	const [selectedEntityPage, setSelectedEntityPage] = useState("default");
	const [hierarchiesList, setHierarchiesList] = useState([]);

	return (
		<EntitiesContext.Provider
			value={{
				entitiesList,
				setEntitiesList,
				selectedEntity,
				setSelectedEntity,
				entityData,
				setEntityData,
				pmEntitiesList,
				setPmEntitiesList,
				hideEntityList,
				setHideEntityList,
				selectedEntityPage,
				setSelectedEntityPage,
				hierarchiesList,
				setHierarchiesList
			}}
		>
			{children}
		</EntitiesContext.Provider>
	);
};

EntitiesProvider.propTypes = propTypes;
