import RequestHelpers from "../helpers/RequestHelpers";
const { doRequest } = RequestHelpers;

export default class DataBatchService {
	static editData(modelCode, entityCode, data) {
		const dataBatch = new FormData();
		dataBatch.append("dataBatchFile", data);

		return doRequest({
			method: "POST",
			uri: "DataProcess/EditData",
			params: { modelCode, entityCode },
			body: dataBatch,
			headers: {}
		});
	}

	static downloadData(modelCode, entityCode) {
		return doRequest({
			method: "GET",
			uri: "ExportView/GetEntityData",
			params: { modelCode, entityCode }
		});
	}

	static deleteData(modelCode, entityCode, data) {
		const dataBatch = new FormData();
		dataBatch.append("dataBatchFile", data);

		return doRequest({
			method: "POST",
			uri: "DataProcess/DeleteData",
			params: { modelCode, entityCode },
			body: dataBatch
		});
	}
}
