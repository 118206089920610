import React from "react";
import Button, { BUTTON_DISPLAY, BUTTON_SIZE } from "@growthos/ui-button";
import Textbox from "@growthos/ui-textbox";
import MoreMenu from "@growthos/ui-more-menu";
import FilterChip, { FILTER_TYPE } from "@growthos/ui-filter-chip";
import Radio from "@growthos/ui-radio";
import Fieldset from "@growthos/ui-fieldset";

import ModelsService from "../../../../services/ModelsService";

export default ({ searchTerm, setSearchTerm, listOfApps, appToFilter, setAppToFilter, showCurrentEntities, setShowCurrentEntities, isExporting, setIsExporting, modelCode }) => {
	return (
		<div className="bulkAddActions">
			<Textbox value={searchTerm} placeholder="Search entities" type="search" onChange={(evt) => setSearchTerm(evt.target.value)} className="searchEntities" children="" />
			<div className="bulkAddDivider" />
			<MoreMenu
				trigger={
					<Button display={BUTTON_DISPLAY.ICON_BORDERED} icon="icon-more" size={BUTTON_SIZE.MEDIUM}>
						More options
					</Button>
				}
				className="bulkAddMoreMenu"
			>
				<button
					id="downloadAllEntitiesButton"
					onClick={async () => {
						if (!isExporting) {
							try {
								setIsExporting(true);
								await ModelsService.exportPredefinedEntities(modelCode?.code);
							} catch (e) {
								console.log(e);
							} finally {
								setIsExporting(false);
							}
						}
					}}
				>
					<i className="icon-download" />
					Export All Entities
				</button>
			</MoreMenu>
			<FilterChip
				type={FILTER_TYPE.LIST}
				id="filterAppsDropdownButton"
				listItems={[
					<Fieldset key="action-field-set">
						{listOfApps.map((app) => {
							return (
								<Radio key={app} value={app} onChange={(evt) => setAppToFilter(evt.target.value)} checked={appToFilter === app} invert={true}>
									{app}
								</Radio>
							);
						})}
					</Fieldset>
				]}
			>
				{appToFilter}
			</FilterChip>
			<FilterChip type="checkbox" id="showCurrentEntitiesButton" checked={showCurrentEntities} onChange={(e, val) => setShowCurrentEntities(val)}>
				Show Current Entities
			</FilterChip>
		</div>
	);
};
