/* eslint-disable */

import EntitiesService from "../../../services/EntitiesService";
import AttributesService from "../../../services/AttributesService";
import HierarchyService from "../../../services/HierarchyService";

import {
	EXCLUDED_ATTRIBUTES,
	HIERARCHY_OPTION,
	ATTRIBUTE_OPTION,
	DATETIME_OPTION,
	NUMBER_OPTION,
	TEXT_OPTION,
	CURRENCY_OPTION,
	MAPPING_OPTION,
	ENTITY_DETAILS_KEY_MAP
} from "./constants";

export default class StateHelper {
	static #hasEntityDetailDifferences = (o1, o2) => {
		for (const [k1, k2] of Object.entries(ENTITY_DETAILS_KEY_MAP)) {
			if (o1?.[k1] != o2?.[k2]) {
				return true;
			}
		}
		return false;
	};

	static #mappingHasObjectDifferences = (existingAttr, newAttr) => {
		if (existingAttr?.name != newAttr?.name) {
			return true;
		}

		if (existingAttr?.configuration?.attributeDataType != newAttr?.type?.label) {
			if (existingAttr?.configuration?.attributeDataType == "Mapping Column") {
				return newAttr?.type?.label != "Mapping";
			}
			return true;
		}
		switch (existingAttr?.configuration?.attributeDataType) {
			case "Mapping Column":
				return existingAttr?.configuration?.entityCode != newAttr?.value?.value;
			case TEXT_OPTION.label:
				return existingAttr?.configuration?.length != newAttr?.value;
			case DATETIME_OPTION.label:
				return existingAttr?.configuration?.format != newAttr?.value?.value;
			case NUMBER_OPTION.label:
			case CURRENCY_OPTION.label:
				return existingAttr?.configuration?.decimals != newAttr?.value?.value;
		}

		return false;
	};

	static #getLabeledValues = (attr) => {
		switch (attr.type.value) {
			case MAPPING_OPTION.value:
				return { entityCode: attr.value.value };
			case TEXT_OPTION.value:
				return { length: attr.value };
			case DATETIME_OPTION.value:
				return { format: attr.value.value };
			case NUMBER_OPTION.value:
				return { decimals: attr.value.value, format: "-####" };
			case CURRENCY_OPTION.value:
				// return {decimals: attr.value.value};
				// I'm just copying this from the old code, not sure if these constants are really required or not.
				return {
					decimals: attr.value.value,
					format: "-####",
					display: 0,
					length: ""
				};
		}
	};

	static getCreatePayload = (newAttr, selectedEntity, provisoryCode, sortOrder = 100) => ({
		code: null,
		hierarchyCode: null,
		provisoryCode,
		modelCode: selectedEntity?.modelCode,
		entityCode: selectedEntity?.code,
		name: newAttr.name,
		displayName: "",
		description: "",
		$type: "MDM.Services.ViewModels.FrontEnd.Attribute, MDM.Services",
		type: newAttr?.type?.value == MAPPING_OPTION?.value ? "DomainBased" : "FreeForm",
		configuration: {
			...(newAttr?.type?.value == MAPPING_OPTION?.value ? {} : { attributeDataType: newAttr?.type?.label }),
			$type:
				newAttr?.type?.value == MAPPING_OPTION?.value
					? "MDM.Services.ViewModels.FrontEnd.DomainBasedAttributeConfig, MDM.Services"
					: "MDM.Services.ViewModels.FrontEnd.FreeformAttributeConfig, MDM.Services",
			...StateHelper.#getLabeledValues(newAttr)
		},
		sortOrder,
		sortOrderCustom: sortOrder,
		isMandatory: false,
		isUnique: false,
		log: [],
		isMultiValue: false
	});

	static getDeletePayload = (selectedEntity, attr) => ({
		code: attr?.id,
		modelCode: selectedEntity?.modelCode,
		entityCode: selectedEntity?.code
	});

	static getUpdatePayload = (existingAttr, newAttr, selectedEntity, sortOrder = 0) => {
		const config = {
			...(newAttr?.type?.value == MAPPING_OPTION?.value ? { attributeDataType: "Mapping Column" } : { attributeDataType: newAttr?.type?.label }),
			$type:
				newAttr?.type?.value == MAPPING_OPTION?.value
					? "MDM.Services.ViewModels.FrontEnd.DomainBasedAttributeConfig, MDM.Services"
					: "MDM.Services.ViewModels.FrontEnd.FreeformAttributeConfig, MDM.Services",
			// add default values, getLabeledValues will override defined values appropriately
			isAutomatic: false,
			startsWith: null,
			format: null,
			decimals: null,
			length: null,
			entityCode: null,
			...StateHelper.#getLabeledValues(newAttr)
		};
		return {
			code: existingAttr?.code,
			// displayName: existingAttr?.name,
			displayName: newAttr?.name,
			description: "",
			hierarchyCode: existingAttr?.hierarchyCode,
			log: [],
			modelCode: selectedEntity?.modelCode,
			entityCode: selectedEntity?.code,
			sortOrder,
			sortOrderCustom: sortOrder,
			$type: "MDM.Services.ViewModels.FrontEnd.Attribute, MDM.Services",
			isMandatory: false,
			isUnique: false,
			isMultiValue: false,
			isKey: false,
			isName: false,
			name: newAttr?.name,
			type: newAttr?.type?.value == MAPPING_OPTION?.value ? "DomainBased" : "FreeForm",
			configuration: config,
			[`Config_IsAutomatic`]: config?.isAutomatic,
			[`Config_StartsWith`]: config?.startsWith,
			[`Config_AttributeDataType`]: config?.attributeDataType,
			[`Config_Format`]: config?.format,
			[`Config_Decimals`]: config?.decimals,
			[`Config_Length`]: config?.length,
			[`Config_EntityCode`]: config?.entityCode
		};
	};

	static updateHierarchy = async (mapping, selectedEntity, hierarchy) => {
		const response = await HierarchyService.editHierarchyName(hierarchy?.id, selectedEntity?.modelCode, mapping?.name);
		console.log(response);
	};

	static createHierarchy = async (mapping, selectedEntity, attributeId) => {
		const hierarchyId = await HierarchyService.Save({
			id: "00000000-0000-0000-0000-000000000000",
			name: mapping?.name,
			modelCode: selectedEntity?.modelCode,
			modelId: selectedEntity?.modelCode
		});

		const payload = [];

		payload.push({
			Id: "00000000-0000-0000-0000-000000000000",
			HierarchyId: hierarchyId,
			AttributeGUID: attributeId,
			modelId: selectedEntity?.modelCode,
			DisplayName: selectedEntity?.name,
			EntityId: selectedEntity?.code,
			ForeignEntityId: "00000000-0000-0000-0000-000000000000",
			ForeignId: selectedEntity?.code,
			ForeignType: 0,
			IsVisible: true,
			LevelNumber: 1
		});

		payload.push({
			Id: "00000000-0000-0000-0000-000000000000",
			HierarchyId: hierarchyId,
			modelId: selectedEntity?.modelCode,
			DisplayName: mapping?.value?.label,
			EntityId: mapping?.value?.value,
			ForeignEntityId: "00000000-0000-0000-0000-000000000000",
			ForeignId: mapping?.value?.value,
			ForeignType: 1,
			IsVisible: true,
			LevelNumber: 2
		});

		await HierarchyService.updateAllLevelsByHierarchy(payload);
	};

	static #checkForChanges = (selectedEntity, entityDetails, existingAttributes, newMappings) => {
		const hasEntityDifferences = StateHelper.#hasEntityDetailDifferences(selectedEntity, entityDetails);
		let hasNewMappings = false;
		let hasUpdatedMappings = false;
		let hasDeletedMappings = false;

		// performance optimization, early return
		if (newMappings?.length != existingAttributes.filter(({ name }) => !EXCLUDED_ATTRIBUTES.includes(name))?.length) {
			hasDeletedMappings = true;
			return true;
		}

		newMappings.forEach((mapping) => {
			const index = existingAttributes.findIndex((attr) => attr.code == mapping.id);
			if (index == -1) {
				hasNewMappings = true;
			} else if (StateHelper.#mappingHasObjectDifferences(existingAttributes[index], mapping)) {
				hasUpdatedMappings = true;
			}
		});

		existingAttributes?.forEach?.((attr) => {
			const index = newMappings?.findIndex((mapping) => mapping?.id == attr?.code);
			if (index == -1 && !EXCLUDED_ATTRIBUTES?.includes?.(attr.name)) {
				hasDeletedMappings = true;
			}
		});
		return hasEntityDifferences || hasNewMappings || hasUpdatedMappings || hasDeletedMappings;
	};

	static #checkForOrderChanges = (existingAttributes, newMappings) => {
		let result = false;
		const sortedAttributes = existingAttributes.filter(({ name }) => !EXCLUDED_ATTRIBUTES.includes(name)).sort((a, b) => (a.sortOrder > b.sortOrder ? 1 : -1));

		if (sortedAttributes?.length == newMappings.length) {
			const sattrs = sortedAttributes?.entries?.() || [];
			for (const [i, v] of sattrs) {
				if (v.code !== newMappings[i].id) {
					result = true;
				}
			}
		} else {
			result = true;
		}
		return result;
	};

	static checkForDuplicateAttributeName = (id, name, attributes) => {
		return (
			name != "" &&
			attributes
				?.filter((a) => a?.id?.toLowerCase?.() != id?.toLowerCase?.())
				?.map((a) => a?.name?.toLowerCase?.())
				?.includes?.(name?.toLowerCase?.())
		);
	};

	static #checkForValidity = (entityDetails, newMappings, attributes, hierarchies, modelCode, selectedEntity) => {
		const chkStrLenth = (s) => s != null && typeof s === "string" && s.length > 0;
		const chkNotUndefined = (u) => u !== undefined;
		const chkType = (test, options) => test != null && options.some((opt) => opt.value == test.value);

		const { entityName, code, description, emailAlertsEnabled, isFreeform } = entityDetails;

		const detailsAreValid = [entityName, code, description].every(chkStrLenth) && [emailAlertsEnabled, isFreeform].every(chkNotUndefined);

		const mappingsAreValid = newMappings.every(
			({ id, mappingRelationship, name, type, value }) =>
				value != null &&
				chkType(mappingRelationship, [HIERARCHY_OPTION, ATTRIBUTE_OPTION]) &&
				chkType(type, [DATETIME_OPTION, NUMBER_OPTION, TEXT_OPTION, CURRENCY_OPTION, MAPPING_OPTION]) &&
				chkStrLenth(name) &&
				!StateHelper.checkForDuplicateAttributeName(id, name, attributes)
		);

		return detailsAreValid && mappingsAreValid;
	};

	static findChanges = (entityDetails, newMappings, selectedEntity, existingAttributes, hierarchies, modelCode, attributes) =>
		(StateHelper.#checkForChanges(selectedEntity, entityDetails, existingAttributes, newMappings) || StateHelper.#checkForOrderChanges(existingAttributes, newMappings)) &&
		StateHelper.#checkForValidity(entityDetails, newMappings, attributes, hierarchies, modelCode, selectedEntity);

	static getHierarchy = (hierarchies, targetEntityValue, targetAttributeValue) => {
		if (!targetEntityValue || !targetAttributeValue) {
			return null;
		}
		return (
			hierarchies?.find?.(
				({ attributeGUID, parentEntityGUID }) =>
					attributeGUID?.toLowerCase?.() == targetAttributeValue?.toLowerCase?.() && parentEntityGUID?.toLowerCase?.() == targetEntityValue?.toLowerCase?.()
			) || null
		);
	};

	static getType = ({ config_AttributeDataType }) => {
		switch (config_AttributeDataType) {
			case "Text":
				return TEXT_OPTION;
			case "Number":
				return NUMBER_OPTION;
			case "Datetime":
				return DATETIME_OPTION;
			case "Currency":
				return CURRENCY_OPTION;
			case "Mapping Column":
				return MAPPING_OPTION;
		}
	};

	static getValue = ({ config_AttributeDataType, config_EntityCode, config_Length, config_Format, config_Decimals }, rawColumns) => {
		switch (config_AttributeDataType) {
			case "Mapping Column":
				const { name, code } = rawColumns?.find(({ code }) => code == config_EntityCode) || {};
				return { label: name, value: code };
			case "Text":
				return config_Length?.toString();
			case "Datetime":
				return { label: config_Format || "", value: config_Format || "" };
			case "Number":
			case "Currency":
				return { label: config_Decimals, value: config_Decimals };
		}
	};

	static saveChanges = async (selectedEntity, existingAttributes, newMappings, hierarchies, entityDetails) => {
		const apiActions = [];

		// entity details
		if (StateHelper.#hasEntityDetailDifferences(selectedEntity, entityDetails)) {
			const { entityName, code, vanityName, description, emailAlertsEnabled, isFreeform } = entityDetails;
			apiActions.push(async () => {
				await EntitiesService.updateEntity({
					...selectedEntity,
					isNew: false,
					dataKeyCode: code,
					dataKeyName: entityName,
					name: entityName,
					dataKeyDescription: description,
					entityDescription: description,
					dataKeyEnableMail: emailAlertsEnabled,
					vanityName,
					isFreeform
				});
			});
		}

		// attributes & hierarchy
		let provisoryCode = 0;

		const { createAttribute, deleteAttribute, updateAttribute } = AttributesService;
		const sortHasChanged = StateHelper.#checkForOrderChanges(existingAttributes, newMappings);

		newMappings.forEach((mapping, i) => {
			const index = existingAttributes.findIndex((attr) => attr.code == mapping.id);
			const sortOrder = i + 1; // one based
			// new attributes
			if (index == -1) {
				apiActions.push(async () => {
					provisoryCode++;
					const createAttributeResponse = await createAttribute(StateHelper.getCreatePayload(mapping, selectedEntity, provisoryCode, sortOrder));
					const attributeId = await JSON.parse(createAttributeResponse);

					// next, create a hierarchy if required.
					if (mapping?.mappingRelationship?.value == HIERARCHY_OPTION.value && !StateHelper.getHierarchy(hierarchies, mapping?.value?.value, mapping?.id)) {
						await StateHelper.createHierarchy(mapping, selectedEntity, attributeId);
					}
				});
				// existing attributes
			} else if (StateHelper.#mappingHasObjectDifferences(existingAttributes[index], mapping)) {
				apiActions.push(async () => {
					await updateAttribute(StateHelper.getUpdatePayload(existingAttributes[index], mapping, selectedEntity, sortOrder));

					// check if hierarchy needs to be updated
					const hierarchy = StateHelper.getHierarchy(hierarchies, mapping?.value?.value, mapping?.id);
					if (mapping?.mappingRelationship?.value == HIERARCHY_OPTION.value && hierarchy) {
						StateHelper.updateHierarchy(mapping, selectedEntity, hierarchy);
					}
				});
			} else if (sortHasChanged) {
				// if the sort order has changed for any existing or new attribute/mapping,
				// we must call update for all attributes that otherwise would not have been changed
				apiActions.push(async () => {
					await updateAttribute(StateHelper.getUpdatePayload(existingAttributes[index], mapping, selectedEntity, sortOrder));
				});
			}
		});

		// if existingAttributes contains an element that is not present in newMappings,
		// then it has been deleted by the user and we need to inform the API of this.
		// excludes values in EXCLUDED_ATTRIBUTES (code, Name, IsArchived)
		existingAttributes.forEach((attr) => {
			const index = newMappings.findIndex((mapping) => mapping.id == attr.code);
			if (index == -1 && !EXCLUDED_ATTRIBUTES.includes(attr.name)) {
				apiActions.push(async () => {
					await deleteAttribute(StateHelper.getDeletePayload(selectedEntity, attr));
					const hierarchy = StateHelper.getHierarchy(hierarchies, attr?.mappingColumn, attr?.code);
					if (hierarchy) {
						await HierarchyService.Delete(hierarchy?.id, hierarchy?.modelId);
					}
				});
			}
		});

		// execute the api actions
		const promises = apiActions.map((action) => action());
		return await Promise.all(promises);
	};
}
