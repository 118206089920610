import React, { useContext, useMemo, useState } from "react";
import "./NewValuesModal.scss";
// Services
import AppAssignmentService from "../../../../services/AppAssignmentService";
import DataService from "../../../../services/DataService";
// Context
import { AppContext } from "../../../../common/AppProvider";
import { EntitiesContext } from "../../../../common/EntitiesContext";
// Component Library
import { BannerNotification, show } from "@growthos/ui-banner";
import Button, { BUTTON_SIZE, BUTTON_DISPLAY } from "@growthos/ui-button";
import Modal from "@growthos/ui-modal";
import Textbox from "@growthos/ui-textbox";

const NewValuesModal = ({ isOpen, closeModal }) => {
	const { selectedModel, setIsLoading } = useContext(AppContext);
	const { selectedEntity, setEntityData } = useContext(EntitiesContext);

	const [values, setValues] = useState([{ code: "", name: "" }]);

	function renderValueInputs() {
		return values.map((row, index) => {
			return (
				<div className="new-value-row" key={`new-value-${index}`}>
					<Textbox
						maxLength="100"
						error="Code is required"
						placeholder="Enter a code"
						value={row.code}
						onChange={(evt) => {
							const newValues = [...values];
							newValues[index].code = evt.target.value;
							setValues(newValues);
						}}
					>
						Code
						<abbr className="gos__required" title="required">
							*
						</abbr>
					</Textbox>
					<Textbox
						maxLength="250"
						error="Name is required"
						placeholder="Enter a name"
						value={row.name}
						onChange={(evt) => {
							const newValues = [...values];
							newValues[index].name = evt.target.value;
							setValues(newValues);
						}}
					>
						Name
						<abbr className="gos__required" title="required">
							*
						</abbr>
					</Textbox>
					<Button
						display={BUTTON_DISPLAY.ICON_BORDERED}
						icon="icon-delete"
						className="delete-value-button"
						disabled={values.length === 1}
						onClick={() => {
							const newValues = [...values];
							newValues.splice(index, 1);
							setValues(newValues);
						}}
					>
						Delete Value
					</Button>
					<Button
						display={BUTTON_DISPLAY.ICON_BORDERED}
						icon="icon-add"
						className="add-value-button"
						onClick={() => {
							const newValues = [...values];
							newValues.push({ code: "", name: "" });
							setValues(newValues);
						}}
					>
						Add Value
					</Button>
				</div>
			);
		});
	}

	function modalCleanup() {
		setValues([{ code: "", name: "" }]);
		closeModal();
	}

	function addNewEntities() {
		const saveObject = [];
		values.forEach((row) => {
			if (row.code.trim() && row.name.trim()) {
				saveObject.push({ Code: row.code.trim(), Name: row.name.trim() });
			}
		});
		setIsLoading(true);
		modalCleanup();
		DataService.UpdateDataSet(selectedModel.code, selectedEntity.code, saveObject)
			.then((response) => {
				AppAssignmentService.getAllDataValues(selectedModel.code, selectedEntity.code).then((fetchedEntityData) => {
					setEntityData(fetchedEntityData);
					show("mainBannerContainer", <BannerNotification type="success" title="Entity updated" message="" timed={5000} />);
					setIsLoading(false);
				});
			})
			.catch(async (error) => {
				const errorObject = await error?.details?.json();
				if (errorObject?.message) {
					const errorMessages = errorObject?.message.split("--");
					show("mainBannerContainer", <BannerNotification type="error" title="No records were added" message="" additionalDetails={errorMessages} />);
				}
				setIsLoading(false);
			});
	}

	const isDisabled = useMemo(() => {
		return values.some((row) => {
			return !row.name.trim() || !row.code.trim();
		});
	}, [values]);

	return (
		<Modal
			id="new-values-modal"
			heading="Add new values"
			description=""
			isOpen={isOpen}
			onRequestClose={modalCleanup}
			actionsRight={
				<div style={{ display: "flex" }}>
					<Button id="closeButtonAddNewEntityModal" display={BUTTON_DISPLAY.SECONDARY} onClick={modalCleanup}>
						Cancel
					</Button>
					<Button id="saveButtonAddNewEntityModal" onClick={addNewEntities} disabled={isDisabled}>
						Save
					</Button>
				</div>
			}
		>
			{renderValueInputs()}
		</Modal>
	);
};
export default NewValuesModal;
