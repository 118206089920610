import React, { useState, useEffect, useContext, useMemo, useCallback } from "react";
import "./index.scss";
import Button, { BUTTON_DISPLAY, BUTTON_SIZE } from "@growthos/ui-button";
import Dropdown from "@growthos/ui-dropdown";
import Tabs, { TabPanel } from "@growthos/ui-tabs";
import AppAssignmentService from "../../services/AppAssignmentService";
import EntitiesService from "../../services/EntitiesService";
import { AppContext } from "../../common/AppProvider";
import Configuration from "./Configuration/Configuration";
import ValueFiltering from "./ValueFiltering/ValueFiltering";

export default function AppConfig() {
	const { selectedModel, setIsLoading } = useContext(AppContext);

	const [applications, setApplications] = useState([]);
	const [selectedApp, setSelectedApp] = useState(null);
	const [tabValue, setTabValue] = useState(0);
	const [configSaveWarning, setConfigSaveWarning] = useState(false);
	const [filterSaveWarning, setFilterSaveWarning] = useState(false);
	const [appChangeModalOpen, setAppChangeModalOpen] = useState(false);
	const [mandatoryEntityCodes, setMandatoryEntityCodes] = useState([]);
	const [applicationEntityCodes, setApplicationEntityCodes] = useState([]);
	const [selectedEntities, setSelectedEntities] = useState([]);
	const [entitiesList, setEntitiesList] = useState([]);

	useEffect(() => {
		setIsLoading(true);
		EntitiesService.getEntitiesData(selectedModel?.code).then((entities) => {
			setEntitiesList(entities);
			AppAssignmentService.getApplicationsList(selectedModel?.code).then((applicationsList) => {
				setApplications(applicationsList);
				setIsLoading(false);
			});
		});
	}, [selectedModel]);

	function getEntities(newSelectedApp) {
		if (newSelectedApp) {
			setIsLoading(true);
			AppAssignmentService.getMandatoryEntities(newSelectedApp.value, selectedModel?.code).then((mandatoryEntities) => {
				AppAssignmentService.getListOfEntities(newSelectedApp.value, selectedModel?.code).then((entitiesList) => {
					setMandatoryEntityCodes(
						mandatoryEntities.map((entity) => {
							return entity.entityCode;
						})
					);
					setApplicationEntityCodes(
						entitiesList.map((entity) => {
							return entity.entityCode;
						})
					);
					setTabValue(0);
					setSelectedApp(newSelectedApp);
					setIsLoading(false);
				});
			});
		} else {
			setSelectedApp(newSelectedApp);
		}
	}

	return (
		<div className="app-config-container">
			<Dropdown
				className="select-application-dropdown"
				value={selectedApp}
				id="select-application-dropdown"
				options={applications.map((app) => {
					return { ...app, label: app.applicationName, value: app.applicationCode };
				})}
				onChange={(selected) => getEntities(selected)}
				isClearable={true}
				isSearchable={true}
				placeholder="Select application..."
			>
				Application
			</Dropdown>
			{selectedApp && (
				<Tabs
					selectedTab={tabValue}
					id="tabs-bar"
					tabs={[
						{
							label: "Application Configuration",
							disabled: false,
							panelId: "panel-1"
						},
						{
							label: "Application Value Filtering",
							disabled: selectedEntities.length === 0,
							panelId: "panel-2"
						}
					]}
					onSelect={setTabValue}
				>
					<TabPanel id="panel-1">
						<Configuration
							selectedApplicationCode={selectedApp?.value}
							modelCode={selectedModel.code}
							mandatoryEntityCodes={mandatoryEntityCodes}
							applicationEntityCodes={applicationEntityCodes}
							entitiesList={entitiesList}
							setSelectedEntities={setSelectedEntities}
							selectedEntities={selectedEntities}
							setApplicationEntityCodes={setApplicationEntityCodes}
						/>
					</TabPanel>
					<TabPanel id="panel-2">
						<ValueFiltering
							entitiesList={entitiesList}
							selectedApplication={selectedApp}
							modelCode={selectedModel.code}
							applicationEntityCodes={applicationEntityCodes}
						/>
					</TabPanel>
				</Tabs>
			)}
		</div>
	);
}
