import React from "react";
import Checkbox from "@growthos/ui-checkbox";
import EntityDetailsTooltip from "./EntityDetailsTooltip";

export default ({ entityId, name, code, tooltip, selected, disabled, onChange, closeEntityTooltips }) => {
	return (
		<div className="bulkAddEntityAvailableRow">
			<div style={{ display: "flex", alignItems: "center" }}>
				<Checkbox checked={selected} disabled={disabled} id={entityId} onChange={(e, checked) => onChange(entityId, checked)}>
					{name} ({code})
				</Checkbox>
			</div>
			<EntityDetailsTooltip name={name} code={code} tooltip={tooltip} closeEntityTooltips={closeEntityTooltips} />
		</div>
	);
};
