import RequestHelpers from "../helpers/RequestHelpers";

const { doRequest, downloadFile } = RequestHelpers;

export default class ModelsService {
	static getModelsData(cid) {
		return doRequest({
			uri: "Model/GetList",
			params: { contextId: cid }
		});
	}

	static deleteModel(modelId) {
		return doRequest({
			method: "DELETE",
			uri: "Model/Delete",
			params: { modelId, modelCode: modelId }
		});
	}

	static saveModel(model) {
		return ModelsService[model.code === null ? "createModel" : "updateModel"](model);
	}

	static createModel(model) {
		return doRequest({
			uri: "Model/Create",
			method: "POST",
			body: JSON.stringify(model)
		});
	}

	static updateModel(model) {
		return doRequest({
			uri: "Model/Update",
			method: "PUT",
			body: JSON.stringify(model),
			params: { modelCode: model.code }
		});
	}

	static getModelTypes() {
		return doRequest({ uri: "Model/GetModelTypes" });
	}

	static async exportModel(modelCode) {
		try {
			const { name, data } = await doRequest({
				uri: `ExportView/ExportModel`,
				params: { modelCode }
			});
			await downloadFile({ name: name || "Model_Export.xlsx", data });
		} catch (e) {
			console.log(e);
		}
	}

	static async exportPredefinedEntities(modelCode) {
		try {
			const { name, data } = await doRequest({
				uri: `ExportView/ExportPredefinedEntityList`,
				params: { modelCode }
			});
			await downloadFile({ name: name || "Predefined_Entities_Export.xlsx", data });
		} catch (e) {
			console.log(e);
		}
	}
}
