import React from "react";
import Grid, { Row, Column } from "@growthos/ui-grid";
import Card, { CARD_PADDING } from "@growthos/ui-card";
import Heading, { HEADING_LEVEL, HEADING_SIZE } from "@growthos/ui-heading";
import "./index.scss";

import PropTypes from "prop-types";

const propTypes = {
	children: PropTypes.node
};

const UnderConstruction = ({ children = "Under Construction" }) => {
	return (
		<Grid>
			<Row className={"underConstruction"}>
				<Column>
					<Card cardPadding={CARD_PADDING.MEDIUM} hideBorder={true} hideBoxShadow={true}>
						<Heading
							as={HEADING_LEVEL.H2}
							size={HEADING_SIZE.SMALL}
							description="This page is currently under construction. Check back later for more progress!"
							icon={"icon-assignment"}
						>
							{children}
						</Heading>
					</Card>
				</Column>
			</Row>
		</Grid>
	);
};

UnderConstruction.propTypes = propTypes;

export default UnderConstruction;
