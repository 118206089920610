import React, { useState, useEffect, useContext, useMemo, useCallback } from "react";
import { BannerNotification, show } from "@growthos/ui-banner";
import Button, { BUTTON_DISPLAY, BUTTON_SIZE } from "@growthos/ui-button";
import Checkbox, { DISPLAY_TYPE } from "@growthos/ui-checkbox";
import Textbox from "@growthos/ui-textbox";
import AppAssignmentService from "../../../services/AppAssignmentService";
import { AppContext } from "../../../common/AppProvider";
import ReactTable from "react-table-v6";
import "react-table-v6/react-table.css";

export default function ValueFiltering({ entitiesList, applicationEntityCodes, selectedApplicationCode, modelCode }) {
	const { setIsLoading } = useContext(AppContext);

	const [selectedEntity, setSelectedEntity] = useState(null);
	const [allDataValues, setAllDataValues] = useState([]);
	const [selectedDataValues, setSelectedDataValues] = useState({});
	const [selectAll, setSelectAll] = useState(false);
	const [searchEntity, setSearchEntity] = useState("");
	const [searchValues, setSearchValues] = useState("");
	const tableColumns = [
		{
			Header: "Value Code",
			accessor: "Code",
			resizable: false
		},
		{
			Header: "Value Description",
			accessor: "Name",
			resizable: false
		},
		{
			id: "checkbox",
			accessor: "",
			Header: (x) => <Checkbox checked={selectAll} onChange={(evt, checked) => toggleSelectAll(checked)} id="selectAll" />,
			Cell: (row) => (
				<Checkbox
					checked={selectedDataValues[row.original.MUID]}
					onChange={(evt, checked) => toggleSelectSingle(row, checked)}
					invert={true}
					id={`checkbox__${row.original.ID}`}
				/>
			),
			sortable: false,
			width: 70,
			resizable: false
		}
	];

	useEffect(() => {
		setSelectedEntity(null);
		setAllDataValues([]);
		setSelectedDataValues({});
		setSelectAll(false);
		setSearchEntity("");
		setSearchValues("");
	}, [applicationEntityCodes]);

	function selectEntity(entity) {
		setIsLoading(true);
		AppAssignmentService.getAllDataValues(modelCode, entity.code).then((allDataValues) => {
			AppAssignmentService.getSavedDataValues(selectedApplicationCode, modelCode, entity.code).then((savedDataValues) => {
				const filteredAllDataValues = allDataValues.data.filter((dataKey) => {
					return dataKey.Code !== "ZZZ";
				});
				const savedValues = {};
				const savedDataValueCodes = [];
				filteredAllDataValues.forEach((dataValue) => {
					savedValues[dataValue.MUID] = false;
				});
				savedDataValues.forEach((dataValue) => {
					savedValues[dataValue.dataValueCode] = true;
					savedDataValueCodes.push(dataValue.dataValueCode);
				});
				const sortedAllDataValues = filteredAllDataValues.sort((a, b) => {
					if (!savedDataValueCodes.includes(a.MUID)) {
						return 1;
					}
					if (!savedDataValueCodes.includes(b.MUID)) {
						return -1;
					}
					return 0;
				});
				const selectAll =
					filteredAllDataValues.length !== 0 &&
					!Object.keys(savedValues).some((code) => {
						return savedValues[code] === false;
					});
				setSelectedEntity(entity);
				setAllDataValues(sortedAllDataValues);
				setSelectedDataValues(savedValues);
				setSelectAll(selectAll);
				setSearchValues("");
				setIsLoading(false);
			});
		});
	}

	function toggleSelectAll(value) {
		const newSelectedDataValues = { ...selectedDataValues };
		if (value === true) {
			Object.keys(newSelectedDataValues).forEach((code) => {
				newSelectedDataValues[code] = true;
			});
			setSelectAll(true);
		} else {
			Object.keys(newSelectedDataValues).forEach((code) => {
				newSelectedDataValues[code] = false;
			});
			setSelectAll(false);
		}
		setSelectedDataValues(newSelectedDataValues);
	}

	function toggleSelectSingle(row, value) {
		const newSelectedDataValues = { ...selectedDataValues };
		newSelectedDataValues[row.original.MUID] = value;
		const newSelectAll = !Object.keys(newSelectedDataValues).some((code) => {
			return newSelectedDataValues[code] === false;
		});
		setSelectAll(newSelectAll);
		setSelectedDataValues(newSelectedDataValues);
	}

	function handleSave() {
		const savedDataValues = [];
		Object.keys(selectedDataValues).forEach((code) => {
			if (selectedDataValues[code] === true) {
				savedDataValues.push(code);
			}
		});
		setIsLoading(true);
		AppAssignmentService.saveDataValues(selectedApplicationCode, modelCode, selectedEntity.code, savedDataValues).then(() => {
			show("mainBannerContainer", <BannerNotification type="success" title="Success" message="Successfully Saved" timed={5000} />);
			setIsLoading(false);
		});
	}

	const renderedSelectedEntities = useMemo(() => {
		const selectedEntities = entitiesList
			.filter((entity) => {
				return applicationEntityCodes.includes(entity.code);
			})
			.filter((entity) => {
				if (searchEntity.trim() !== "") {
					return entity.name.toLowerCase().includes(searchEntity.toLowerCase());
				} else {
					return true;
				}
			});
		return selectedEntities.map((entity, index) => {
			return (
				<div className="select-entity" key={`select-entity-${index}`} onClick={(e) => selectEntity(entity)}>
					<span className="entity-name">{entity.name}</span>
					<span className="entity-code">{entity.dataKeyCode}</span>
				</div>
			);
		});
	}, [applicationEntityCodes, searchEntity]);

	return (
		<div className="filtering-container">
			<div className="select-entity-container">
				<Textbox
					className="filtering-search-entities"
					id="filtering-search-entities"
					placeholder="Search entities"
					hideLabel={true}
					value={searchEntity}
					onChange={(e) => setSearchEntity(e.target.value)}
				>
					Search Entities
				</Textbox>
				<div style={{ padding: "16px 0" }}>Keys in this application</div>
				<div className="entity-container">{renderedSelectedEntities}</div>
			</div>
			{selectedEntity && (
				<div className="select-values-container">
					<div className="filtering-header">
						<h2>{selectedEntity.name}</h2>
						<Button onClick={handleSave}>Save</Button>
					</div>
					<Textbox
						className="filtering-search-values"
						id="filtering-search-values"
						placeholder="Search data values"
						hideLabel={true}
						value={searchValues}
						onChange={(e) => setSearchValues(e.target.value)}
					>
						Search Values
					</Textbox>
					<ReactTable
						className="data-values-table"
						data={allDataValues.filter((dataValue) => {
							if (searchValues.trim() !== "") {
								return dataValue.Code.toLowerCase().includes(searchValues.toLowerCase()) || dataValue.Name.toLowerCase().includes(searchValues.toLowerCase());
							} else {
								return true;
							}
						})}
						pageSize={allDataValues.length}
						pagination={false}
						columns={tableColumns}
						noDataText="No data values found"
					/>
				</div>
			)}
		</div>
	);
}
