import RequestHelpers from "../helpers/RequestHelpers";
const { doRequest } = RequestHelpers;

export default class UserService {
	static getUserData(contextId) {
		return doRequest({
			uri: "User/getUserData",
			params: { contextId }
		});
	}
}
