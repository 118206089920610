import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import store from "./redux/store";
import { Provider } from "react-redux";

import AppSDK from "@growthos/ui-app-sdk";

import App from "./App";

import packageJson from "../package.json";
import { unregister } from "./serviceWorkerRegistration";

const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");
const appElementId = "app";

const Body = (
	<AppSDK.ShellProvider onRender={render}>
		<AppSDK.ErrorHandler>
			<BrowserRouter basename={baseUrl}>
				<Provider store={store}>
					<App />
				</Provider>
			</BrowserRouter>
		</AppSDK.ErrorHandler>
	</AppSDK.ShellProvider>
);

// Welcome message
// console.log(`App: ${packageJson.name} v${packageJson.version}`);

function render(app) {
	AppSDK.initialize({ appCode: process.env.REACT_APP_DM_APP_CODE });
	const appEl = document.getElementById(appElementId);
	createRoot(appEl).render(app);
}
render(Body);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.register();
unregister();
