import React, { useState, useEffect, useMemo } from "react";
import "./FilterSideSheet.scss";
// Services
// Component Library
import Button, { BUTTON_DISPLAY } from "@growthos/ui-button";
import Dropdown from "@growthos/ui-dropdown";
import Textbox from "@growthos/ui-textbox";
import SideSheet from "@growthos/ui-sidesheet";

export default ({ isOpen, onClose, applyFilter, columns, savedFilters }) => {
	const [filterValues, setFilterValues] = useState([{ column: null, value: "" }]);

	const renderedFilters = useMemo(() => {
		const filtersList = [];
		const selectedColumns = [];
		filterValues.forEach((filter) => {
			if (filter.column) {
				selectedColumns.push(filter.column.value);
			}
		});
		const columnOptions = columns
			.filter((column) => {
				return !selectedColumns.includes(column.name);
			})
			.map((option) => {
				return { value: option.name, label: option.name };
			});

		filterValues.forEach((filter, index) => {
			filtersList.push(
				<div className="filter-row">
					<Dropdown
						className="column-dropdown"
						placeholder="Select column"
						value={filter.column}
						options={columnOptions}
						isClearable={true}
						isSearchable={true}
						onChange={(selected) => {
							const newFilterValues = [...filterValues];
							newFilterValues[index].column = selected;
							if (!selected) {
								newFilterValues[index].value = "";
							}
							setFilterValues(newFilterValues);
						}}
					>
						Select column
					</Dropdown>
					<Textbox
						className="filter-textbox"
						hideLabel={true}
						placeholder="Enter text"
						value={filter.value}
						disabled={!filter.column}
						onChange={(evt, value) => {
							const newFilterValues = [...filterValues];
							newFilterValues[index].value = value;
							setFilterValues(newFilterValues);
						}}
					>
						Text to filter
					</Textbox>
					<div className="filter-actions">
						<Button
							display={BUTTON_DISPLAY.ICON_BORDERED}
							icon="icon-delete"
							disabled={index === 0}
							onClick={() => {
								const newFilterValues = [...filterValues];
								newFilterValues.splice(index, 1);
								setFilterValues(newFilterValues);
							}}
						></Button>
						<Button
							display={BUTTON_DISPLAY.ICON_BORDERED}
							icon="icon-add"
							disabled={filterValues.length === columns.length}
							onClick={() => {
								const newFilterValues = [...filterValues];
								newFilterValues.push({ column: null, value: "" });
								setFilterValues(newFilterValues);
							}}
						></Button>
					</div>
				</div>
			);
		});
		return filtersList;
	}, [filterValues, columns]);

	const disableSubmit = useMemo(() => {
		return filterValues.some((filter) => {
			return !filter.column || !filter.value.trim();
		});
	}, [filterValues]);

	return (
		<SideSheet
			className="filter-sidesheet"
			isOpen={isOpen}
			onClose={onClose}
			headline="Filters"
			footerActions={
				<>
					<Button
						display={BUTTON_DISPLAY.SECONDARY}
						onClick={() => {
							setFilterValues([{ column: null, value: "" }]);
							if (savedFilters.length !== 0) {
								applyFilter([]);
							}
						}}
					>
						Clear
					</Button>
					<Button
						disabled={disableSubmit}
						onClick={() => {
							const mappedValues = filterValues.map((filter) => {
								return { column: filter.column.value, value: filter.value };
							});
							applyFilter(mappedValues);
						}}
					>
						Done
					</Button>
				</>
			}
		>
			<div className="filter-header">
				<span>Filter by column</span>
				<span>Enter text to filter</span>
			</div>
			{renderedFilters}
		</SideSheet>
	);
};
