import React, { useEffect } from "react";
import { createPortal } from "react-dom";
import { useNavigate } from "react-router-dom";
import Dropdown from "@growthos/ui-dropdown";

// this will eventually go away
export default ({ modelsList, selectedModel, setSelectedModel }) => {
	const navigate = useNavigate();
	useEffect(() => {
		if (modelsList?.length === 1) {
			const autoselectedModel = modelsList[0];
			navigate(`/model/${autoselectedModel.code}/entity`);
			setSelectedModel(autoselectedModel);
		}
	}, [modelsList]);

	return (
		<>
			{document.querySelector(".shell_uIxqR") &&
				createPortal(
					<Dropdown
						className={"modelSelector"}
						options={modelsList}
						value={selectedModel}
						disabled={modelsList?.length === 1}
						isSearchable={true}
						onChange={(selected) => {
							if (selected) {
								navigate(`/model/${selected.code}/entity`);
							} else {
								navigate(`/`);
							}
							setSelectedModel(selected);
						}}
						children=""
						isClearable={true}
					/>,
					document.querySelector(".shell_uIxqR")
				)}
		</>
	);
};
