import { useState, useMemo } from "react";
import Modal from "./AddNewEntityModal";
export default () => {
	const [open, setOpen] = useState(false);

	return useMemo(
		() => ({
			open,
			setOpen,
			Modal
		}),
		[open, setOpen, Modal]
	);
};
