import React, { useMemo, useCallback, useState, useContext, useEffect } from "react";
import { useDispatch } from "react-redux";

import { BannerNotification, show } from "@growthos/ui-banner";
import EntitiesService from "../../../services/EntitiesService";
// import {entitiesDataRequest} from "../../Redux/entities/entitiesActions";
import { areas } from "../../../helpers/Areas";
import { displayErrors } from "../../../helpers/Errors";
import { EntitiesContext } from "../../../common/EntitiesContext";
import Modal from "./components/ModalComponent";
import { AppContext } from "../../../common/AppProvider";

export default (toggleLoading) => {
	// console.log(EntitiesContext);
	// const dispatch = useDispatch();
	const [open, setOpen] = useState();
	const [showCurrentEntities, setShowCurrentEntities] = useState(false);

	const { entitiesList: entities, pmEntitiesList, setEntitiesList } = useContext(EntitiesContext);
	const { selectedModel: modelCode, setIsLoading } = useContext(AppContext);

	// const refresh = () => dispatch(entitiesDataRequest(areas.ENTITIES_LIST, modelCode));

	const onSubmit = useCallback(
		(entityIds) => {
			const executeSave = async () => {
				try {
					setIsLoading(true);
					EntitiesService.saveStandardBulkEntities(modelCode?.code, entityIds)
						.then(() => {
							EntitiesService.getEntitiesData(modelCode?.code).then((response) => {
								setEntitiesList(response);
								setIsLoading(false);
								show("mainBannerContainer", <BannerNotification type="success" title="Entities added" message="" timed={5000} />);
							});
						})
						.catch(async (error) => {
							const errorObject = await error?.details?.json();
							setIsLoading(false);
							displayErrors("mainBannerContainer", errorObject?.message);
						});
					// refresh();
				} catch (e) {
					displayErrors("mainBannerContainer", e);
					setIsLoading(false);
				} finally {
					// toggleLoading(false);
				}
			};

			try {
				// toggleLoading(true);
				executeSave();
			} catch (e) {
				// noop
			}
		},
		[modelCode]
	);

	const availableEntities = useMemo(() => {
		if (pmEntitiesList?.length > 0 && entities?.length > 0 && modelCode != null) {
			// filter the template entities with the codes of the existing entities, restricted to this modelCode
			const existingCodes = entities.filter((e) => e.modelCode === modelCode.code).map(({ dataKeyCode }) => dataKeyCode);
			const condition = ({ dataKeyCode }) => dataKeyCode !== "SZ" && dataKeyCode !== "RT" && dataKeyCode !== "SCX" && existingCodes.indexOf(dataKeyCode) === -1;

			// conditionally filter and map the properties to the ones the Modal expects
			return (showCurrentEntities ? pmEntitiesList : pmEntitiesList.filter(condition)).map(({ code, dataKeyCode, dataKeyName, entityTooltip }) => ({
				entityId: code,
				name: dataKeyName,
				code: dataKeyCode,
				tooltip: entityTooltip,
				selected: false,
				disabled: !condition({ dataKeyCode })
			}));
		}
		return [];
	}, [entities, pmEntitiesList, modelCode, showCurrentEntities]);

	return useMemo(
		() => ({
			Modal,
			open,
			setOpen,
			availableEntities,
			onSubmit,
			modelCode,
			showCurrentEntities,
			setShowCurrentEntities
		}),
		[Modal, open, setOpen, availableEntities, onSubmit, showCurrentEntities, setShowCurrentEntities]
	);
};
