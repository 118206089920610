import React, { useState, useMemo, useCallback, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import "./index.scss";
import _ from "lodash";
import nextId from "react-id-generator";

import { AppContext } from "../../../common/AppProvider";
import { EntitiesContext } from "../../../common/EntitiesContext";
import Button, { BUTTON_DISPLAY, BUTTON_SIZE } from "@growthos/ui-button";
import Checkbox, { SELECTION_STATE, DISPLAY_TYPE } from "@growthos/ui-checkbox";
import Modal from "@growthos/ui-modal";
import Textbox from "@growthos/ui-textbox";
import Tooltip, { TOOLTIP_POSITION } from "@growthos/ui-tooltip";

import { displayErrors } from "../../../helpers/Errors";
import StateHelper from "./StateHelper";
import { BannerNotification, show } from "@growthos/ui-banner";
import Mappings from "./Mappings/Mappings";

import { EXCLUDED_ATTRIBUTES, HIERARCHY_OPTION, ATTRIBUTE_OPTION, MAPPING_OPTION, SUPPLIER_CODE_NAME } from "./constants";

import PropTypes from "prop-types";
import EntitiesService from "../../../services/EntitiesService";
import AttributesService from "../../../services/AttributesService";
import HierarchyService from "../../../services/HierarchyService";

const propTypes = {
	handleGoBack: PropTypes.func.isRequired,
	hierarchies: PropTypes.array.isRequired,
	attributes: PropTypes.array.isRequired,
	refresh: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired,
	selectedModel: PropTypes.object.isRequired
};

export default function EditAttributes({ handleGoBack, attributes, hierarchies }) {
	const navigate = useNavigate();

	const { setPageHeaderValues, setIsLoading, selectedModel, setModelHierarchies } = useContext(AppContext);
	const { selectedEntity, entitiesList, selectedEntityPage, setSelectedEntityPage, setHideEntityList, setEntitiesList, setSelectedEntity } = useContext(EntitiesContext);
	const [entityDetails, setEntityDetails] = useState({});
	const [enableSiteSupplier, setEnableSiteSupplier] = useState(false);
	const [initialSiteSupplier, setInitialSiteSupplier] = useState(false);
	const [enableSave, setEnableSave] = useState(false);
	const [entityMappings, setEntityMappings] = useState([]);
	const [currentEntityMappings, setCurrentEntityMappings] = useState([]);
	const isInNameSite = useMemo(() => {
		return selectedEntity?.dataKeyCode === "PB" && selectedEntity?.dataKeyName === "InName Site";
	}, [selectedEntity]);

	const disableEditDetails = useMemo(() => {
		if (["DMA", "MSA", "CY"].includes(selectedEntity?.dataKeyCode)) {
			return true;
		}
		return false;
	}, [selectedEntity]);

	useEffect(() => {
		setHideEntityList(true);
		setSelectedEntityPage("settings");
	}, []);

	useEffect(() => {
		const Actions = () => (
			<>
				<Button
					id="cancelEntitySettingsButton"
					size={BUTTON_SIZE.MD}
					display={BUTTON_DISPLAY.SECONDARY}
					onClick={() => {
						if (enableSave) {
							Modal.show("cancelConfirmationModal");
						} else {
							handleGoBack();
						}
					}}
				>
					Cancel
				</Button>
				<Button
					id="saveEntitySettingsButton"
					size={BUTTON_SIZE.MD}
					disabled={!enableSave}
					onClick={() => {
						Modal.show("confirmationModal");
					}}
				>
					Save
				</Button>
			</>
		);
		setPageHeaderValues({ Actions, Heading: selectedEntity?.name, hideNavLinks: true });
	}, [selectedEntityPage, selectedEntity, enableSave]);

	useEffect(() => {
		setEntityDetails(selectedEntity);
	}, [selectedEntity]);

	useEffect(() => {
		if (
			isInNameSite &&
			attributes.find((attribute) => {
				return attribute.name === SUPPLIER_CODE_NAME;
			})
		) {
			setEnableSiteSupplier(true);
			setInitialSiteSupplier(true);
		}
	}, [attributes, isInNameSite]);

	useEffect(() => {
		const newMappings = [];
		attributes
			.filter((attribute) => {
				return !EXCLUDED_ATTRIBUTES.includes(attribute.name) && (!isInNameSite || (isInNameSite && attribute.name !== SUPPLIER_CODE_NAME));
			})
			.sort((a, b) => (a.sortOrder > b.sortOrder ? 1 : -1))
			.forEach((mapping) => {
				newMappings.push({
					id: mapping.code,
					mappingRelationship: StateHelper.getHierarchy(hierarchies, mapping?.config_EntityCode, mapping?.code) !== null ? HIERARCHY_OPTION : ATTRIBUTE_OPTION,
					name: mapping?.name || "",
					type: StateHelper.getType(mapping) || "",
					value: StateHelper.getValue(mapping, entitiesList) || ""
				});
			});
		setCurrentEntityMappings(_.cloneDeep(newMappings));
		setEntityMappings(newMappings);
	}, [attributes, isInNameSite, hierarchies]);

	useEffect(() => {
		const allDetailsFilledOut = entityDetails?.dataKeyName?.trim() && entityDetails?.dataKeyCode?.trim() && entityDetails?.dataKeyDescription?.trim();
		const allMappingsFilledOut = entityMappings.every((mapping) => {
			return mapping.mappingRelationship && mapping.name.trim() && mapping.type && mapping.value;
		});
		const nameIsRawSupplierCode = entityMappings.find((mapping) => {
			return mapping?.name?.trim()?.toLowerCase() === "raw supplier code";
		});
		// Check if anything has been changed and that all fields are filled out
		if (
			(!_.isEqual(entityDetails, selectedEntity) || !_.isEqual(entityMappings, currentEntityMappings) || enableSiteSupplier !== initialSiteSupplier) &&
			allDetailsFilledOut &&
			allMappingsFilledOut &&
			!nameIsRawSupplierCode
		) {
			setEnableSave(true);
		} else {
			setEnableSave(false);
		}
	}, [entityDetails, entityMappings, enableSiteSupplier, initialSiteSupplier]);

	const handleSave = useCallback(async () => {
		Modal.hide("confirmationModal");
		setIsLoading(true);
		const entityDetailsUpdated =
			!_.isEqual(entityDetails, selectedEntity) && entityDetails?.dataKeyName?.trim() && entityDetails?.dataKeyCode?.trim() && entityDetails?.dataKeyDescription?.trim();
		const entityMappingsUpdated =
			!_.isEqual(entityMappings, currentEntityMappings) &&
			entityMappings.every((mapping) => {
				return mapping.mappingRelationship && mapping.name.trim() && mapping.type && mapping.value;
			});
		const apiActions = [];
		if (entityDetailsUpdated) {
			const savedEntityDetails = { ...entityDetails };
			savedEntityDetails.isNew = false;
			apiActions.push(async () => {
				await EntitiesService.updateEntity(selectedModel?.code, savedEntityDetails);
			});
		}
		if (entityMappingsUpdated || enableSiteSupplier !== initialSiteSupplier) {
			let mappingsToSave = [...entityMappings];

			// Handle inNameSite site supplier toggle
			if (isInNameSite) {
				const existingSiteSupplierMapping = attributes.find((attribute) => {
					return attribute.name === SUPPLIER_CODE_NAME;
				});
				const supplierCodeEntity = entitiesList.find((entity) => {
					return entity.name === SUPPLIER_CODE_NAME;
				});
				const siteSupplierMapping = {
					mappingRelationship: ATTRIBUTE_OPTION,
					name: SUPPLIER_CODE_NAME,
					type: MAPPING_OPTION,
					value: {
						label: supplierCodeEntity.dataKeyName,
						value: supplierCodeEntity.code
					}
				};
				if (enableSiteSupplier) {
					if (!existingSiteSupplierMapping) {
						siteSupplierMapping.id = nextId("new-mapping-");
					} else {
						siteSupplierMapping.id = existingSiteSupplierMapping.code;
					}
					mappingsToSave.push(siteSupplierMapping);
				} else if (!enableSiteSupplier && existingSiteSupplierMapping) {
					siteSupplierMapping.id = existingSiteSupplierMapping.code;
					apiActions.push(async () => {
						await AttributesService.deleteAttribute(StateHelper.getDeletePayload(selectedEntity, siteSupplierMapping));
					});
				}
			}

			let provisoryCode = 0;
			mappingsToSave.forEach((mapping, index) => {
				if (mapping.id.startsWith("new-mapping-")) {
					// Create new mapping
					apiActions.push(async () => {
						provisoryCode++;
						const attributeId = await AttributesService.createAttribute(StateHelper.getCreatePayload(mapping, selectedEntity, provisoryCode, index + 1));
						if (mapping.mappingRelationship?.label === "Hierarchy" && !StateHelper.getHierarchy(hierarchies, mapping?.value?.value, mapping?.id)) {
							// Create a hierarchy for this mapping
							await StateHelper.createHierarchy(mapping, selectedEntity, attributeId);
						}
					});
				} else {
					// Update mapping
					apiActions.push(async () => {
						const existingAttribute = attributes.find((attribute) => {
							return attribute.code === mapping.id;
						});
						await AttributesService.updateAttribute(StateHelper.getUpdatePayload(existingAttribute, mapping, selectedEntity, index + 1));
						const hierarchy = StateHelper.getHierarchy(hierarchies, mapping?.value?.value, mapping?.id);
						// Check if hierarchy needs to be updated
						if (mapping.mappingRelationship?.label === "Hierarchy" && hierarchy) {
							StateHelper.updateHierarchy(mapping, selectedEntity, hierarchy);
						}
					});
				}
			});
			currentEntityMappings.forEach((existingMapping) => {
				if (
					!mappingsToSave.find((mapping) => {
						return mapping.id === existingMapping.id;
					})
				) {
					// Delete mapping and also delete hierarchy if this mapping was a hierarchy
					apiActions.push(async () => {
						await AttributesService.deleteAttribute(StateHelper.getDeletePayload(selectedEntity, existingMapping));
						const hierarchy = StateHelper.getHierarchy(hierarchies, existingMapping?.value?.value, existingMapping?.id);
						if (hierarchy) {
							await HierarchyService.Delete(hierarchy?.id, hierarchy?.modelId);
						}
					});
				}
			});
		}

		try {
			const promises = apiActions.map((action) => action());
			await Promise.all(promises);
			show("mainBannerContainer", <BannerNotification type="success" title="Settings saved" message="" timed={5000} />);
			postSaveHandling();
		} catch (error) {
			console.log(error);
			const errorObject = await error?.details?.json();
			if (errorObject?.errorMessage || errorObject?.message) {
				displayErrors("mainBannerContainer", errorObject.errorMessage || errorObject?.message);
			}
			postSaveHandling();
		}
	}, [entityDetails, selectedEntity, entityMappings, currentEntityMappings, isInNameSite, entitiesList, enableSiteSupplier, initialSiteSupplier]);

	function postSaveHandling() {
		HierarchyService.getAllHierarchies(selectedModel?.code).then((hiearchies) => {
			setModelHierarchies(hiearchies);
			EntitiesService.getEntitiesData(selectedModel?.code).then((response) => {
				setEntitiesList(response);
				const savedEntity = response.find((entity) => {
					return entity.code === selectedEntity.code;
				});
				setSelectedEntity(savedEntity);
				navigate(`/model/${selectedModel?.code}/entity/${savedEntity?.code}/values`);
				setIsLoading(false);
			});
		});
	}

	function handleEntityDetailUpdate(value, type) {
		const newSelectedEntity = { ...entityDetails };
		let formattedValue = value;
		if (type === "dataKeyName") {
			newSelectedEntity.name = formattedValue;
		} else if (type === "dataKeyCode") {
			formattedValue = formattedValue.replace(/[^a-zA-Z0-9]+/g, "")?.toUpperCase();
		} else if (type === "dataKeyDescription") {
			newSelectedEntity.entityDescription = formattedValue;
		}
		newSelectedEntity[type] = formattedValue;
		setEntityDetails(newSelectedEntity);
	}

	return (
		<div className="entity-settings-container">
			<div className="entity-details-container">
				<h4>Entity details</h4>
				<div className="text-field-container">
					<Textbox
						value={entityDetails?.dataKeyName}
						maxLength={50}
						onChange={(evt) => handleEntityDetailUpdate(evt.target.value, "dataKeyName")}
						id="entity-name"
						placeholder="Enter an entity name"
						required={true}
						error="Entity name is required"
						disabled={disableEditDetails}
					>
						Entity name
					</Textbox>
					<Textbox
						value={entityDetails?.dataKeyCode}
						maxLength={3}
						onChange={(evt) => handleEntityDetailUpdate(evt.target.value, "dataKeyCode")}
						id="entity-code"
						placeholder="Enter an entity code"
						required={true}
						error="Code is required"
						disabled={disableEditDetails}
					>
						Code
					</Textbox>
					<Textbox
						value={entityDetails?.vanityName}
						maxLength={50}
						onChange={(evt) => handleEntityDetailUpdate(evt.target.value, "vanityName")}
						id="entity-vanity-name"
						placeholder="Enter a vanity name"
						disabled={disableEditDetails}
					>
						Vanity name
					</Textbox>
				</div>

				<div className="entity-options-container">
					<Textbox
						value={entityDetails?.dataKeyDescription}
						maxLength={300}
						onChange={(evt) => handleEntityDetailUpdate(evt.target.value, "dataKeyDescription")}
						id="entity-description"
						placeholder="Enter a description"
						as="textarea"
						required={true}
						error="Description is required"
						disabled={disableEditDetails}
					>
						Description
					</Textbox>
					<Checkbox
						checked={entityDetails?.dataKeyEnableMail}
						onChange={(evt, checked) => handleEntityDetailUpdate(checked, "dataKeyEnableMail")}
						className="toggle-email-alerts"
						id="toggle-email-alerts"
						displayType={DISPLAY_TYPE.TOGGLE}
						disabled={disableEditDetails}
					>
						Email alerts
					</Checkbox>
					<Tooltip position={TOOLTIP_POSITION.TOP} title="Email alerts" className="toggle-tooltips">
						<i className="icon-question" />
					</Tooltip>
					<Checkbox
						checked={entityDetails?.isFreeform}
						onChange={(evt, checked) => handleEntityDetailUpdate(checked, "isFreeform")}
						className="toggle-freeform"
						id="toggle-freeform"
						displayType={DISPLAY_TYPE.TOGGLE}
						disabled={disableEditDetails}
					>
						Freeform
					</Checkbox>
					<Tooltip position={TOOLTIP_POSITION.TOP} title="Enable freeform in order to allow for manual user inputted text field" className="toggle-tooltips">
						<i className="icon-question" />
					</Tooltip>
				</div>
			</div>
			<Mappings entityMappings={entityMappings} setEntityMappings={setEntityMappings} isInNameSite={isInNameSite} />
			{isInNameSite && (
				<div className="site-supplier-container">
					<h4>Site Supplier Code</h4>
					<p className="container-description">Enable in order to map Supplier Code to InName Site.</p>
					<Checkbox
						checked={enableSiteSupplier}
						onChange={(evt, checked) => setEnableSiteSupplier(checked)}
						className="toggle-site-supplier"
						id="toggle-site-supplier"
						displayType={DISPLAY_TYPE.TOGGLE}
					>
						Enable Site Supplier
					</Checkbox>
				</div>
			)}
			<Modal
				id="confirmationModal"
				heading="Confirm"
				description="Are you sure you want to perform this action?"
				actionsRight={
					<React.Fragment>
						<Button
							display={BUTTON_DISPLAY.SECONDARY}
							onClick={() => {
								Modal.hide("confirmationModal");
							}}
						>
							Cancel
						</Button>
						<Button disabled={!enableSave} onClick={handleSave}>
							Save
						</Button>
					</React.Fragment>
				}
			></Modal>
			<Modal
				id="cancelConfirmationModal"
				heading="Confirm"
				description="You have unsaved changes that will be lost if you exit this page. Are you sure you want to exit without saving?"
				actionsRight={
					<React.Fragment>
						<Button
							display={BUTTON_DISPLAY.SECONDARY}
							onClick={() => {
								Modal.hide("cancelConfirmationModal");
							}}
						>
							Continue Editing
						</Button>
						<Button className="red-button" onClick={handleGoBack}>
							Yes, exit without saving
						</Button>
					</React.Fragment>
				}
			></Modal>
		</div>
	);
}

EditAttributes.propTypes = propTypes;
