// Pages
import Entities from "../pages/Entities";
import EntitySettings from "../pages/Entities/EntitySettings";
import EntityValuesTable from "../pages/Entities/EntityValues";
import NoEntitySelected from "../pages/Entities/NoEntitySelected/NoEntitySelected";

import Hierarchies from "../pages/Hierarchies";
import AppConfig from "../pages/AppConfig";
import ActivityLog from "../pages/ActivityLog";
import ModelsAdmin from "../pages/ModelsAdmin";
import UnderConstruction from "../common/UnderConstruction";
import { Page404 } from "@growthos/ui-theme";
import React from "react";

export default [
	{
		path: "/",
		exact: true,
		index: true,
		element: <UnderConstruction>No Model Selected</UnderConstruction>
	},
	{
		path: "/model/:modelCode/entity",
		exact: true,
		index: true,
		element: (
			<Entities>
				<NoEntitySelected />
			</Entities>
		)
	},
	{
		path: "/model/:modelCode/entity/:entityCode/values",
		exact: true,
		index: true,
		element: (
			<Entities>
				<EntityValuesTable />
			</Entities>
		)
	},
	{
		path: "/model/:modelCode/entity/:entityCode/settings",
		exact: true,
		index: true,
		element: (
			<Entities>
				<EntitySettings />
			</Entities>
		)
	},
	{
		path: "/model/:modelCode/hierarchies",
		exact: true,
		index: true,
		element: <Hierarchies />
	},
	{
		path: "/model/:modelCode/activity-log",
		exact: true,
		index: true,
		element: <ActivityLog />
	},
	{
		path: "/models-admin",
		exact: true,
		index: true,
		element: <ModelsAdmin />
	},
	{
		path: "/app-config/:modelCode",
		exact: true,
		index: true,
		element: <AppConfig />
	},
	{
		path: "*",
		element: <Page404 />
	}
];
