import RequestHelpers from "../helpers/RequestHelpers";

const { doRequest } = RequestHelpers;

export default class AttributesService {
	static getAttributesData(modelCode, entityCode) {
		return doRequest({
			uri: "Attribute/GetList",
			params: { modelCode, entityCode, includeHierarchies: false }
		});
	}

	static deleteAttribute({ code, modelCode, entityCode }) {
		return doRequest({
			method: "DELETE",
			uri: "Attribute/Delete",
			params: { attributeId: code, modelId: modelCode, entityId: entityCode, modelCode }
		});
	}

	static createAttribute(attribute) {
		return doRequest({
			uri: "Attribute/Create",
			method: "POST",
			body: JSON.stringify(attribute)
		});
	}

	static updateAttribute(attribute) {
		return doRequest({
			uri: "Attribute/Update",
			method: "PUT",
			body: JSON.stringify(attribute)
		});
	}

	static markAsKey(modelCode, entityCode, keyAttrCodes) {
		return doRequest({
			method: "PUT",
			uri: "Attribute/MarkAttributesAsKey",
			params: { modelCode, entityCode },
			body: JSON.stringify(keyAttrCodes)
		});
	}

	static getFieldFormats() {
		return doRequest({ uri: "Attribute/GetFieldFormats" });
	}
}
