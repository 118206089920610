/* eslint-disable */
import React, { useState, useEffect, useContext, useMemo } from "react";
import "./index.scss";
import HierarchySideSheet from "./HierarchySideSheet/HierarchySideSheet";
import NewValuesModal from "./NewValuesModal/NewValuesModal";
import LiveValues from "./LiveValues/LiveValues";
import ArchivedValues from "./ArchivedValues/ArchivedValues";
// Services
import AppAssignmentService from "../../../services/AppAssignmentService";
import EntitiesService from "../../../services/EntitiesService";
// Component Library
import Loading from "@growthos/ui-loading";
// Context
import { EntitiesContext } from "../../../common/EntitiesContext";
import { AppContext } from "../../../common/AppProvider";

const EntityValuesTable = () => {
	const { selectedModel } = useContext(AppContext);
	const { selectedEntity, setSelectedEntityPage, setHideEntityList, setHierarchiesList } = useContext(EntitiesContext);

	const [hierachyOpen, setHierachyOpen] = useState(false);
	const [newValuesModalOpen, setNewValuesModalOpen] = useState(false);
	const [valuesType, setValuesType] = useState("Live");
	const [refreshValues, setRefreshValues] = useState(true);
	const [fetchLiveValues, setFetchLiveValues] = useState(false);
	const [fetchArchivedValues, setFetchArchivedValues] = useState(false);

	useEffect(() => {
		setHideEntityList(false);
		// Set the actions in the page header to the default ones
		setSelectedEntityPage("default");
	}, []);

	useEffect(() => {
		if (selectedModel && selectedEntity) {
			refreshDataValues();
		}
	}, [selectedEntity]);

	function refreshDataValues() {
		setHierachyOpen(false);
		setRefreshValues(true);
		EntitiesService.getEntityHierarchies(selectedModel.code, selectedEntity.code).then((hierarchies) => {
			setHierarchiesList(hierarchies);
			setRefreshValues(false);
		});
	}

	return (
		<>
			{selectedEntity && (
				<>
					{!refreshValues ? (
						<>
							<div className={`data-values-container ${valuesType === "Live" ? "" : "is-hidden"}`}>
								<LiveValues
									setValuesType={setValuesType}
									setHierachyOpen={setHierachyOpen}
									setNewValuesModalOpen={setNewValuesModalOpen}
									fetchValues={fetchLiveValues}
									setFetchLiveValues={setFetchLiveValues}
									setFetchArchivedValues={setFetchArchivedValues}
								/>
							</div>
							<div className={`data-values-container ${valuesType === "Archived" ? "" : "is-hidden"}`}>
								<ArchivedValues
									setValuesType={setValuesType}
									fetchValues={fetchArchivedValues}
									setFetchLiveValues={setFetchLiveValues}
									setFetchArchivedValues={setFetchArchivedValues}
								/>
							</div>
						</>
					) : (
						<Loading details="">Loading</Loading>
					)}

					<NewValuesModal isOpen={newValuesModalOpen} closeModal={() => setNewValuesModalOpen(false)} />
					<HierarchySideSheet isOpen={hierachyOpen} onClose={() => setHierachyOpen(false)} />
				</>
			)}
		</>
	);
};
export default EntityValuesTable;
