import React, { useEffect, useContext, useMemo, useState, useRef } from "react";
import ArchiveModal from "../ArchiveModal/ArchiveModal";
import FilterSideSheet from "../FilterSideSheet/FilterSideSheet";
// Services
import DataService from "../../../../services/DataService";
// Context
import { AttributesContext } from "../../../../common/AttributesContext";
import { AppContext } from "../../../../common/AppProvider";
import { EntitiesContext } from "../../../../common/EntitiesContext";
// Component Library
import { BannerNotification, show } from "@growthos/ui-banner";
import Button, { BUTTON_DISPLAY, BUTTON_SIZE } from "@growthos/ui-button";
import { DataProvider } from "@growthos/ui-theme";
import DataTable, { ColumnTemplate, TD_TYPE, TD_ALIGN } from "@growthos/ui-datatable";
import Heading, { HEADING_LEVEL, HEADING_SIZE } from "@growthos/ui-heading";
import Loading from "@growthos/ui-loading";
import Modal from "@growthos/ui-modal";
import Textbox from "@growthos/ui-textbox";
import Tooltip, { TOOLTIP_POSITION } from "@growthos/ui-tooltip";
import Status from "@growthos/ui-status";
import Popover from "@growthos/ui-popover";

export default function ArchivedValues({ setValuesType, fetchValues, setFetchLiveValues, setFetchArchivedValues }) {
	const tableApiRef = useRef();

	const { selectedModel } = useContext(AppContext);
	const { selectedEntity, setHideEntityList, hideEntityList } = useContext(EntitiesContext);
	const { entityAttributes } = useContext(AttributesContext);

	const [isLoading, setIsLoading] = useState(false);
	const [sidesheetOpen, setSidesheetOpen] = useState(false);
	const [statePage, setStatePage] = useState(1);
	const [stateColumns, setStateColumns] = useState([]);
	const [stateDataProvider, setStateDataProvider] = useState(null);
	const [statePaginationProps, setStatePaginationProps] = useState({});
	const [stateSelectedValues, setStateSelectedValues] = useState([]);
	const [searchValue, setSearchValue] = useState("");
	const [filterValues, setFilterValues] = useState([]);
	const [rawColumnData, setRawColumnData] = useState([]);

	const isFreeForm = useMemo(() => {
		if (selectedEntity) {
			return selectedEntity.isFreeform;
		}
		return false;
	}, [selectedEntity]);

	const selectedNamesAndCodes = useMemo(() => {
		return (
			stateSelectedValues.map((value) => {
				return { Code: value.Code, Name: value.Name, IsArchived: "0", MUID: value.MUID };
			}) || []
		);
	}, [stateSelectedValues]);

	useEffect(() => {
		// Refetch data if values have been archived
		if (fetchValues) {
			setFetchArchivedValues(false);
			getDataValues({ pageNumber: statePage });
		}
	}, [fetchValues]);

	useEffect(() => {
		if (stateDataProvider) {
			setIsLoading(false);
			setTimeout(() => {
				reselectCheckboxesOnRefresh();
			}, 1000);
		}
	}, [stateDataProvider]);

	useEffect(() => {
		getDataValues({ pageNumber: 1 });
	}, [searchValue, filterValues]);

	function getDataValues(apiProps = {}) {
		setIsLoading(true);
		setStateDataProvider(null);
		const filtersJson = {};
		const orFilters = [];
		const andFilters = [];
		if (rawColumnData.length && searchValue.trim()) {
			rawColumnData.forEach((column) => {
				orFilters.push({ [column.name]: { $like: searchValue.trim() } });
			});
		}
		if (filterValues.length) {
			filterValues.forEach((filter) => {
				andFilters.push({ [filter.column]: { $like: filter.value.trim() } });
			});
		}
		if (orFilters.length) {
			filtersJson.$or = orFilters;
		}
		if (andFilters.length) {
			filtersJson.$and = andFilters;
		}
		DataService.getDataList({
			modelCode: selectedModel.code,
			entityCode: selectedEntity.code,
			pageSize: 50,
			archivedEnum: 2,
			filtersJson: JSON.stringify(filtersJson),
			...apiProps
		}).then((data) => {
			const columns = !isFreeForm
				? [
						<ColumnTemplate key={"MUID"} bind="MUID" type={TD_TYPE.CHECKBOX}>
							Checkboxes
						</ColumnTemplate>
					]
				: [];
			const codeColumn = data.colHeaders.find((column) => {
				return column.name === "Code";
			});
			const nameColumn = data.colHeaders.find((column) => {
				return column.name === "Name";
			});
			setRawColumnData([codeColumn, nameColumn]);
			columns.push(
				<ColumnTemplate key={codeColumn.name} bind={codeColumn.name} type={TD_TYPE.TEXT} isSortable={true}>
					<span>{codeColumn.name}</span>
					<Status type="no-status" isBadge={true} title={codeColumn.dataCount?.toString()} />
				</ColumnTemplate>
			);
			columns.push(
				<ColumnTemplate key={nameColumn.name} bind={nameColumn.name} type={TD_TYPE.TEXT} isSortable={true}>
					<span>{nameColumn.name}</span>
					<Status type="no-status" isBadge={true} title={nameColumn.dataCount?.toString()} />
				</ColumnTemplate>
			);
			setStateColumns(columns);
			setStatePaginationProps({
				pageSize: 50,
				totalSize: data.count,
				dropUp: true,
				value: { label: apiProps.pageNumber, value: apiProps.pageNumber },
				rowOptions: [{ label: "50", value: 50 }],
				onChange: (value) => {
					getDataValues({ pageNumber: value.value });
				}
			});
			setStatePage(apiProps.pageNumber);
			setStateDataProvider(new DataProvider({ data: data.data, currentData: data.data, rowsPerPage: 50 }));
		});
	}

	function handleValueSelection(evt, checkboxState, node) {
		if (!evt.isTrusted) {
			return;
		}
		let selectedValues = [...stateSelectedValues];
		const isSelectAll = node.value === "archived-values-table-select-all";
		const selectedMUIDs = selectedValues.map((row) => {
			return row.MUID;
		});
		if (!isSelectAll) {
			if (checkboxState === true && !selectedMUIDs.includes(node?.renderProps?.value)) {
				const selectedValue = tableApiRef?.current?.dataProvider?.data?.find((row) => {
					return row.MUID === node?.renderProps?.value;
				});
				selectedValues.push(selectedValue);
			} else if (checkboxState === false) {
				selectedValues = selectedValues.filter((row) => {
					return row.MUID !== node.renderProps.value;
				});
			}
		} else {
			if (node.selectionState === true) {
				tableApiRef?.current?.dataProvider?.data?.forEach((row) => {
					if (!selectedMUIDs.includes(row.MUID)) {
						selectedValues.push(row);
					}
				});
			} else {
				const codesInCurrentPage = tableApiRef?.current?.dataProvider?.data?.map((row) => {
					return row.MUID;
				});
				selectedValues = selectedValues.filter((row) => {
					return !codesInCurrentPage.includes(row.MUID);
				});
			}
		}
		setStateSelectedValues(selectedValues);
	}

	function reselectCheckboxesOnRefresh() {
		if (stateSelectedValues.length) {
			const checkboxes = Array.from(document.querySelectorAll("input[id^='archived-values-table'][type='checkbox']"));
			stateSelectedValues.forEach((row) => {
				const matchingCheckbox = checkboxes.find((checkbox) => {
					return checkbox.value === row.MUID;
				});
				if (matchingCheckbox && !matchingCheckbox?.checked) {
					matchingCheckbox.click();
				}
			});
		}
	}

	function unarchiveValues() {
		setIsLoading(true);
		DataService.UpdateDataSet(selectedModel.code, selectedEntity.code, selectedNamesAndCodes)
			.then(() => {
				show("mainBannerContainer", <BannerNotification type="success" title="Value(s) unarchived" message="" timed={5000} />);
				setStateSelectedValues([]);
				getDataValues({ pageNumber: statePage });
				setFetchLiveValues(true);
			})
			.catch(async (error) => {
				const errorObject = await error?.details?.json();
				if (errorObject?.message) {
					const errorMessages = errorObject?.message.split("--");
					show("mainBannerContainer", <BannerNotification type="error" title="Error" message="Values were not unarchived" additionalDetails={errorMessages} />);
				}
				setIsLoading(false);
			});
	}

	return (
		<>
			{isLoading && <Loading details="">Loading</Loading>}
			<div className="editEntityHeader">
				<div className="editEntityHeaderLeft">
					<>
						<Tooltip title={hideEntityList ? "Minimize view" : "Expand view"} position={TOOLTIP_POSITION.BOTTOM_START}>
							<Button
								className={hideEntityList ? "sidebar-expanded" : ""}
								id="sidebar-toggle-button"
								icon={hideEntityList ? "icon-sidebar-open" : "icon-sidebar-close"}
								display={BUTTON_DISPLAY.ICON}
								onClick={() => {
									setHideEntityList(!hideEntityList);
								}}
							>
								Sidebar toggle
							</Button>
						</Tooltip>
						<h4 className="editEntityTitle">{selectedEntity.name}</h4>
						<Popover
							className="values-type-popover"
							trigger={
								<Button display={BUTTON_DISPLAY.TEXT} size={BUTTON_SIZE.SMALL} id="values-type-trigger">
									Archived values
									<i className="icon-caret-down" />
								</Button>
							}
						>
							<button className="values-button" id="live-values-button" onClick={() => setValuesType("Live")} type="button">
								Live values
							</button>
							<button className="values-button  is-selected" id="archived-values-button" type="button">
								Archived values
								<i className="icon-checkmark" />
							</button>
						</Popover>
					</>
				</div>
			</div>
			<div className="values-table-actions">
				{!isFreeForm && (
					<>
						{stateSelectedValues.length === 0 ? (
							<>
								<Textbox
									id="search-archived-values"
									className="search-values-textbox"
									hideLabel={true}
									type="search"
									placeholder="Search"
									onChange={(evt, value) => {
										if (value?.trim()) {
											setSearchValue(value?.trim());
										}
									}}
									onClear={() => {
										setSearchValue("");
									}}
									debounceTimeout={1000}
									defaultValue={searchValue}
								>
									Search Data Table
								</Textbox>
								<div className="actions-divider" />
								<Button
									className={`${filterValues.length ? "filter-applied" : ""}`}
									display={BUTTON_DISPLAY.SECONDARY}
									size={BUTTON_SIZE.SMALL}
									icon="icon-filter-alt"
									onClick={() => {
										setSidesheetOpen(!sidesheetOpen);
									}}
								>
									Filters
								</Button>
							</>
						) : (
							<>
								<Button display={BUTTON_DISPLAY.SECONDARY} size={BUTTON_SIZE.SMALL} icon="icon-archive" onClick={() => Modal.show("Unarchive-modal")}>
									Unarchive
								</Button>
								<div className="actions-divider" />
								<span className="selected-count">{stateSelectedValues.length} selected</span>
							</>
						)}
					</>
				)}
			</div>
			{stateDataProvider && (
				<DataTable
					id="archived-values-table"
					ref={tableApiRef}
					className="values-data-table"
					hasVirtualScrolling={false}
					bindKey={"MUID"}
					actionBarProps={{ visible: false }}
					data={!isFreeForm ? stateDataProvider : null}
					paginationProps={statePaginationProps}
					emptyState={
						<Heading
							size={HEADING_SIZE.MEDIUM}
							description={
								isFreeForm
									? "This is a freeform entity."
									: !searchValue.trim() && !filterValues.length
										? "This entity currently has no archived values."
										: "No data."
							}
						>
							No values to display
						</Heading>
					}
					onCheckboxChange={handleValueSelection}
				>
					{stateColumns}
				</DataTable>
			)}
			<FilterSideSheet isOpen={sidesheetOpen} onClose={() => setSidesheetOpen(false)} applyFilter={setFilterValues} columns={rawColumnData} savedFilters={filterValues} />
			<ArchiveModal type="Unarchive" selectedValues={selectedNamesAndCodes} handleConfirm={unarchiveValues} />
		</>
	);
}
